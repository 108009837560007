import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import { TPersonalData, IIdentifaceResult } from 'src/typings/singpass'
import { TOKEN } from 'src/constants/storage'
import storage from 'src/utils/storage'
import { getIsMyInfoEntity } from 'src/state/rtkQueries/uploadPortalApiSlice'

type TMyInfoEnv = {
  status: string
  clientId: string
  serviceUrl: string
  redirectUrl: string
  authApiUrl: string
  attributes: string
  companyAuthApiUrl: string
  companyAttributes: string
  authLevel: string
  purposeId: string
}

type TIdentifaceEnv = {
  identifaceUrl: string
  serviceId: string
  threshold: number
}
export const singPassApi = createApi({
  reducerPath: 'singPassApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${storage.getAPIServerUrl()}/CaseRequest/`,
    prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get(TOKEN)
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    }
  }),
  endpoints: (build) => ({
    getMyInfoConfig: build.query<TMyInfoEnv, void>({
      query: () => 'Singpass/MyInfo/Config'
    }),
    getCodeChallenge: build.query<string, void>({
      query: () => ({
        url: 'Singpass/MyInfo/GenerateCodeChallenge',
        responseHandler: 'text'
      })
    }),
    getPersonData: build.query<TPersonalData, { code: string; state: string; redirectUrl: string }>({
      query: (body) => ({
        url: 'Singpass/MyInfo/GetResult',
        method: 'POST',
        body
      })
    }),
    getBusinessData: build.query<TPersonalData, { code: string; state: string; redirectUrl: string }>({
      query: (body) => ({
        url: 'Singpass-business/MyInfo/GetResult',
        method: 'POST',
        body
      })
    }),
    getIdentifaceConfig: build.query<TIdentifaceEnv, void>({
      query: () => 'Singpass/Identiface/Config'
    }),
    // getIdentifaceToken: build.mutation<{ type: 'success'; token: string; status: number }, { userId: string }>({
    //   query: (body) => ({
    //     url: 'Singpass/Identiface/VerifyToken',
    //     method: 'POST',
    //     body
    //   })
    // }),
    getIdentifaceToken: build.mutation<
      { type: 'success'; token: string; status: number },
      { user_id: string; service_id: string }
    >({
      query: (body) => ({
        url: 'https://developer.bio-api.singpass.gov.sg/api/face/verify/token',
        method: 'POST',
        body
      })
    }),
    getIdentifaceResult: build.mutation<IIdentifaceResult, { userId: string; sessionToken: string }>({
      query: (body) => ({
        url: 'Singpass/Identiface/ValidateResult',
        method: 'POST',
        body
      })
    }),
    singpassOptOut: build.mutation<void, void>({
      query: () => {
        const isMyInfoEntity = getIsMyInfoEntity()
        return {
          url: `Singpass${isMyInfoEntity ? '-business' : ''}/OptOut`,
          method: 'POST'
        }
      }
    }),
    scheduleCallbackCheck: build.mutation<void, void>({
      query: () => ({
        url: 'Singpass/ScheduleCallbackCheck',
        method: 'POST'
      })
    })
  })
})

export const {
  useGetPersonDataQuery,
  useGetBusinessDataQuery,
  useGetMyInfoConfigQuery,
  useLazyGetCodeChallengeQuery,
  useGetIdentifaceConfigQuery,
  useGetIdentifaceTokenMutation,
  useGetIdentifaceResultMutation,
  useSingpassOptOutMutation,
  useScheduleCallbackCheckMutation
} = singPassApi
