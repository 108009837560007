import { createInstance } from 'src/utils/fetch'
import { State } from 'src/state/app/slice'
import { TBlobStorage } from 'src/typings/types'

const fetch = createInstance({})

const AppAPI = {
  async getSettings(): Promise<State> {
    // LocalDev url = `${process.env.REACT_APP_API_LOCATOR_URL}/ApiLocator/GetUploadPortalApiSettings?hostName=${'localhost:3000'}`
    const { data } = await fetch.get(
      `${process.env.REACT_APP_API_LOCATOR_URL}/ApiLocator/GetUploadPortalApiSettings?hostName=${window.location.host}`
    )
    // LocalDev
    //data.apiUrl = 'https://localhost:5001'
    return data
  },
  async createBlobInContainer(payload: TBlobStorage) {
    const { url, file } = payload
    const { data } = await fetch.put(url, file, {
      headers: {
        'Content-Type': 'image/jpeg; charset=utf-8',
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2017-11-09',
        authorization: false
      },
      signal: payload.signal
    })
    return data
  }
}

export default AppAPI
