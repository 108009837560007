import React from 'react'
import { useMediaQuery } from '@kyc-ui/react'
import useTheme from 'src/hooks/common/useTheme'
import CustomizedImage from 'src/components/CustomizedImage'

interface ILogoProps {
  isHome?: boolean
  width?: number | string
  height?: number | string
}

const Logo = (props: ILogoProps) => {
  const theme = useTheme()
  const { isHome = false, ...restProps } = props
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  if (matches) {
    if (isHome && theme.images.homeLogo) {
      return <CustomizedImage image={theme.images.homeLogo} height={'32px'} {...restProps} />
    } else {
      return <CustomizedImage image={theme.images.logo} name="dashboardLogo" height={'32px'} {...restProps} />
    }
  } else {
    if (isHome && theme.images.homeLogoIcon) {
      return <CustomizedImage image={theme.images.homeLogoIcon} name="homeLogoIcon" height={'32px'} {...restProps} />
    } else {
      return <CustomizedImage image={theme.images.logoIcon} name="dashboardLogoIcon" height={'32px'} {...restProps} />
    }
  }
}

export default Logo
