import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import Cookies from 'js-cookie'
import { TOKEN } from 'src/constants/storage'

export const generateFetchBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = Cookies.get(TOKEN) as string
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('Content-Type', 'application/json; charset=utf-8')
      return headers
    }
  })
}
