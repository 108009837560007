import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LANGUAGE_CODE } from 'src/constants/storage'
import { FALLBACK_LANGUAGE } from 'src/constants/app'
import { localStorageDetector, navigatorDetector } from './i18nDetectors'

const languageDetector = new LanguageDetector(null, {
  order: ['localStorageDetector', 'navigatorDetector'],
  lookupLocalStorage: LANGUAGE_CODE
})
languageDetector.addDetector(localStorageDetector)
languageDetector.addDetector(navigatorDetector)

export const languageOptions = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'es',
    label: 'Spanish'
  },
  {
    value: 'fr',
    label: 'French'
  },
  {
    value: 'de',
    label: 'German'
  },
  {
    value: 'jp',
    label: 'Japanese'
  },
  {
    value: 'zh-hk',
    label: 'Chinese (Traditional)'
  },
  {
    value: 'zh',
    label: 'Chinese (Simplified)'
  }
] as const

i18n
  .use(languageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS: 'translation',
    fallbackLng: FALLBACK_LANGUAGE,
    fallbackNS: 'translation',
    debug: false,
    cleanCode: true,
    interpolation: { escapeValue: false }
  })

export default i18n
