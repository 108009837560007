import React, { useEffect } from 'react'
import { useSnackbar } from '@kyc-ui/react'
import useApp from 'src/hooks/app/useApp'

const Snackbar = () => {
  const { addSnackbar } = useSnackbar()
  const { error } = useApp()
  useEffect(() => {
    if (error) {
      addSnackbar({ message: error.message, status: 'error' })
    }
  }, [addSnackbar, error])
  return <></>
}

export default Snackbar
