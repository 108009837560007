import { createTheme } from '@kyc-ui/react'
import 'src/theming/payoneer/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import { ReactComponent as Logo } from 'src/theming/payoneer/images/logo.svg'
import { ReactComponent as LogoIcon } from 'src/theming/payoneer/images/logo-icon.svg'
import { ReactComponent as Location } from 'src/theming/payoneer/images/location.svg'
import { ReactComponent as Agreement } from 'src/theming/payoneer/images/agreement.svg'
import Login from 'src/theming/payoneer/images/login.svg'
import Background from 'src/theming/payoneer/images/background.png'
import uploadAnimation from 'src/theming/payoneer/lotties/upload.json'
import checkAnimation from 'src/theming/payoneer/lotties/check.json'
import pinAnimation from 'src/theming/payoneer/lotties/pin.json'
import Loading from 'src/theming/payoneer/images/loading.gif'
import emptyMemberList from 'src/theming/payoneer/lotties/empty-member-list.json'
import { ReactComponent as CompletedImage } from 'src/theming/payoneer/images/check-docs.svg'
import { ReactComponent as EntityBeforeWeBegin } from 'src/theming/payoneer/images/entity-before-we-begin.svg'
import { ReactComponent as EntityApplicantProfile } from 'src/theming/payoneer/images/entity-applicant-profile.svg'
import { ReactComponent as EntityCompanyIdentity } from 'src/theming/payoneer/images/entity-company-identity.svg'
import { ReactComponent as EntityControllingBodies } from 'src/theming/payoneer/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/theming/payoneer/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/theming/payoneer/images/entity-psc.svg'
import { ReactComponent as PageNotFound } from 'src/theming/payoneer/images/page-not-found.svg'
import AcceptedDocumentsImg from 'src/theming/payoneer/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/payoneer/lotties/selfie.json'
import POIImg from 'src/theming/payoneer/lotties/poi-accepted-doc.json'

const fonts = {
  system: SYSTEM_FONTS,
  body: `'Roboto Condensed', ${SYSTEM_FONTS}!important`
}

const palette = {
  primary: {
    main: '#0075E1',
    light: '#5FB2FF',
    dark: '#1257A9',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#00A75B',
    light: '#31DA89',
    dark: '#007540',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#1994F1',
    dark: '#1274D0',
    light: '#3EA3F3',
    contrastText: '#ffffff'
  },
  warning: {
    main: '#ED6C02',
    dark: '#A64C01',
    light: '#FFB547',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#00C555',
    dark: '#34AC42',
    light: '#A2FFA0',
    contrastText: '#252A36'
  },
  error: {
    main: '#E90022',
    dark: '#00B54A',
    light: '#00C555',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#252526',
    secondary: '#666666',
    disabled: '#A8A8A8'
  },
  background: {
    paper: '#FFFFFF'
  },
  action: {
    active: 'rgba(37, 37, 38, 0.7)',
    hover: 'rgba(37, 37, 38, 0.04)',
    selected: 'rgba(37, 37, 38, 0.08)',
    disabled: 'rgba(37, 37, 38, 0.26)',
    disabledBackground: 'rgba(37, 37, 38, 0.12)',
    focus: 'rgba(37, 37, 38, 0.12)'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '32px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
      paragraphSpacing: 0
    },
    h2: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '0px',
      paragraphSpacing: 0
    },
    h3: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '20px',
      lineHeight: '133%',
      letterSpacing: '0.15px',
      paragraphSpacing: 0
    },
    h4: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '16px',
      lineHeight: '133%',
      letterSpacing: '0.15px',
      paragraphSpacing: 0
    },
    h5: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '14px',
      lineHeight: '133%',
      letterSpacing: '0.1px',
      paragraphSpacing: 0
    },
    h6: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: '13px',
      lineHeight: '133%',
      letterSpacing: '0.1px',
      paragraphSpacing: 0
    },
    subtitle1: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
      paragraphSpacing: 12
    },
    subtitle2: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.15px',
      paragraphSpacing: 8
    },
    body1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '15px',
      lineHeight: '150%',
      letterSpacing: '0.5px',
      paragraphSpacing: 8
    },
    body2: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '13px',
      lineHeight: '143%',
      letterSpacing: '0.25px',
      paragraphSpacing: 8
    },
    button: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.46px',
      paragraphSpacing: 0,
      '& .MuiButton-sizeSmall': {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.46px',
        paragraphSpacing: 0
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '0.46px',
        paragraphSpacing: 0
      }
    },
    caption: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '12px',
      lineHeight: '166%',
      letterSpacing: '0px',
      paragraphSpacing: 0
    },
    overline: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '12px',
      lineHeight: '266%',
      letterSpacing: '1px',
      paragraphSpacing: 0
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: 'rgba(239, 239, 239, 1)',
          color: 'rgba(102, 102, 102, 1)'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#BDBDBE'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial!important',
          borderRadius: '6px!important',
          '&.MuiButton-outlinedSizeMedium': {
            fontSize: '16px',
            color: '#6D68DC',
            border: '1px solid #6D68DC',
            background: 'white',
            '&:hover': {
              backgroundImage: 'linear-gradient(95.74deg, #5173FF -30.01%, #973F96 142.86%)',
              border: '1px solid #6D68DC',
              color: '#ffffff'
            }
          },
          '&.Mui-disabled': {
            backgroundImage: 'none',
            border: 'none'
          }
        },
        contained: {
          border: 'none',
          '&:hover': {
            border: 'none'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'initial!important',
          '&.MuiListItemButton-root': {
            border: 'none',
            boxShadow: 'none',
            borderRadius: '4px'
          },
          '&.MuiButton-containedPrimary': {
            backgroundImage: 'linear-gradient(95.74deg, #D85AD6 -53.26%, #0075E1 118.75%)',
            color: '#ffffff',
            border: 'none',
            '&:hover': {
              backgroundImage: 'linear-gradient(95.74deg, #5173FF -30.01%, #973F96 142.86%)',
              color: '#ffffff'
            }
          },
          '&.MuiButton-containedSizeMedium': {
            fontSize: '16px',
            border: 'none'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'none',
          '&.MuiPaper-Outlined': {
            border: 'none',
            boxShadow: 'none'
          },
          '&.MuiPaper-rounded': {
            border: 'none',
            boxShadow: 'none'
          },
          '&.profileCard': {
            background: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '608px'
          },
          '&.whatNextCard': {
            textAlign: 'left',
            alignItems: 'start'
          },
          '&.completedSubCard h2': {
            fontSize: '23px',
            lineHeight: '143%',
            letterSpacing: '0.15px',
            paragraphSpacing: '8px'
          },
          '&.completedCard h1': {
            fontSize: '28px',
            lineHeight: '122%',
            fontWeight: 'medium'
          },
          '&.completedCard p': {
            fontSize: '26px',
            lineHeight: '175%',
            fontWeight: 'regular',
            letterSpacing: '0.15px'
          },
          '&.completedCard': {
            alignItems: 'center',
            textAlign: 'center'
          }
        }
      }
    },
    // custom type size------------------
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
          '&.agreementTitle': {
            fontWeight: FONT_WEIGHT.bold,
            color: '#252526',
            fontSize: '24px!important',
            lineHeight: '133.4%',
            letterSpacing: '0px',
            paragraphSpacing: 0,
            padding: '8px 0'
          },
          '&.agreementDevicePrompt': {
            display: 'none'
          },
          '&.header': {
            fontWeight: FONT_WEIGHT.bold,
            color: '#252526',
            fontSize: '28px!important',
            lineHeight: '133.4%',
            letterSpacing: '0px',
            paragraphSpacing: 0
          },
          '&.MuiDialogTitle-root': {
            fontWeight: FONT_WEIGHT.bold,
            fontSize: '24px!important',
            lineHeight: '133.4%',
            letterSpacing: '0px',
            paragraphSpacing: 0
          },
          '&.MuiTypography-h6': {
            fontWeight: FONT_WEIGHT.bold,
            fontSize: '18px',
            lineHeight: '114%',
            letterSpacing: '0.46px',
            paragraphSpacing: 0
          },
          '&.MuiTypography-h5': {
            fontWeight: FONT_WEIGHT.bold,
            fontSize: '18px',
            lineHeight: '114%',
            letterSpacing: '0.46px',
            paragraphSpacing: 0
          },
          '&.MuiTypography-body1': {
            fontWeight: FONT_WEIGHT.normal,
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '0.15px',
            paragraphSpacing: 8
          }
        }
      }
    },
    // end custom font size
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial!important',
          borderRadius: 6,
          backgroundImage: 'linear-gradient(95.74deg, #D85AD6 -53.26%, #0075E1 118.75%)',
          color: '#ffffff',
          letterSpacing: '0.46px',
          '&:hover': {
            backgroundImage: 'linear-gradient(95.74deg, #5173FF -30.01%, #973F96 142.86%)',
            border: 'none'
          },
          '&.Mui-disabled': {
            backgroundImage: 'none',
            border: 'none'
          },
          '&.MuiButton-text': {
            backgroundImage: 'none',
            border: 'none'
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '18px',
            fontWeight: 700,
            minHeight: '40px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 24px 16px',
          justifyContent: 'flex-start'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: '#252A363B'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#252A366B'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'none'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          color: palette.action.active
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(95.34deg, rgb(255 73 1 / 20%) -15.87%, rgb(214 86 217 / 20%) 44.88%, rgb(1 147 245 / 20%) 111.9%)',
          '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(95.34deg, #FF4901 -15.87%, #D656D9 44.88%, #0193F5 111.9%)'
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(37, 37, 38, 0.04)',
            color: 'rgba(37, 37, 38, 1)',
            '&:hover': {
              backgroundColor: 'rgba(37, 37, 38, 0.14)'
            }
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(168, 168, 168, 1)'
          }
        }
      }
    }
  },
  palette,
  images: {
    upload: JSON.stringify(uploadAnimation),
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    loading: Loading,
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: LogoIcon,
    location: Location,
    login: Login,
    completed: CompletedImage,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    entityApplicantProfile: EntityApplicantProfile,
    entityCompanyIdentity: EntityCompanyIdentity,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc,
    notFound: PageNotFound,
    acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
    selfieImg: JSON.stringify(SelfieImg),
    poiImg: JSON.stringify(POIImg)
  },
  colorName: {
    button: 'primary',
    linearProgress: 'info',
    checkbox: 'primary',
    stepColor: 'primary.main',
    entityButton: 'primary'
  },
  others: {
    loginBackgroundSize: 'contain',
    customize: {
      background: {
        opacity: 0.05,
        background: 'linear-gradient(252.93deg, #D054CE 33.59%, #0092F4 59.88%, #20DC86 87.87%)',
        filter: 'blur(142.5px)'
      },
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      },
      loginIllustrationCard: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
      loginCard: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
      completedArea: {
        justifyContent: 'flex-start'
      }
    }
  }
}

export default theme
