import { createTheme } from '@kyc-ui/react'
import 'src/theming/grab/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import { ReactComponent as Logo } from 'src/theming/grab/images/logo.svg'
import { ReactComponent as Location } from 'src/theming/grab/images/location.svg'
import { ReactComponent as Agreement } from 'src/theming/grab/images/agreement.svg'
import Background from 'src/theming/grab/images/background.svg'
import Login from 'src/theming/grab/images/login.svg'
import uploadAnimation from 'src/theming/grab/lotties/upload.json'
import checkAnimation from 'src/theming/grab/lotties/check.json'
import pinAnimation from 'src/theming/grab/lotties/pin.json'
import emptyMemberList from 'src/theming/grab/lotties/empty-member-list.json'
import { ReactComponent as EntityBeforeWeBegin } from 'src/theming/grab/images/entity-before-we-begin.svg'
import { ReactComponent as EntityControllingBodies } from 'src/theming/grab/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/theming/grab/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/theming/grab/images/entity-psc.svg'

const fonts = {
  system: SYSTEM_FONTS,
  body: `'Sanomat Sans', ${SYSTEM_FONTS}`
}

const palette = {
  primary: {
    main: '#03B14F',
    light: '#58E47D',
    dark: '#008022',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#001E1F',
    light: '#284546',
    dark: '#000000',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#00A5CF',
    dark: '#00769E',
    light: '#5FD6FF',
    contrastText: '#383E43'
  },
  warning: {
    main: '#F36D27',
    dark: '#EF5A1D',
    light: '#FBB87C',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#03CA5A',
    dark: '#00982C',
    light: '#5FFE89',
    contrastText: '#383E43'
  },
  error: {
    main: '#DC3232',
    dark: '#A3000B',
    light: '#FF695C',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#383E43',
    secondary: '#383E43B2',
    disabled: '#383E4361'
  },
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF'
  },
  action: {
    active: '#383E4399',
    hover: '#383E430A',
    selected: '#383E4314',
    disabled: '#383E4342',
    disabledBackground: '#383E431F',
    focus: '#383E431F'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '95px',
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '59px',
      lineHeight: '120%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '48px',
      lineHeight: '116.7%'
    },
    h4: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '34px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px'
    },
    h5: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '24px',
      lineHeight: '133.4%'
    },
    h6: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '20px',
      lineHeight: '160%',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '16px',
      lineHeight: '175%',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '14px',
      lineHeight: '157%',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.5px'
    },
    body2: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.25px'
    },
    button: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '1.25px',
      '& .MuiButton-sizeSmall': {
        fontSize: '12px',
        lineHeight: '22px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '12px',
      lineHeight: '166%',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '10px',
      lineHeight: '266%',
      letterSpacing: '1.5px'
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#383E431F'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#252A363B'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: '#252A363B'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#252A366B'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#383E4380'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          color: palette.action.active
        }
      }
    }
  },
  palette,
  images: {
    upload: JSON.stringify(uploadAnimation),
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: Logo,
    location: Location,
    login: Login,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    individualBeforeWeBegin: EntityBeforeWeBegin,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc
  },
  colorName: {
    button: 'primary',
    linearProgress: 'primary',
    checkbox: 'primary',
    stepColor: 'secondary.main',
    cardBorderColor: '#4A4B651F',
    entityButton: 'primary'
  },
  others: {
    customize: {
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
}

export default theme
