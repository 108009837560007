import { trim, replace, camelCase, isObject, lowerCase, startCase } from 'lodash'

import { IValidationError } from 'src/typings/types'
import { DONOT_CONVERT_ROLES } from 'src/constants/entity'
import { EMYINFO_ENTITY_PATHNAME } from 'src/constants/app'

/**
 * Converts a string to TitleCase
 * @param {string} str input string
 * @returns {string} TitleCased version of `str`
 */
export function toTitleCase(str: string): string {
  return (
    str
      .split('/')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .pop() ?? ''
  )
}

/**
 * Converts a DD-MM-YYYY to MM-DD-YYYY format
 * @param {string} oldDate input string
 * @returns {string} MDY format version of `str`
 */
export const convertToMDY = (oldDate?: string) => {
  const date = oldDate ?? new Date()
  const dateArr = date.toString().split('/')
  return `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`
}

/**
 * Checks if a string contains a word
 * @param {string} string input string
 * @param {string} word word to check for
 */
export const stringContainsWord = (string: string | undefined, word: string) => {
  const str = string ?? 'qwerty'
  return str.toLowerCase().includes(word.toLowerCase())
}

/**
 * Checks if a string starts with a word and ends with a word
 * @param {str} str input string
 * @param {start} start the start of the string
 * @param {end} end the end of the string
 */
export const removeSpecificString = (str: string, start: string, end: string) => {
  return str.replace(new RegExp(`^${start}(.*)${end}$`), '$1')
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  return new File([blob], fileName, { type: 'image/jpeg' })
}

export const stringifyValidationError = (err: IValidationError) => {
  try {
    return Object.values(err.errors || {})
      .map((e) => (Array.isArray(e) ? e[0] : e))
      .join(' ')
  } catch {
    return ''
  }
}

export function html2Text(html: string): string {
  return trim(replace(html, /<[^>]*(>|$)| |‌|»|«|>/g, ' ').replace(/\s+/g, ' '))
}

export function camelCaseKeys(object: any): any {
  if (typeof object === 'string') {
    return object
  }
  if (Array.isArray(object)) {
    return object.map((item) => camelCaseKeys(item))
  }

  return Object.entries(object).reduce((carry, [key, value]) => {
    carry[camelCase(key) as string] = Array.isArray(value)
      ? value.map((v) => camelCaseKeys(v))
      : isObject(value)
      ? camelCaseKeys(value)
      : value

    return carry
  }, {} as Record<string, any>)
}

const ignoreStartCaseStrings = ['Personnes exerçant un contrôle important (PCI)']

export function kycStartCase(str?: string): string | undefined {
  if (!str || ignoreStartCaseStrings.includes(str)) {
    return str
  }
  return DONOT_CONVERT_ROLES.includes(str) ? str : startCase(lowerCase(str))
}

export function isValidNumber(number?: number | string | null): boolean {
  if (number === '-' || !number) {
    return true
  }
  return !isNaN(+number)
}

export function greaterThanZero(number?: number | string | null): boolean {
  if (number === '-' || !number) {
    return true
  }
  if (isValidNumber(number)) {
    return Number(number) > 0
  }
  return false
}

export function exceedDecimalPlaces(number?: number | string | null): boolean {
  if (number === '-' || !number) {
    return true
  }
  if (greaterThanZero(number)) {
    const decimal = String(number || '').split('.')
    if (decimal.length > 1) {
      return decimal[1].length <= 2
    }
    return true
  }
  return false
}

export function getSingpassRedirectUrl(withDashboard = false): string {
  return (
    process.env.REACT_APP_SINGPASS_REDIRECT_URL ||
    `${window.location.protocol}//${window.location.host}${withDashboard ? '/dashboard' : ''}${
      EMYINFO_ENTITY_PATHNAME.singpassMyInfo
    }`
  )
}
