import React, { useState, useEffect, useMemo } from 'react'
import { browserName } from 'react-device-detect'
import { AlertDialog, FlagIconSource } from '@kyc-ui/react'
import useUser from 'src/hooks/user/useUser'
import { PageLoader } from 'src/components/layout'
import useApp, { useSettings } from 'src/hooks/app/useApp'
import useSteps from 'src/hooks/case/useSteps'
import { PageContent } from 'src/components/layout/home/PageContent'
import ErrorPage from 'src/pages/Error'
import { LayoutRoot } from 'src/components/layout/home'
import useBroadcast from 'src/hooks/common/useBroadcast'
import useQuery from 'src/hooks/common/useQuery'
import useTranslation from 'src/hooks/common/useTranslation'
import storage from 'src/utils/storage'
import { Background } from 'src/components/layout/Background'

const AuthenticatedApp = React.lazy(() => import('src/pages/authenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('src/pages/unauthenticatedApp'))

const App = () => {
  useBroadcast()
  useSettings()
  const tb = useTranslation('browser')
  const { apiUrl, isEntity, isMobile, isExternalAuthorize, background } = useApp()
  const { isPayoneer } = useQuery()
  const { signalToken } = useUser()
  const [openDialog, setOpenDialog] = useState(false)
  const { getSteps, isFetchStepListError, fetchStepListError, isFetchStepListLoading } = useSteps()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (signalToken) {
      getSteps()
    }
  }, [getSteps, signalToken])

  useEffect(() => {
    if (apiUrl && storage.getAPIServerUrl() !== apiUrl) {
      storage.setAPIServerUrl(apiUrl)
    }
    setTimeout(() => {
      setIsReady(!!apiUrl)
    })
  }, [apiUrl])

  const isSamSungOrXiaoMiMobileBrowser = useMemo(() => {
    return isMobile && (browserName === 'Samsung Browser' || browserName === 'MIUI Browser')
  }, [isMobile])

  useEffect(() => {
    if (isSamSungOrXiaoMiMobileBrowser) {
      setOpenDialog(true)
    }
  }, [isSamSungOrXiaoMiMobileBrowser])

  return (
    <>
      <Background sx={background} />
      <FlagIconSource />
      {isReady && !isExternalAuthorize && (
        <>
          {signalToken ? (
            <>
              {isFetchStepListLoading && <PageLoader />}
              {isFetchStepListError && (
                <LayoutRoot>
                  <PageContent>
                    {!isEntity && fetchStepListError && (
                      <ErrorPage message={fetchStepListError.message} refreshData={getSteps} />
                    )}
                  </PageContent>
                </LayoutRoot>
              )}
              <AuthenticatedApp />
            </>
          ) : (
            <UnauthenticatedApp />
          )}
        </>
      )}
      {isSamSungOrXiaoMiMobileBrowser && (
        <AlertDialog
          PaperProps={{
            sx: {
              width: '600px',
              '.MuiDialogActions-root': {
                '>div': {
                  justifyContent: isPayoneer ? 'flex-start' : 'flex-end'
                }
              },
              '.MuiButton-outlined': {
                display: 'none'
              }
            }
          }}
          message={tb('alertMessage')}
          noText={undefined}
          open={openDialog}
          title={tb('alertTitle')}
          yesAction={() => setOpenDialog(false)}
          yesText={tb('alertOk')}
          messageColor="text.primary"
        />
      )}
    </>
  )
}

export { App }
