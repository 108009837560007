import Cookies from 'js-cookie'
import { TOKEN } from 'src/constants/storage'

const BlobAPI = {
  async getPdfUrl(docUrl: string): Promise<string> {
    const res = await fetch(docUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get(TOKEN)}`
      }
    })

    if (res.ok && res.status !== 204) {
      const blob = await res.blob()
      if (blob.size) {
        const newBlob = await new Blob([blob], { type: 'application/pdf' })
        return URL.createObjectURL(newBlob)
      }
    }
    return ''
  }
}

export default BlobAPI
