import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import appSlice from 'src/state/app/slice'
import userSlice from 'src/state/user/slice'
import caseSlice from 'src/state/case/slice'
import entitySlice from 'src/state/entity/slice'
import errorHandler, { rtkQueryErrorLogger } from 'src/middlewares/errorHandler'
import responseHandler from 'src/middlewares/responseHandler'
import { uploadPortalApiSlice } from 'src/state/rtkQueries/uploadPortalApiSlice'
import { singPassApi } from './state/singpass/api'
import { singPassSlice } from './state/singpass/slice'

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    user: userSlice.reducer,
    case: caseSlice.reducer,
    [singPassApi.reducerPath]: singPassApi.reducer,
    [singPassSlice.name]: singPassSlice.reducer,
    entity: entitySlice.reducer,
    [uploadPortalApiSlice.reducerPath]: uploadPortalApiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
      .concat(singPassApi.middleware, errorHandler)
      .concat(uploadPortalApiSlice.middleware)
      .concat(rtkQueryErrorLogger)
      .concat(responseHandler)
      .concat(errorHandler)
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch
export type GetState = () => RootState
