import { TDocument } from 'src/typings/types'

export const stepNames = {
  IndividualSummary: 'summary',
  Location: 'location',
  KYCDocuments: 'kycDocuments',
  Selfie: 'selfie',
  PhotoId: 'photoid',
  ProofOfAddress: 'poa',
  CustomDocuments: 'custom',
  AdditionalInfo: 'additional',
  SingPassMyInfo: 'myinfo',
  SingPassIdentiface: 'identiface',
  BankReference: 'Bankreference',
  ProfessionalProfileCV: 'ProfessionalProfileCV'
}

export const fixedDocuments = [stepNames.Selfie, stepNames.PhotoId, stepNames.ProofOfAddress]

export const CHECKBOX_TRUE = 'True'
export const CHECKBOX_FALSE = 'False'

export const FIXED_DOCUMENT_INFOS = [
  {
    id: 0,
    description: 'Photo ID',
    documentType: 'photoid',
    url: '',
    originalUrl: '',
    previewUrl: '',
    updateDate: null,
    fileName: '',
    uploadValidationStatus: ''
  },
  {
    id: 0,
    description: 'Selfie',
    documentType: 'selfie',
    url: '',
    originalUrl: '',
    previewUrl: '',
    updateDate: null,
    fileName: '',
    uploadValidationStatus: ''
  },
  {
    id: 0,
    description: 'Proof of Address',
    documentType: 'poa',
    url: '',
    originalUrl: '',
    previewUrl: '',
    updateDate: '',
    fileName: '',
    uploadValidationStatus: ''
  }
] as TDocument[]
