import { split, includes, keys } from 'lodash'
import { LANGUAGES, FALLBACK_LANGUAGE, CHINESE_TRADITION, CHINESE_TRADITION_VARIANTS } from 'src/constants/app'
import storage from 'src/utils/storage'

export interface LanguageDetectorOption {
  order: string[]
  lookupLocalStorage: string
}

export const localStorageDetector = {
  name: 'localStorageDetector',

  lookup(options: LanguageDetectorOption) {
    let found

    if (options.lookupLocalStorage) {
      const lng = storage.getLocalStorage(options.lookupLocalStorage)
      if (lng) found = lng
    }

    return found
  },
  cacheUserLanguage(lng: string, options: LanguageDetectorOption) {
    if (options.lookupLocalStorage) {
      storage.setLocalStorage(options.lookupLocalStorage, lng)
    }
  }
}

export const navigatorDetector = {
  name: 'navigatorDetector',

  lookup() {
    let found

    if (typeof navigator !== 'undefined') {
      if (navigator.language) {
        found = navigator.language
      } else if (navigator.languages) {
        found = navigator.languages[0]
      }
    }

    if (found) {
      if (CHINESE_TRADITION_VARIANTS.includes(found.toLowerCase())) {
        return CHINESE_TRADITION
      }
      found = split(found, '-')?.[0]
      return includes(keys(LANGUAGES), found) ? found : FALLBACK_LANGUAGE
    }

    return FALLBACK_LANGUAGE
  },
  cacheUserLanguage(lng: string, options: LanguageDetectorOption) {
    if (options.lookupLocalStorage) {
      storage.setLocalStorage(options.lookupLocalStorage, lng)
    }
  }
}
