import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { TCountry } from 'src/typings/case'
import { getDocumentInfoByStep, getStepList, pinLocation, updateIndividual } from 'src/state/case/actions'
import {
  PropertiesTab,
  TStepObj,
  TabProperty,
  TDocumentStepInfo,
  TLocationStepInfo,
  TIndividualStepInfo
} from 'src/typings/types'
import { PROFILE_DIRECTIVE_NAME, LOCATION_DIRECTIVE_NAME, DOCUMENT_DIRECTIVE_NAME } from 'src/constants/directiveNames'
import { actions as userActions } from 'src/state/user/slice'
import reducers from './reducers'

export interface State {
  countries: TCountry[]
  nationalities: TCountry[]
  finishedSteps: string[]
  declinedSteps: string[]
  isFetchStepListError?: boolean
  isFetchStepListLoading?: boolean
  stepListFetched?: boolean
  fetchStepListError?: Error
  stepObj: TStepObj
  isUploadingDetails?: boolean
  backBtnClickedTime: number
}

const initialState = {
  countries: [],
  nationalities: [],
  stepListFetched: false,
  finishedSteps: [],
  declinedSteps: [],
  stepObj: {} as TStepObj,
  isUploadingDetails: true,
  backBtnClickedTime: 0
} as State

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers,
  extraReducers(builder) {
    builder
      .addCase(getStepList.pending, (state) => {
        state.isFetchStepListError = false
        state.isFetchStepListLoading = true
        state.fetchStepListError = undefined
      })
      .addCase(getStepList.fulfilled, (state, action) => {
        const { payload } = action
        state.stepObj = payload.steps.slice().reduce((acc, step) => {
          if (step.directiveName) {
            acc[step.directiveName] = step
          }
          return acc
        }, {} as Record<string, TDocumentStepInfo | TLocationStepInfo | TIndividualStepInfo>) as TStepObj
        state.isFetchStepListError = false
        state.isFetchStepListLoading = false
        state.fetchStepListError = undefined
        state.stepListFetched = true
      })
      .addCase(getStepList.rejected, (state, action) => {
        state.isFetchStepListError = true
        state.isFetchStepListLoading = false
        state.fetchStepListError = action.error as Error
      })
      .addCase(updateIndividual.fulfilled, (state, action) => {
        const { payload } = action
        let firstName = payload.firstName
        if (payload.middleName) {
          firstName += ` ${payload.middleName}`
        }
        const stepObjProfile = state.stepObj[PROFILE_DIRECTIVE_NAME]
        state.stepObj = {
          ...state.stepObj,
          [PROFILE_DIRECTIVE_NAME]: {
            ...stepObjProfile,
            nationalityCountryId: payload.nationalityCountryId,
            firstName,
            lastName: payload.lastName,
            birthDate: payload.birthDate ? dayjs(payload.birthDate).format('DD/MM/YYYY') : payload.birthDate,
            address: {
              addressLine1: payload.addressLine1,
              addressLine2: payload.addressLine2,
              city: payload.city,
              country: 'country name',
              countryId: payload.countryId,
              stateProvince: payload.stateProvince,
              postcode: payload.postcode
            },
            tabs: stepObjProfile.tabs.map((tab: PropertiesTab) => {
              const properties = tab.properties.map((property: TabProperty) => {
                if (Object.keys(payload.properties).includes(property.fieldName)) {
                  return {
                    ...property,
                    fieldValue: payload.properties[property.fieldName] as string
                  }
                }
                return property
              })
              return {
                ...tab,
                properties: properties
              }
            })
          }
        }
      })
      .addCase(pinLocation.fulfilled, (state, action) => {
        const { payload } = action
        state.stepObj = {
          ...state.stepObj,
          [LOCATION_DIRECTIVE_NAME]: {
            ...state.stepObj[LOCATION_DIRECTIVE_NAME],
            lat: payload.lat,
            lng: payload.lng
          }
        }
      })
      .addCase(getDocumentInfoByStep.fulfilled, (state, action) => {
        const { payload } = action
        const documentInfos = state.stepObj[DOCUMENT_DIRECTIVE_NAME].documentInfos
        ;(payload.documentInfos || []).forEach((documentInfo) => {
          const index = documentInfos.findIndex((doc) => doc.documentType === documentInfo.documentType)
          if (index > -1) {
            documentInfos[index] = documentInfo
          } else {
            documentInfos.push(documentInfo)
          }
        })
        state.stepObj = {
          ...state.stepObj,
          [DOCUMENT_DIRECTIVE_NAME]: {
            ...state.stepObj[DOCUMENT_DIRECTIVE_NAME],
            documentInfos
          }
        }
      })
      .addCase(userActions.logout, (state) => {
        state.stepListFetched = false
        state.finishedSteps = []
        state.declinedSteps = []
        state.stepObj = {} as TStepObj
      })
  }
})

export default caseSlice
export const actions = caseSlice.actions
