import { createTheme } from '@kyc-ui/react'
import 'src/theming/lsh/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import { ReactComponent as Logo } from 'src/theming/lsh/images/logo.svg'
import { ReactComponent as LogoIcon } from 'src/theming/lsh/images/logo-icon.svg'
import { ReactComponent as Location } from 'src/theming/lsh/images/location.svg'
import { ReactComponent as Agreement } from 'src/theming/lsh/images/agreement.svg'
import { ReactComponent as Completed } from 'src/theming/lsh/images/completed.svg'
import Background from 'src/theming/lsh/images/background.svg'
import Login from 'src/theming/lsh/images/login.svg'
import uploadAnimation from 'src/theming/lsh/lotties/upload.json'
import checkAnimation from 'src/theming/lsh/lotties/check.json'
import pinAnimation from 'src/theming/lsh/lotties/pin.json'
import Loading from 'src/theming/lsh/images/loading.gif'
import emptyMemberList from 'src/theming/lsh/lotties/empty-member-list.json'
import { ReactComponent as EntityBeforeWeBegin } from 'src/theming/lsh/images/entity-before-we-begin.svg'
import { ReactComponent as EntityControllingBodies } from 'src/theming/lsh/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/theming/lsh/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/theming/lsh/images/entity-psc.svg'
import AcceptedDocumentsImg from 'src/theming/lsh/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/lsh/lotties/selfie.json'
import POIImg from 'src/theming/lsh/lotties/poi-accepted-doc.json'

const fonts = {
  system: SYSTEM_FONTS,
  body: `'Roboto Condensed', ${SYSTEM_FONTS}`
}

const palette = {
  primary: {
    main: '#CA1527',
    light: '#FF5550',
    dark: '#910000',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#027461',
    light: '#47A38E',
    dark: '#004837',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#005D84',
    dark: '#003457',
    light: '#488AB4',
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#CA5E15',
    dark: '#933000',
    light: '#FF8D45',
    contrastText: '#3333331F'
  },
  success: {
    main: '#007278',
    dark: '#00464C',
    light: '#49A1A7',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#7A2426',
    dark: '#490000',
    light: '#AD514E',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#333333',
    secondary: '#333333CC',
    disabled: '#33333361'
  },
  background: {
    paper: '#FFFFFF',
    default: '#F5F6FA'
  },
  action: {
    active: '#33333399',
    hover: '#3333330A',
    selected: '#33333314',
    disabled: '#33333342',
    disabledBackground: '#3333331F',
    focus: '#3333331F'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '60px',
      lineHeight: '120%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '48px',
      lineHeight: '116.7%'
    },
    h4: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '34px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px'
    },
    h5: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '24px',
      lineHeight: '133.4%'
    },
    h6: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '20px',
      lineHeight: '160%',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '16px',
      lineHeight: '175%',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '14px',
      lineHeight: '157%',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.5px'
    },
    body2: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.25px'
    },
    button: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '14px!important',
      lineHeight: '26px',
      letterSpacing: '1.25px',
      '& .MuiButton-sizeSmall': {
        fontSize: '14px!important',
        lineHeight: '22px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '16px!important',
        lineHeight: '26px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '12px',
      lineHeight: '166%',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '10px',
      lineHeight: '266%',
      letterSpacing: '1.5px'
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#3333331F'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#4444443B'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: '#4444443B'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#0000006B'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#00000080'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          color: palette.action.active,
          '&.home': {
            position: 'fixed',
            backgroundColor: 'transparent',
            color: palette.secondary.contrastText
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked svg': {
            color: palette.primary.light
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#9AC7C0',
          '& .MuiLinearProgress-bar': {
            backgroundColor: palette.secondary.main
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.agreementTitle': {
            color: palette.primary.main
          }
        }
      }
    }
  },
  palette,
  images: {
    entityCompleted: JSON.stringify(checkAnimation),
    upload: JSON.stringify(uploadAnimation),
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    loading: Loading,
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: LogoIcon,
    location: Location,
    completed: Completed,
    login: Login,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    individualBeforeWeBegin: EntityBeforeWeBegin,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc,
    acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
    selfieImg: JSON.stringify(SelfieImg),
    poiImg: JSON.stringify(POIImg)
  },
  colorName: {
    button: 'secondary',
    linearProgress: 'info',
    checkbox: 'primary',
    stepColor: 'secondary.main',
    addButton: 'primary'
  },
  colorString: {
    checkbox: palette.primary.light,
    cardOutline: palette.primary.light
  },
  others: {
    loginBackgroundSize: 'cover',
    customize: {
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
}

export default theme
