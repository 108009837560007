import storage from 'src/utils/storage'
import { createInstance } from 'src/utils/fetch'
import { TLoginData } from 'src/typings/user'
import { TUploadFile } from 'src/typings/types'

const fetch = createInstance({
  baseURL: `${storage.getAPIServerUrl()}/Authorize/`
})

const UserAPI = {
  async login(token: string): Promise<TLoginData> {
    const { data } = await fetch.post('Login', { token })
    return data
  },
  async loginSignal(): Promise<string> {
    const { data } = await fetch.get('LoginSignal')
    return data?.token
  },
  async getUploadFileUrl(signal?: AbortSignal, extension = 'jpg'): Promise<TUploadFile> {
    const { data } = await fetch.get(`GetUploadFileUrl?extension=${extension}`, { signal })

    return data
  },
  async hasAgreedWithTerms(): Promise<boolean> {
    const { data } = await fetch.get('HasAgreedWithTerms')

    return data
  },
  async agreeWithTerms(): Promise<{ status: number }> {
    const { data } = await fetch.post('AgreeWithTerms')
    return data
  }
}

export default UserAPI
