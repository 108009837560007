import { useCallback, ReactNode } from 'react'
import { useTranslation as useI18NTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'
import type { TErrorCode } from 'src/typings/types'
import { fileSizeKb, fileSizeMb } from 'src/constants/fileUpload'
import useQuery from './useQuery'

export default function useTranslation(keyPrefix = 'dashboard.alertMessage') {
  const { brand } = useQuery()
  const { t } = useI18NTranslation(brand ? [brand, 'translation'] : 'translation', { keyPrefix })
  return t
}

export function usePureTranslation() {
  const { brand } = useQuery()
  const { t } = useI18NTranslation(brand ? [brand, 'translation'] : 'translation')
  return t
}

export function useI18N() {
  const { i18n } = useI18NTranslation()
  return i18n
}

export function useBtnTranslation(keyPrefix = 'btnText') {
  const { brand } = useQuery()
  const { t: btnT } = useI18NTranslation(brand ? [brand, 'translation'] : 'translation', { keyPrefix })
  return btnT
}

export function useErrorMessageTranslation(keyPrefix = 'dashboard.alertMessage') {
  const et = useTranslation(keyPrefix)

  return useCallback(
    (errorCodes: TErrorCode[]) => {
      errorCodes = uniqBy(errorCodes, 'Code')
      return errorCodes
        .map((error) => et(error.Code, error.Description, { mb: fileSizeMb, kb: fileSizeKb }))
        .join('\r\n')
    },
    [et]
  )
}

export function useTranslations(keys: string[], keyPrefix = '', replaces?: { [key: string]: string | ReactNode }) {
  const { brand } = useQuery()
  const { t } = useI18NTranslation(brand ? [brand, 'translation'] : 'translation', { keyPrefix })
  const translatedHash: Record<string, string> = {}
  keys.forEach(
    (key) =>
      (translatedHash[key] = t(key, {
        defaultValue: key,
        ...replaces
      }))
  )
  return translatedHash
}
