import { Middleware } from '@reduxjs/toolkit'
import { actions as appSliceActions } from 'src/state/app/slice'
import { GLOBAL_LOADING_MESSAGE_ACTION_MAPPING } from 'src/constants/app'

const documentActions = ['getUploadFileUrl', 'createBlobInContainer', 'uploadDocument']
const ignoreActions = ['submit', 'submitCase', 'autoCheckFieldValidation']
const responseHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const requestStatus = action.meta?.requestStatus
    const actionName = action.meta ? action.type.split('/').splice(-2, 1)?.[0] : undefined
    const endpointName = action.meta?.arg?.endpointName
    const requestId = action.meta?.requestId
    const loadingMessage = actionName ? GLOBAL_LOADING_MESSAGE_ACTION_MAPPING[endpointName as string] : undefined

    if (requestStatus && requestId) {
      switch (requestStatus) {
        case 'fulfilled':
        case 'rejected':
          if (ignoreActions.includes(endpointName) || ignoreActions.includes(actionName)) {
            break
          }
          setTimeout(() => {
            loadingMessage && dispatch(appSliceActions.updateGlobalLoadingMessage(''))
            if (documentActions.includes(endpointName) || documentActions.includes(actionName)) {
              dispatch(
                appSliceActions.updateDocumentRequestCount({
                  requestId,
                  count: -1
                })
              )
            } else {
              dispatch(
                appSliceActions.updateGlobalLoadingRequestCount({
                  requestId,
                  count: -1
                })
              )
            }
          }, 10)
          break
        case 'pending':
          loadingMessage && dispatch(appSliceActions.updateGlobalLoadingMessage(loadingMessage))
          if (ignoreActions.includes(endpointName) || ignoreActions.includes(actionName)) {
            break
          }
          if (documentActions.includes(endpointName) || documentActions.includes(actionName)) {
            dispatch(
              appSliceActions.updateDocumentRequestCount({
                requestId,
                count: 1
              })
            )
          } else {
            dispatch(
              appSliceActions.updateGlobalLoadingRequestCount({
                requestId,
                count: 1
              })
            )
          }
          if (actionName === 'uploadFile' || endpointName === 'uploadDocument') {
            dispatch(appSliceActions.updateDocumentRequestCount({ requestId: 'async', count: 1 }))
            dispatch(
              appSliceActions.setDocumentRequestTimeout(
                setTimeout(() => {
                  dispatch(appSliceActions.resetDocumentRequestCount('async'))
                }, 60 * 5 * 1000)
              )
            )
          }
          break
      }
    }
    return next(action)
  }

export default responseHandler
