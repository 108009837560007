import { EFieldType, PropertiesTab, TabProperty, TIndividualSummaryRequest } from 'src/typings/types'
import { TPersonalData, TSingPassMyInfo } from 'src/typings/singpass'
import { convertToMDY } from 'src/utils/stringHelper'

export const tabPropertiesToFormik = (identityTab: PropertiesTab | undefined, singpassTabs: TabProperty[]) => {
  const identityTabProperties = identityTab?.properties || []
  const identityTabFieldNames = identityTabProperties.map((prop) => prop.fieldName) || []
  const filteredProperties = [
    ...singpassTabs.filter((prop) => !identityTabFieldNames.includes(prop.fieldName)),
    ...identityTabProperties
  ].filter((prop) => prop.fieldName !== 'Gender')
  return {
    ...identityTab,
    properties: filteredProperties.map((p) => {
      if (p.fieldType === EFieldType.DateTime && p.fieldValue) {
        return {
          ...p,
          fieldValue: convertToMDY(p.fieldValue)
        }
      }
      return p
    })
  } as unknown as PropertiesTab
}

export const myInfoToFormValue = (
  myInfoData: TPersonalData,
  countries: { id: number; name: string }[]
): TSingPassMyInfo => {
  const [lastName, ...firstName] = myInfoData.name.split(' ')
  const add1 =
    myInfoData.regAdd?.line1 ??
    [
      ['', myInfoData?.regAdd?.street],
      ['Unit', myInfoData?.regAdd?.unit],
      ['Floor', myInfoData?.regAdd?.floor],
      ['Building', myInfoData?.regAdd?.building],
      ['Block', myInfoData?.regAdd?.block]
    ]
      .filter(([_, value]) => !!value)
      .map(([key, value]) => `${key} ${value}`)
      .join(', ')
  return {
    principalName: myInfoData.principalName ?? myInfoData.name,
    firstName: firstName.join(' ') || 'N/A',
    lastName: lastName,
    dateOfBirth: myInfoData.dob,
    nationality: myInfoData.nationality,
    address1: add1,
    address2: myInfoData?.regAdd?.line2 ?? '',
    city: '',
    state: '',
    country: myInfoData.regAdd?.country ?? '',
    countryId: countries.find(
      (country) => (country.name || '').toLowerCase() === myInfoData.regAdd?.country?.toLowerCase()
    )?.id,
    postcode: myInfoData.regAdd?.postal ?? '',
    properties: {}
  }
}

const MYINFO_TO_TAB = [
  ['Email', 'email'],
  ['NRIC/FIN', 'uinfin'],
  ['Phone', 'mobileNo'],
  ['Alias', 'aliasName'],
  ['Residential Status', 'residentialStatus']
] as [string, keyof TPersonalData][]
export const myInfoToTab = (tab: PropertiesTab | undefined, myInfoData: TPersonalData | undefined) => {
  if (!tab || !myInfoData) return
  const result = JSON.parse(JSON.stringify(tab)) as PropertiesTab

  MYINFO_TO_TAB.forEach(([fieldName, myInfoKey]) => {
    const prop = result.properties.find((p) => p.fieldName === fieldName)
    if (prop) prop.fieldValue = (myInfoData[myInfoKey] as string) || prop.fieldValue
  })

  return result
}
export const myInfoToSingpassTab = (props: TabProperty[], myInfoData: TPersonalData | undefined) => {
  if (!props || !myInfoData) return []
  const result = JSON.parse(JSON.stringify(props)) as TabProperty[]

  MYINFO_TO_TAB.forEach(([fieldName, myInfoKey]) => {
    const prop = result.find((p) => p.fieldName === fieldName)
    if (prop) {
      if (prop.fieldName === 'NRIC/FIN') {
        prop.isEditable = false
      }
      prop.fieldValue = myInfoData[myInfoKey] as string
    }
  })

  return result
}
export const formToSingpassTab = (props: TabProperty[], formValue: TSingPassMyInfo & Record<string, string>) => {
  if (!props || !formValue) return props
  const result = JSON.parse(JSON.stringify(props)) as TabProperty[]

  MYINFO_TO_TAB.forEach(([fieldName]) => {
    const field = result.find((p) => p.fieldName === fieldName)
    if (field) {
      field.fieldValue = formValue.properties[fieldName] || ''
    }
  })

  return result
}

export const formValueToRequest = (value: TSingPassMyInfo): Partial<TIndividualSummaryRequest> => {
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    birthDate: value.dateOfBirth,
    nationalityCountryId: value.nationality,
    addressLine1: value.address1,
    addressLine2: value.address2,
    city: value.city,
    countryId: value.countryId,
    postcode: value.postcode,
    stateProvince: value.state,
    properties: {
      ...value.properties
    },
    linkProperties: {}
  }
}
