import React, { useCallback, ReactNode } from 'react'
import { useNotistackSnackbar, Box } from '@kyc-ui/react'
import { useErrorMessageTranslation } from 'src/hooks/common/useTranslation'
import type { TErrorCode } from 'src/typings/types'

export function useAlertErrors() {
  const translation = useErrorMessageTranslation()
  const { enqueueSnackbar } = useNotistackSnackbar()

  return useCallback(
    (errorCodes: TErrorCode[], snackbarStatus: 'success' | 'info' | 'warning' | 'error', separator = '') => {
      errorCodes.forEach((error) => {
        const message: string = translation([error])
        let messageNode: ReactNode | string = message
        if (separator) {
          messageNode = message.split(separator).map((msg) => <Box key={msg}>{msg}</Box>)
        }

        enqueueSnackbar(messageNode, { severity: snackbarStatus, autoHideDuration: 5000, onAction: () => {} })
      })
    },
    [enqueueSnackbar, translation]
  )
}
