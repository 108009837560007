import { CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react'
import { useMediaQuery } from '@kyc-ui/react'
import { useLocation, useSearchParams } from 'react-router-dom'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { getSettings } from 'src/state/app/actions'
import { actions as appSliceActions } from 'src/state/app/slice'
import storage from 'src/utils/storage'
import signalrConnect from 'src/websocket/ws'
import useTheme from 'src/hooks/common/useTheme'
import { BrandingAgreementLinks } from 'src/typings/user'
import { TGlobalLoading } from 'src/typings/app'
import { useGetConfigurationSettingsQuery } from 'src/state/rtkQueries/entityRequest'
import useUser from 'src/hooks/user/useUser'
import { IConfigurationSetting } from 'src/typings/entity'
import {
  BRANDS,
  ENTITY_PATHNAMES,
  ENTITY_UPLOAD_SETTINGS,
  EXTERNAL_AUTHORIZE_PATHNAME,
  INDIVIDUAL_PATHNAME,
  INDIVIDUAL_UPLOAD_SETTINGS,
  MYINFO_ENTITY_PATHNAMES,
  SETTINGS,
  SINGPASS_FLOW_PATHNAME
} from 'src/constants/app'
import useQuery from 'src/hooks/common/useQuery'

interface UseAPPReturn {
  apiUrl?: string
  uploadProcessDone: boolean
  entityPath: string
  isExternalAuthorize?: boolean
  isDocumentValidation: boolean
  error?: Error
  accessToken?: string | null
  isMobile: boolean
  isIndividual: boolean
  isMyInfoIndividual: boolean
  isEntity: boolean
  isMyInfoEntity: boolean
  isSingpassFlow: boolean
  showGlobalLoading: boolean
  globalLoading: TGlobalLoading
  globalLoadingMessage?: string
  brandingAgreementLinks: BrandingAgreementLinks
  isDocumentRequesting: boolean
  configurationSettings?: Record<string, IConfigurationSetting>
  checkLiveness?: IConfigurationSetting
  selfieCaptureIndividualConfig?: IConfigurationSetting
  poiCaptureIndividualConfig?: IConfigurationSetting
  poaCaptureIndividualConfig?: IConfigurationSetting
  customDocCaptureIndividualConfig?: IConfigurationSetting
  selfieCaptureEntityConfig?: IConfigurationSetting
  poiCaptureEntityConfig?: IConfigurationSetting
  poaCaptureEntityConfig?: IConfigurationSetting
  customDocCaptureEntityConfig?: IConfigurationSetting
  isCaptureOnly?: boolean
  isUploadOnly?: boolean
  isCaptureAndUpload?: boolean
  background: CSSProperties
  updateDocumentRequestCount: (requestId: string, count: number) => void
}

export function useSettings() {
  const dispatch = useDispatch()
  const bootstrapedRef = useRef(false)
  const { logout } = useUser()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const signalToken = useSelector((state) => state.user.signalToken)
  const queryAccessToken = searchParams.get('accessToken')

  useEffect(() => {
    const storedAccessToken = storage.getAccessToken()
    if (queryAccessToken && queryAccessToken !== storedAccessToken) {
      storage.removeRedirectUrl()
    }
  }, [queryAccessToken])

  useEffect(() => {
    if (pathname !== EXTERNAL_AUTHORIZE_PATHNAME) {
      dispatch(getSettings())
    }
  }, [dispatch, pathname])

  const bootstrapAppData = useCallback(async () => {
    const token = storage.getToken()
    if (token && signalToken) {
      bootstrapedRef.current = true
      await signalrConnect(signalToken)
    }
  }, [signalToken])

  useEffect(() => {
    if (window.location.pathname === EXTERNAL_AUTHORIZE_PATHNAME) {
      // http://localhost:3000/externalauthorize?accessToken=D05A4Z
      const storedAccessToken = storage.getAccessToken()
      queryAccessToken && storage.setAccessToken(queryAccessToken)
      if (!storedAccessToken) {
        window.location.href = '/?accessToken=' + queryAccessToken
      }
      // eg: https://uploadportal-dev.knowyourcustomer.com/externalauthorize?accessToken=D06GUS
      storage.removeRedirectUrl()
      dispatch(appSliceActions.updateEntityPath(''))
      logout()
    }
  }, [dispatch, logout, queryAccessToken])

  useEffect(() => {
    if (
      ENTITY_PATHNAMES.includes(window.location.pathname) ||
      MYINFO_ENTITY_PATHNAMES.includes(window.location.pathname)
    ) {
      dispatch(appSliceActions.updateEntityPath(window.location.pathname))
    }
  }, [dispatch])

  useEffect(() => {
    if (signalToken && !bootstrapedRef.current) {
      bootstrapAppData()
    }
  }, [bootstrapAppData, signalToken])

  useEffect(() => {
    if (
      ENTITY_PATHNAMES.includes(window.location.pathname) ||
      INDIVIDUAL_PATHNAME.individual === window.location.pathname
    ) {
      if (window.location.href !== storage.getRedirectUrl()) {
        storage.setRedirectUrl(window.location.href)
      }
    }
  }, [])
}

export default function useApp(): UseAPPReturn {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const { brand } = useQuery()
  const { pathname } = useLocation()
  const { isAuth, signalToken } = useUser()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const {
    apiUrl,
    uploadProcessDone,
    entityPath,
    isDocumentValidation,
    isIndividual,
    isMyInfoIndividual,
    isEntity,
    isMyInfoEntity,
    globalLoading,
    globalLoadingMessage,
    documentRequestCount
  } = useSelector((state) => state.app)
  const showGlobalLoading = Boolean(Object.keys(globalLoading).length)
  const isDocumentRequesting = Boolean(Object.keys(documentRequestCount).length)

  const error = useSelector((state) => state.app.error)
  const brandingAgreementLinks = useSelector((state) => state.app.brandingAgreementLinks)

  const updateDocumentRequestCount = useCallback(
    (requestId: string, count: number) => {
      dispatch(appSliceActions.updateDocumentRequestCount({ requestId, count }))
    },
    [dispatch]
  )

  const { data: configurationSettings = {} } = useGetConfigurationSettingsQuery(
    {
      settings: Object.values({ ...SETTINGS, ...(isEntity ? ENTITY_UPLOAD_SETTINGS : INDIVIDUAL_UPLOAD_SETTINGS) })
    },
    {
      skip: !isAuth
    }
  )

  const background = useMemo(() => {
    if (!signalToken && theme.others?.customize?.loginBackground) return theme.others.customize?.loginBackground
    if (theme.others?.customize?.background) return theme.others.customize?.background
    if (theme.images.background) {
      return {
        background: `url(${theme.images.background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
    const currBrand = BRANDS[brand]
    const isDefault = !currBrand || currBrand === BRANDS.kyc
    return {
      background: isDefault ? theme.palette.primary.main : prefersDarkMode ? '' : theme.palette.background.default
    }
  }, [brand, prefersDarkMode, theme, signalToken])

  return {
    apiUrl,
    uploadProcessDone,
    isExternalAuthorize: pathname === EXTERNAL_AUTHORIZE_PATHNAME,
    entityPath,
    isDocumentValidation,
    error,
    accessToken: useMemo(() => searchParams.get('accessToken') ?? storage.getAccessToken(), [searchParams]),
    showGlobalLoading,
    globalLoading,
    globalLoadingMessage,
    isMobile,
    brandingAgreementLinks,
    isIndividual,
    isMyInfoIndividual,
    isEntity,
    isMyInfoEntity,
    isDocumentRequesting,
    isSingpassFlow: pathname === SINGPASS_FLOW_PATHNAME,
    configurationSettings,
    checkLiveness: configurationSettings.checkLiveness,
    selfieCaptureIndividualConfig: configurationSettings[INDIVIDUAL_UPLOAD_SETTINGS.selfieCaptureIndividualConfig],
    poiCaptureIndividualConfig: configurationSettings[INDIVIDUAL_UPLOAD_SETTINGS.poiCaptureIndividualConfig],
    poaCaptureIndividualConfig: configurationSettings[INDIVIDUAL_UPLOAD_SETTINGS.poaCaptureIndividualConfig],
    customDocCaptureIndividualConfig:
      configurationSettings[INDIVIDUAL_UPLOAD_SETTINGS.customDocCaptureIndividualConfig],
    selfieCaptureEntityConfig: configurationSettings[ENTITY_UPLOAD_SETTINGS.selfieCaptureEntityConfig],
    poiCaptureEntityConfig: configurationSettings[ENTITY_UPLOAD_SETTINGS.poiCaptureEntityConfig],
    poaCaptureEntityConfig: configurationSettings[ENTITY_UPLOAD_SETTINGS.poaCaptureEntityConfig],
    customDocCaptureEntityConfig: configurationSettings[ENTITY_UPLOAD_SETTINGS.customDocCaptureEntityConfig],
    isCaptureOnly: false,
    isUploadOnly: !isMobile, // FIXME: isDesktop or from settings
    isCaptureAndUpload: isMobile, // FIXME: isDesktop or from settings
    background,
    updateDocumentRequestCount
  }
}
