import { createApi } from '@reduxjs/toolkit/query/react'
import storage from 'src/utils/storage'
import { ENTITY_PATHNAME } from 'src/constants/app'
import { generateFetchBaseQuery } from './fetchBaseQuery'

export const getIsDocumentValidation = () => {
  return storage.getEntityPath() === ENTITY_PATHNAME.entityValidation
}

export const getIsMyInfoEntity = () => {
  return !!storage.getLoginData()?.companyDetail?.isMyInfo
}

export const uploadPortalApiSlice = createApi({
  reducerPath: 'uploadPortalApi',
  baseQuery: generateFetchBaseQuery(
    process.env.REACT_APP_ENABLE_MOCK ? `/mock/` : `${storage.getAPIServerUrl() || ''}/`
  ),
  tagTypes: [
    'uploadPortalApi',
    'EntityStepList',
    'EntityMember',
    'EntityJointShareholder',
    'ApplicantProfile',
    'CompanyIdentity',
    'EntityDocument',
    'EntityJointShareholderDocument',
    'LookupCases',
    'EntityCaseStepGroupStatus'
  ],
  refetchOnReconnect: true,
  endpoints: () => ({})
})
