import React from 'react'
import { AppBar, Box, Toolbar } from '@kyc-ui/react'
import Logo from 'src/components/layout/Logo'
import I18N from 'src/components/common/I18N'

export function HomeNavbar() {
  return (
    <AppBar position="static" sx={{ boxShadow: 'none' }} className="home">
      <Toolbar>
        <Box display="flex" flexGrow={1} sx={{ alignItems: 'center', pl: 2 }} className="logoContainer">
          <Logo isHome={true} />
        </Box>
        <I18N />
      </Toolbar>
    </AppBar>
  )
}

HomeNavbar.displayName = 'HomeNavbar'
