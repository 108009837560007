import { HubConnectionBuilder } from '@microsoft/signalr'
import { BroadcastChannel } from 'broadcast-channel'
import storage from 'src/utils/storage'
import type { TSignalRNotification, TValidationResult } from 'src/typings/types'

const signalrConnect = async (signalToken: string) => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${storage.getSignalrServerUrl()}/kyc-hub?access_token=${signalToken}`, {
      withCredentials: false,
      accessTokenFactory: () => signalToken
    })
    .withAutomaticReconnect()
    .build()

  await connection.start()
  const channel = new BroadcastChannel('signalRNotifySuccess')
  connection.on('notifySuccess', (type, title, info: string) => {
    if (title != null) {
      // eslint-disable-next-line no-console
      console.log('Undefined signalR notification.')
      // eslint-disable-next-line no-console
      console.log(`Title: ${title}`)
      // eslint-disable-next-line no-console
      console.log(`Type: ${type}`)
      // eslint-disable-next-line no-console
      console.log(`Info: ${info}`)
      return
    }

    const validationResult = JSON.parse(info) as TValidationResult
    channel.postMessage({
      fileCat: type,
      validationResult
    } as TSignalRNotification)
  })
  const errorChannel = new BroadcastChannel('signalRNotifyError')
  connection.on('notifyError', (type, title, info: string) => {
    if (title != null) {
      // eslint-disable-next-line no-console
      console.log('Undefined signalR notification.')
      // eslint-disable-next-line no-console
      console.log(`Title: ${title}`)
      // eslint-disable-next-line no-console
      console.log(`Type: ${type}`)
      // eslint-disable-next-line no-console
      console.log(`Info: ${info}`)
      return
    }

    const validationResult = JSON.parse(info) as TValidationResult
    errorChannel.postMessage({
      fileCat: type,
      validationResult
    } as TSignalRNotification)
  })

  return signalToken
}

export default signalrConnect
