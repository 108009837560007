import storage from 'src/utils/storage'
import { createInstance } from 'src/utils/fetch'
import appAPI from 'src/api/app'
import {
  StatusReturn,
  TBlobStorage,
  TDeleteFileRequest,
  TDocumentStep,
  TIndividualSummaryRequest,
  TPinLocation,
  TStepList,
  TSubmitCaseRequest,
  TUploadFileRequest,
  TValidateFieldRequest
} from 'src/typings/types'
import BlobAPI from 'src/api/blob'

const fetch = createInstance({
  baseURL: `${storage.getAPIServerUrl()}/CaseRequest/`
})

const CaseAPI = {
  async getStepList(): Promise<TStepList> {
    const { data } = await fetch.get('GetIndividualStepList')
    return data
  },
  async submitCase({ isMyInfoFlow }: TSubmitCaseRequest): Promise<void> {
    await fetch.post(`SubmitCase?isMyInfoFlow=${isMyInfoFlow}`)
  },
  async pinLocation(location: TPinLocation): Promise<TPinLocation> {
    const { data } = await fetch.post('PinLocation', location)
    return data
  },
  async updateIndividual(individual: TIndividualSummaryRequest): Promise<void> {
    return await fetch.post('UpdateIndividual', individual)
  },
  async getDocumentInfoByStep(stepId: number): Promise<TDocumentStep> {
    const { data } = await fetch.get(`${stepId}/GetIndividualDocumentsStep`)
    return data
  },
  async deleteFile(params: TDeleteFileRequest): Promise<void> {
    await fetch.post('DeleteFile', params)
  },
  async createBlobInContainer(blob: TBlobStorage): Promise<void> {
    return await appAPI.createBlobInContainer(blob)
  },
  async uploadFile(params: TUploadFileRequest): Promise<StatusReturn> {
    const { data } = await fetch.post('UploadFiles', params, { signal: params.signal })
    return data
  },
  async getIndividualDocumentPreview(docId: number): Promise<string> {
    return await BlobAPI.getPdfUrl(`${storage.getAPIServerUrl()}/CaseRequest/${docId}/GetIndividualDocumentPreview`)
  },
  async checkFieldValidation(params: TValidateFieldRequest): Promise<boolean> {
    const { data } = await fetch.post('CheckFieldValidation', params)
    return data
  }
}

export default CaseAPI
