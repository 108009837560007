import { PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { cloneDeep, compact, isEqual } from 'lodash'
import { State } from 'src/state/entity/slice'
import {
  EButtonType,
  ECardType,
  EEntityGroupType,
  EMemberType,
  IDocumentResult,
  IGroupResult,
  IJourney,
  ILookupPage,
  IMemberProperty,
  IMemberResult,
  IMemberTab,
  IStepListResult,
  TCard,
  TEntityStep,
  TExtraPermissions,
  TScaffold,
  TStepProgress
} from 'src/typings/entity'
import * as EntityUploadPortalService from 'src/services/EntityUploadPortalService'
import { formatCountryNationality } from 'src/services/EntityUploadPortalService'
import {
  EMEMBER_TYPE,
  ENTITY_DIRECTIVE_NAME,
  ENTITY_UPLOAD_FIELD_NAMES,
  ENTITY_UPLOAD_PROPERTIES_NAMES,
  EStepType,
  GROUP_NUMBER_MAPPING,
  IdentityConst,
  IdentityDocumentStepName,
  STEP_NAME,
  STEP_PREFIX,
  EVALIDATION_ERROR
} from 'src/constants/entity'
import { buttonGroup } from 'src/constants/entitySteps/common'
import { ICountry, INationalitiesWithJurisdictions } from 'src/typings/country'
import { DropDownOption, TDropDownOption } from 'src/typings/types'
import storage from 'src/utils/storage'
import { getIsMyInfoEntity } from 'src/state/rtkQueries/uploadPortalApiSlice'
import CreateEntityCaseService from 'src/services/CreateEntityCaseService'

function getUniqueNotifications(notifications?: string[]): string[] {
  const uniques: string[] = []
  if (!notifications) {
    return uniques
  }

  notifications.forEach((key: string) => {
    const translated = i18next.t(`translation:entity:${key}`)
    if (uniques.indexOf(translated) === -1) {
      uniques.push(translated)
    }
  })

  return uniques
}

const findRootStep = (state: State, currentStepName: string): TEntityStep | undefined => {
  const rootStepName = currentStepName.split('.')?.[0]

  return state.entitySteps.find((step) => step.name === rootStepName)
}

const findPreviousRootStep = (state: State, currentStepName: string): TEntityStep[] | undefined => {
  const rootStepName = currentStepName.split('.')?.[0]

  const steps: TEntityStep[] = []
  state.entitySteps.every((step) => {
    steps.push(step)
    if (step.name === rootStepName) {
      return false
    }
    return true
  })
  return steps
}

const findShareholderStepById = (state: State, stepId: number, groupId: number): TEntityStep | undefined => {
  let currentStep: TEntityStep | undefined
  const shareholderGroup = state.entitySteps.find((step) => step.groupId === groupId)
  if (shareholderGroup) {
    const subStep = shareholderGroup.subSteps?.find((subStep) => subStep.name === `members-${state.pageIndex ?? 1}`)
    if (subStep) {
      currentStep = subStep.subSteps?.find((subStep) => subStep.caseStepId === stepId)
    }
  }

  return currentStep
}

const findCurrentStepProgress = (state: State, currentStepName: string): TStepProgress | undefined => {
  const stepName = currentStepName.split('.').shift()
  return state.entitySteps.find((step) => step.name === stepName)?.stepProgress
}

const getSubSteps = (result: IStepListResult, fullNamePrefix: string, groupId: number, isIdentity = false) => {
  const subSteps: TEntityStep[] = []

  const identityDocuments = isIdentity
    ? result?.steps?.list?.filter(
        (item) => item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments && item.hasDocuments
      )
    : []
  const otherSteps = isIdentity
    ? result?.steps?.list?.filter((item) => item.directiveName !== ENTITY_DIRECTIVE_NAME.kycDocuments)
    : result?.steps?.list
  const isOwnership = GROUP_NUMBER_MAPPING[groupId] === EEntityGroupType.Shareholders

  otherSteps?.forEach((item) => {
    const name = `${STEP_PREFIX[item.directiveName]}${item.step.caseStepId}`
    const isCompany = item.directiveName === ENTITY_DIRECTIVE_NAME.memberCompany
    const isIndividual = item.directiveName === ENTITY_DIRECTIVE_NAME.memberIndividual
    const isDocument = item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments
    const step: TEntityStep = {
      name,
      directiveName: item.directiveName,
      fullName: `${fullNamePrefix}.${name}`,
      stepProgressVisible: true,
      isEditable: !!item.permissions.canEdit,
      groupId,
      stepType: isDocument ? EStepType.Document : EStepType.Detail,
      caseStepId: item.step.caseStepId,
      caseCommonId: item.caseCommonId,
      isCompany,
      isIndividual,
      isOwnership,
      hasErrorMessage: !!item.errorMessages?.filter((message) => message !== EVALIDATION_ERROR.DocumentMissed)?.length,
      errorMessages: item.errorMessages || [],
      isDeactivated: item.step.isDeactivated,
      isIdentity: item.directiveName === ENTITY_DIRECTIVE_NAME.identityCompany,
      isDocument: item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments,
      hasDocuments: item.hasDocuments,
      cards: [],
      stepPrefix: STEP_PREFIX[item.directiveName],
      stepCaseCommonId: item.caseCommonId
    }
    subSteps.push(step)
  })
  if (!getIsMyInfoEntity() && identityDocuments && identityDocuments.length) {
    const name = IdentityDocumentStepName
    const step: TEntityStep = {
      name,
      directiveName: ENTITY_DIRECTIVE_NAME.kycDocuments,
      fullName: `${fullNamePrefix}.${name}`,
      stepProgressVisible: true,
      groupId,
      stepType: EStepType.Document,
      isCompany: false,
      isIndividual: false,
      isIdentity: false,
      isDocument: true,
      hasErrorMessage: identityDocuments?.reduce((acc, currDoc) => {
        acc = acc || !!currDoc.errorMessages?.filter((message) => message !== EVALIDATION_ERROR.DocumentMissed)?.length
        return acc
      }, false),
      errorMessages: identityDocuments?.reduce((acc, currDoc) => {
        if (currDoc.errorMessages) {
          acc.push(...currDoc.errorMessages)
        }
        return acc
      }, [] as string[]),
      cards:
        identityDocuments?.map((item) => {
          const name = `${STEP_PREFIX[item.directiveName]}${item.step.caseStepId}`
          const isCompany = item.directiveName === ENTITY_DIRECTIVE_NAME.memberCompany
          const isIndividual = item.directiveName === ENTITY_DIRECTIVE_NAME.memberIndividual
          const isDocument = item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments
          const step: TEntityStep = {
            name,
            directiveName: item.directiveName,
            fullName: `${fullNamePrefix}.${name}`,
            stepProgressVisible: true,
            groupId,
            stepType: isDocument ? EStepType.Document : EStepType.Detail,
            caseStepId: item.step.caseStepId,
            caseCommonId: item.caseCommonId,
            isCompany,
            isIndividual,
            isDeactivated: item.step.isDeactivated,
            isIdentity: item.directiveName === ENTITY_DIRECTIVE_NAME.identityCompany,
            isDocument: item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments,
            hasDocuments: item.hasDocuments,
            stepCaseCommonId: item.caseCommonId,
            stepPrefix: STEP_PREFIX[item.directiveName]
          }
          return {
            title:
              item.step.name === 'Company Identity Documents'
                ? 'companyDocuments'
                : item.step.name === 'Other Company Documents'
                ? 'otherCompanyDocuments'
                : item.step.name,
            caseStepId: item.step.caseStepId,
            stepInfo: step
          }
        }) || []
    }
    subSteps.push(step)
  }

  return subSteps
}

/**
 * @ignore update entity root steps
 */
const updateEntitySteps = (state: State, action: PayloadAction<TEntityStep[]>) => {
  const { payload } = action
  state.entitySteps = payload
  state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
}

/**
 * @ignore remove current root step
 */
const removeCurrentRootStep = (state: State, action: PayloadAction<number | undefined>) => {
  const { payload } = action
  if (payload) {
    const entitySteps = cloneDeep(state.entitySteps.filter((step) => step.groupId !== payload))
    const firstRootStep = entitySteps?.[0]
    if (firstRootStep) {
      firstRootStep.subSteps?.every((subStep) => {
        if (subStep.stepType === EStepType.SectionMessage) {
          subStep.cards?.every((card) => {
            if (card.buttons && card.buttons[0]?.buttonType === EButtonType.previous) {
              card.buttons.splice(0, 1)
              return false
            }
            return true
          })
          return false
        }
        return true
      })
    }
    state.entitySteps = entitySteps
    state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
  }
}

/**
 * @ignore update entity root steps status
 */
const updateEntityStepsStatus = (state: State, action: PayloadAction<TScaffold[]>) => {
  const { payload } = action
  const entitySteps = state.entitySteps.slice()
  let changed = false
  entitySteps.every((step) => {
    const scaffold = payload.find((item) => item.caseGroup === step.groupId)
    if (scaffold && step.hasDocumentRequested !== scaffold.hasDocumentRequested) {
      step.hasDocumentRequested = scaffold.hasDocumentRequested
      changed = true
    }
    return true
  })
  if (changed) {
    state.entitySteps = entitySteps
  }
}

/**
 * @ignore update sub steps / groups like:
 * Identity
 * Controlling Entities and Individuals
 * Shareholders and Beneficial Owners
 * Person with Significant Control
 */
const updateSubSteps = (
  state: State,
  action: PayloadAction<{
    groupId: number
    result: IStepListResult
  }>
) => {
  const {
    payload: { groupId, result }
  } = action
  const pageIndex = result?.steps.pageIndex || 1
  const stepByGroupId = state.entitySteps.find((step) => step.groupId === groupId)
  const isIdentity = GROUP_NUMBER_MAPPING[groupId] === IdentityConst
  const caseStepIds: number[] = result?.steps?.list.map((item) => item.step.caseStepId)

  const detailMembers = state.refreshMembers[EStepType.Detail].slice()
  const documentIds = state.refreshMembers[EStepType.Document].slice()
  const jointShareholderMembers = state.refreshMembers[EStepType.ShareholderGroup].slice()
  const isOwnership = GROUP_NUMBER_MAPPING[groupId] === EEntityGroupType.Shareholders

  const { sectionState, notifications } = result || {}

  if (stepByGroupId && stepByGroupId.fullName && stepByGroupId.groupId) {
    const subSteps: TEntityStep[] = []
    if (isIdentity) {
      // For section/group Identity/groupId = 4
      subSteps.push(...getSubSteps(result, stepByGroupId.fullName, stepByGroupId.groupId, true))
    } else {
      // For other sections/groups, per design, should insert a new member list step, and make the members as sub step
      // of the member list
      subSteps.push({
        name: `members-${pageIndex}`,
        fullName: `${stepByGroupId.fullName}.members-${pageIndex}`,
        stepProgressVisible: true,
        caseStepIds,
        groupId,
        isMemberList: true,
        stepType: EStepType.MemberList,
        hasErrorMessage: result.hasErrorMessage,
        shares: result.totalShares,
        cards: [
          {
            total: result?.steps?.itemCount,
            pageSize: 10,
            allowAdd: true,
            shares: result.totalShares,
            members: result?.steps?.list?.map((l) => {
              const type =
                l.directiveName === ENTITY_DIRECTIVE_NAME.memberCompany
                  ? EMemberType.entity
                  : l.directiveName === ENTITY_DIRECTIVE_NAME.memberIndividual
                  ? EMemberType.individual
                  : EMemberType.jointShareholder
              if (l.directiveName === ENTITY_DIRECTIVE_NAME.memberJointShareholder) {
                if (!jointShareholderMembers.includes(l.step.caseStepId)) {
                  jointShareholderMembers.push(l.step.caseStepId)
                }
              } else {
                if (!detailMembers.includes(l.step.caseStepId)) {
                  detailMembers.push(l.step.caseStepId)
                }
                if (l.hasDocuments && !documentIds.includes(l.step.caseStepId)) {
                  documentIds.push(l.step.caseStepId)
                }
              }
              return {
                id: l.step.caseStepId,
                name: l.memberName || l.step.joinShareholderGroupName || l.step.name,
                secondary: l.step.caseRequestTitle || l.step.title,
                shareCount: l.shareCount,
                isDeactivated: l.step.isDeactivated,
                type,
                status: l.step.status,
                isDeletable: l.step.caseStepId < 0,
                isEditable: !!l.permissions?.canEdit,
                isOwnership,
                stepCaseCommonId: l.caseCommonId,
                caseCommonId: l.caseCommonId,
                caseStepGroupId: l.step.caseStepGroupId,
                caseStepId: l.step.caseStepId,
                hasErrorMessage: !!l.errorMessages?.length,
                errorMessages: l.errorMessages,
                stepPrefix: STEP_PREFIX[type],
                extraPermissions: l.jointShareholderGroupSteps?.reduce((acc, current) => {
                  acc[current.step.caseStepId] = current.permissions
                  return acc
                }, {} as TExtraPermissions)
              }
            })
          },
          {
            buttonDirection: 'row',
            buttons: [buttonGroup.previous, buttonGroup.next]
          }
        ],
        subSteps: getSubSteps(result, `${stepByGroupId.fullName}.members-${pageIndex}`, stepByGroupId.groupId)
      })
    }

    if (subSteps.length > 0) {
      if (!stepByGroupId.subSteps) {
        stepByGroupId.subSteps = subSteps
      } else {
        if (isIdentity) {
          if (stepByGroupId.subSteps.length === 1) {
            stepByGroupId.subSteps = [stepByGroupId.subSteps[0], ...subSteps]
          } else {
            const identityDocumentStep = stepByGroupId.subSteps.find(
              (subStep) => subStep.name === IdentityDocumentStepName
            )
            const newIdentityDocumentStep = subSteps?.find((subStep) => subStep.name === IdentityDocumentStepName)
            if (identityDocumentStep && newIdentityDocumentStep) {
              identityDocumentStep.hasErrorMessage = newIdentityDocumentStep.hasErrorMessage
              identityDocumentStep.errorMessages = newIdentityDocumentStep.errorMessages
            }
          }
        } else {
          subSteps.every((subStep) => {
            if (!stepByGroupId.subSteps) {
              stepByGroupId.subSteps = [subStep]
            } else {
              const subStepIndex = stepByGroupId.subSteps?.findIndex((step) => step.fullName === subStep.fullName) ?? -1
              if (subStepIndex > -1) {
                stepByGroupId.subSteps.splice(subStepIndex, 1, subStep)
              } else {
                stepByGroupId.subSteps.push(subStep)
              }
            }
            return true
          })
        }
      }
    }

    // stepByGroupId.visible = sectionState !== 'Created'
    stepByGroupId.hasMember = !!result?.steps?.list?.length
    stepByGroupId.loading = sectionState === 'Processing'
    stepByGroupId.approved = sectionState === 'Approved'
    stepByGroupId.validOrApproved = sectionState === 'Approved' || sectionState === 'Validated'
    stepByGroupId.inManualIntervention = sectionState === 'ManualIntervention'
    stepByGroupId.showSpinner = sectionState === 'Processing' || sectionState === 'ManualIntervention'
    stepByGroupId.valid = sectionState === 'Validated'
    stepByGroupId.notifications = getUniqueNotifications(notifications)

    state.entitySteps = [...state.entitySteps]
    state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
  }

  if (!isEqual(detailMembers, state.refreshMembers[EStepType.Detail])) {
    state.refreshMembers[EStepType.Detail] = detailMembers
  }

  if (!isEqual(documentIds, state.refreshMembers[EStepType.Document])) {
    state.refreshMembers[EStepType.Document] = documentIds
  }

  if (!isEqual(jointShareholderMembers, state.refreshMembers[EStepType.ShareholderGroup])) {
    state.refreshMembers[EStepType.ShareholderGroup] = jointShareholderMembers
  }
}

/**
 * @ignore update jointshareholder member permissions:
 */
const updateSubStepsPermissions = (
  state: State,
  action: PayloadAction<{
    groupId: number
    result: IStepListResult
  }>
) => {
  const {
    payload: { groupId, result }
  } = action
  if (GROUP_NUMBER_MAPPING[groupId] !== IdentityConst) {
    const stepByGroupId = state.entitySteps.find((step) => step.groupId === groupId)
    const listExtraPermissions = result.steps?.list
      ?.filter((l) => l.directiveName === ENTITY_DIRECTIVE_NAME.memberJointShareholder)
      ?.reduce((acc, curr) => {
        acc[curr.step.caseStepId] = curr.jointShareholderGroupSteps?.reduce((accu, current) => {
          accu[current.step.caseStepId] = current.permissions
          return accu
        }, {} as TExtraPermissions)
        return acc
      }, {} as Record<string, TExtraPermissions>)

    if (stepByGroupId && stepByGroupId.fullName && stepByGroupId.groupId) {
      stepByGroupId.subSteps?.every((subStep) => {
        if (subStep.stepType === EStepType.MemberList) {
          subStep.cards?.every((card) => {
            if (card.members) {
              card.members.every((member) => {
                const permissions = listExtraPermissions[String(member.id)]
                if (permissions) {
                  member.extraPermissions = permissions
                }
                return true
              })
            }
            return true
          })
          subStep.subSteps?.every((sub) => {
            const permissions = listExtraPermissions[String(sub.caseStepId)]
            if (permissions) {
              sub.cards?.every((sc) => {
                if (sc.members) {
                  sc.members.every((member) => {
                    member.isEditable = permissions[String(member.id)]?.canEdit ?? true
                    return true
                  })
                }
                return true
              })
              sub.subSteps?.every((subSub) => {
                subSub.isEditable = permissions[String(subSub.caseStepId)]?.canEdit ?? true
                return true
              })
            }
            return true
          })
        }
        return true
      })
    }
  }
}

export const getApplicantCard = (payload: {
  stepId: number
  groupId: number
  result: IMemberResult | IDocumentResult
  visibleFields: string[]
}) => {
  const { stepId, result, groupId, visibleFields } = payload
  const memberResult = result as IMemberResult
  const caseCommonId = memberResult.caseCommonId
  const subSteps: any =
    memberResult?.propertiesTabs?.map((tab: IMemberTab) =>
      EntityUploadPortalService.generateStepCards({
        directiveName: ENTITY_DIRECTIVE_NAME.applicantProfile,
        tab,
        caseStepId: stepId,
        isApplicantProfile: true
      })
    ) || []

  const tabs = subSteps
    .reduce(
      (acc: TCard[], cur: TCard[]) => [
        ...acc,
        ...cur.reduce((accu, curr: TCard) => (curr.tabs ? [...accu, ...curr.tabs] : accu), [] as TCard[])
      ],
      [] as TCard[]
    )
    .reduce((tabAcc: TCard, tab: TCard) => {
      if (!tabAcc.title) {
        tabAcc = { ...tab, properties: tab.properties?.filter((prop) => visibleFields.includes(prop.fieldName)) || [] }
      } else {
        tabAcc.properties = [
          ...(tabAcc.properties ?? []),
          ...(tab.properties?.filter((prop) => visibleFields.includes(prop.fieldName)) ?? [])
        ]
      }
      return tabAcc
    }, {} as TCard)

  return {
    name: 'Applicant',
    directiveName: ENTITY_DIRECTIVE_NAME.applicantProfile,
    fullName: '',
    stepProgressVisible: true,
    groupId,
    caseStepId: stepId,
    caseStepGroupId: groupId,
    caseCommonId: caseCommonId,
    tabIndex: 0,
    type: EMEMBER_TYPE.INDIVIDUAL,
    stepType: EStepType.Detail,
    isCompany: false,
    isIndividual: true,
    isIdentity: false,
    isDocument: false,
    status: memberResult.status,
    isClosed: memberResult.isClosed,
    isLinkedCaseClosed: memberResult.status === 'Closed',
    // KYC.WebApp/Scripts/Custom/CaseSteps/companyidentity.directive.js L34
    isReadOnlyMode: memberResult.isClosed,
    // $scope.companyProperties['Registration Number'];
    hasRegisterNumber: !!memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.RegistrationNumber],
    // !$scope.companyProperties['Company Status'];
    isNotAvailable: !memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.CompanyStatus],
    // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L201
    // $scope.companyDetails.isUnregisteredEntity
    is4UnregisteredEntity: memberResult.isUnregisteredEntity,
    // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L86
    // This field should always be "false" according to the old logic.
    isManualIntervention: false,
    cards: [
      {
        title: 'Applicant',
        tabs: [tabs]
      }
    ]
  }
}

/**
 * @ignore for section Identity only: flatten tabs into multiple pages
 * eg: let's say Identity has tabs in PropertiesTabs: Identity, Additional Info, and Other,
 *     then, the three tabs will be separated into three pages.
 */
const updateIdentitySteps = (
  state: State,
  action: PayloadAction<{
    stepId: number
    groupId: number
    result: IMemberResult | IDocumentResult
    isDocument?: boolean
  }>
) => {
  const {
    payload: { stepId, result, isDocument = false }
  } = action
  const memberResult = result as IMemberResult
  if (!isDocument) {
    state.caseCommonId = memberResult.caseCommonId
  }
  let stepById: TEntityStep | undefined
  let subStepIndex = -1
  state.entitySteps.every((step) => {
    if (step.subSteps) {
      subStepIndex = step.subSteps.findIndex((subStep) => {
        if (isDocument) {
          return subStep.name === IdentityDocumentStepName
        }
        return subStep.caseStepId === stepId
      })
      if (subStepIndex > -1) {
        stepById = step
        return false
      }
    }
    return true
  })
  const subStep = stepById?.subSteps?.[subStepIndex]
  if (stepById && subStepIndex > -1 && subStep) {
    if (isDocument) {
      const documentCards = EntityUploadPortalService.generateStepCards({
        directiveName: subStep?.directiveName,
        documents: (result as IDocumentResult).documents,
        caseStepId: stepId
      })
      const cardIndex = subStep.cards?.findIndex((card) => card.stepInfo?.caseStepId === stepId) ?? -1
      if (cardIndex > -1) {
        if (!documentCards?.[0].documents?.length) {
          // if no documents, remove this card
          subStep.cards?.splice(cardIndex, 1)
        } else {
          const currentCard = subStep.cards?.[cardIndex]
          const docCard = {
            ...currentCard,
            ...documentCards?.[0],
            title: currentCard?.title || documentCards?.[0]?.title
          }
          if (subStep.cards) {
            subStep.cards[cardIndex] = docCard
          } else {
            subStep.cards = documentCards
          }
          const buttonCard = documentCards.slice(-1)?.[0]
          const lastCard = subStep.cards?.slice(-1)?.[0]
          if (!!subStep.cards?.length && lastCard?.cardType !== ECardType.button) {
            subStep.cards.push(buttonCard)
          }
        }
      }
      if (!subStep.cards?.length) {
        // if no document cards, remove this step
        stepById.subSteps?.splice(subStepIndex, 1)
      }
    } else {
      const documentIndex = stepById.subSteps?.findIndex((step) => step.stepType === EStepType.Document) ?? -1
      const removeItems =
        documentIndex > -1
          ? documentIndex - subStepIndex
          : (stepById.subSteps?.length || subStepIndex + 1) - subStepIndex
      stepById.subSteps?.splice(
        subStepIndex,
        // Remove the original page
        removeItems,
        // Separate tabs and insert them into independence pages
        ...(memberResult?.propertiesTabs?.map((tab: IMemberTab) => {
          const name = `${STEP_PREFIX[ENTITY_DIRECTIVE_NAME.identityCompany]}${subStep.caseStepId}-${tab.order}`
          return {
            name,
            directiveName: subStep?.directiveName,
            fullName: `${stepById?.fullName}.${name}`,
            stepProgressVisible: true,
            groupId: subStep.groupId,
            caseStepId: subStep.caseStepId,
            caseStepGroupId: subStep.caseStepGroupId,
            caseCommonId: subStep.caseCommonId,
            tabIndex: tab.order,
            type: subStep.type,
            stepType: EStepType.Detail,
            isCompany: subStep.directiveName === ENTITY_DIRECTIVE_NAME.memberCompany,
            isIndividual: subStep.directiveName === ENTITY_DIRECTIVE_NAME.memberIndividual,
            isIdentity: subStep.directiveName === ENTITY_DIRECTIVE_NAME.identityCompany,
            isDocument: subStep.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments,
            status: memberResult.status,
            isClosed: memberResult.isClosed,
            isLinkedCaseClosed: memberResult.status === 'Closed',
            // KYC.WebApp/Scripts/Custom/CaseSteps/companyidentity.directive.js L34
            isReadOnlyMode: memberResult.isClosed,
            // $scope.companyProperties['Registration Number'];
            hasRegisterNumber: !!memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.RegistrationNumber],
            // !$scope.companyProperties['Company Status'];
            isNotAvailable: !memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.CompanyStatus],
            // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L201
            // $scope.companyDetails.isUnregisteredEntity
            is4UnregisteredEntity: memberResult.isUnregisteredEntity,
            // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L86
            // This field should always be "false" according to the old logic.
            isManualIntervention: false,
            cards: EntityUploadPortalService.generateStepCards({
              directiveName: subStep?.directiveName,
              tab,
              caseStepId: stepId,
              documentStepId: memberResult.documentStepId
            })
          }
        }) || [])
      )
    }

    state.entitySteps = [...state.entitySteps]
    state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
  }
}

const findMemberStepFullNamePrefix = (
  groupId: number,
  stepId: number,
  entitySteps: TEntityStep[],
  isJointShareholderMember?: boolean
): string | undefined => {
  let fullName: string | undefined = undefined
  if (isJointShareholderMember) {
    const shareholderSubSteps = entitySteps.find((step) => step.groupType === EEntityGroupType.Shareholders)?.subSteps
    shareholderSubSteps?.every((step) => {
      if (step.stepType === EStepType.MemberList) {
        return step.subSteps?.every((subStep) => {
          if (subStep.caseStepIds?.includes(stepId)) {
            fullName = subStep.fullName
            return false
          }
          return true
        })
      }
      return true
    })
  } else {
    entitySteps?.forEach((step) => {
      if (!fullName) {
        if (step.groupId === groupId && step.caseStepIds && step.caseStepIds.includes(stepId)) {
          fullName = step.fullName
        } else if (step.subSteps) {
          fullName = findMemberStepFullNamePrefix(groupId, stepId, step.subSteps)
        }
      }
    })
  }

  return fullName
}

/**
 * @ignore update sub step's cards
 */
const updateMemberCards = (
  state: State,
  action: PayloadAction<{
    stepId: number
    groupId: number
    result: IMemberResult
  }>
) => {
  const {
    payload: { stepId, groupId, result: memberResult }
  } = action
  const isCompany = memberResult?.type === EMEMBER_TYPE.COMPANY
  const type = isCompany ? EMemberType.entity : EMemberType.individual

  const fullNamePrefix = findMemberStepFullNamePrefix(groupId, stepId, state.entitySteps)
  if (fullNamePrefix) {
    const currentMembers = EntityUploadPortalService.findCurrentStep(state.entitySteps, fullNamePrefix)
    currentMembers?.cards?.forEach((card) => {
      card.members?.forEach((member) => {
        if (member.id === stepId) {
          member.secondary = memberResult.role || member.secondary
          member.isDeactivated = memberResult.isDeactivated
          member.type = memberResult.type
          member.shareCount = memberResult.shareCount
        }
      })
    })

    const fullName = `${fullNamePrefix}.${STEP_PREFIX[type]}${stepId}`
    const currentStep = EntityUploadPortalService.findCurrentStep(state.entitySteps, fullName)
    if (currentStep) {
      const cards: TCard[] = [...(currentStep.cards || [])]
      if (cards.findIndex((card) => !!card.buttons) > -1) {
        // Remove exist buttons card
        cards.pop()
      }

      const tabs: TCard[] = []
      memberResult.propertiesTabs.forEach((tab: IMemberTab) => {
        if (tab.name === IdentityConst && memberResult.caseLinkProperties) {
          tab.properties.push(...(memberResult.caseLinkProperties || []))
        }

        const tabCards = EntityUploadPortalService.generateStepCards({
          directiveName: currentStep?.directiveName,
          tab,
          caseStepId: stepId,
          documentStepId: memberResult.documentStepId
        })
        // Remove buttons card
        tabCards.pop()
        // Merge tabs into one card
        tabCards.forEach((card) => {
          tabs.push(...(card.tabs || []))
        })
      })

      const existingDetailsIndex = cards.findIndex((card) => card.title === 'details')
      if (existingDetailsIndex > -1) {
        cards.splice(existingDetailsIndex, 1, {
          title: 'details',
          tabs
        })
      } else {
        cards.splice(0, 0, {
          title: 'details',
          tabs
        })
      }

      currentStep.caseCommonId = memberResult.caseCommonId
      currentStep.documentStepId = memberResult.documentStepId
      currentStep.journeyId = memberResult.journeyId ?? currentStep.journeyId
      currentStep.linkJourneyId = memberResult.linkJourneyId ?? currentStep.linkJourneyId
      currentStep.isLinkedCaseClosed = memberResult.status === 'Closed'
      // KYC.WebApp/Scripts/Custom/CaseSteps/companyidentity.directive.js L34
      currentStep.isReadOnlyMode = memberResult.isClosed
      // $scope.companyProperties['Registration Number'];
      currentStep.hasRegisterNumber = !!memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.RegistrationNumber]
      // !$scope.companyProperties['Company Status'];
      currentStep.isNotAvailable = !memberResult.properties[ENTITY_UPLOAD_PROPERTIES_NAMES.CompanyStatus]
      // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L201
      // $scope.companyDetails.isUnregisteredEntity
      currentStep.is4UnregisteredEntity = memberResult.isUnregisteredEntity
      // KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js L86
      // This field should always be "false" according to the old logic.
      currentStep.isManualIntervention = false

      cards.push({
        buttons: [buttonGroup.saveAndAddAnother, buttonGroup.saveAndReturn, buttonGroup.cancel]
      })

      currentStep.cards =
        cards.map((card) => {
          if (card.cardType === 'document') {
            card.documents = card.documents?.map((document) => {
              document.documentStepId = currentStep.documentStepId
              return document
            })
          }
          return card
        }) || []
      currentStep.stepName = memberResult.stepName || currentStep.stepName
      currentStep.type = memberResult.type || currentStep.type
      currentStep.shareCount = memberResult.shareCount
      currentStep.isShareholderMember = currentStep.stepName?.toUpperCase() === 'JOINT SHAREHOLDER MEMBER'
    }
  }
}

/**
 * @ignore update document cards
 */
const updateDocumentCards = (
  state: State,
  action: PayloadAction<{
    stepId: number
    groupId: number
    isJointShareholderMember?: boolean
    stepPrefix: string
    result: IDocumentResult
  }>
) => {
  const {
    payload: { stepId, groupId, result, stepPrefix, isJointShareholderMember }
  } = action
  const fullNamePrefix = findMemberStepFullNamePrefix(groupId, stepId, state.entitySteps, isJointShareholderMember)
  if (fullNamePrefix && result.documents.length) {
    const fullName = `${fullNamePrefix}.${stepPrefix}${stepId}`
    const currentStep = EntityUploadPortalService.findCurrentStep(state.entitySteps, fullName)
    if (currentStep) {
      const cards: TCard[] = [...(currentStep.cards || [])]
      if (cards.findIndex((card) => !!card.buttons) > -1) {
        // Remove exist buttons card
        cards.pop()
      }

      const tabCards = EntityUploadPortalService.generateStepCards({
        directiveName: currentStep?.directiveName,
        documents: result.documents,
        caseStepId: stepId,
        documentStepId: currentStep.documentStepId
      })
      // Remove buttons card
      tabCards.pop()
      // If there is document card, pop that card first
      const lastCard = cards[cards.length - 1]
      if (lastCard?.cardType === ECardType.document) {
        cards.pop()
      }
      cards.push(...tabCards)

      cards.push({
        buttons: [buttonGroup.saveAndAddAnother, buttonGroup.saveAndReturn, buttonGroup.cancel]
      })

      currentStep.cards =
        cards.map((card) => {
          if (card.cardType === 'document') {
            card.documents = card.documents?.map((document) => {
              document.documentStepId = currentStep.documentStepId
              return document
            })
          }
          return card
        }) || []
    }
  }
}

/**
 * @ignore Click `Next`, `Back`, `Cancel`, `New` etc., need to update current step
 */
const updateCurrentStep = (state: State, action: PayloadAction<string | undefined>) => {
  const { payload } = action
  let stepName = payload
  if (typeof stepName === 'undefined') {
    stepName = state.entitySteps?.[0]?.subSteps?.[0]?.fullName
  }

  const stepNames = stepName?.split('.') ?? []
  if (stepNames.slice(-1)?.[0] === 'members') {
    stepName = stepNames.join('.') + '-1'
  } else {
    stepName = stepNames.join('.')
  }

  if (stepName) {
    state.currentRootStep = findRootStep(state, stepName)
    state.previousRootSteps = findPreviousRootStep(state, stepName)
    state.currentStep = EntityUploadPortalService.findCurrentStep(state.entitySteps, stepName)
    state.currentStepProgress = findCurrentStepProgress(state, stepName)
    if (state.currentStepName !== stepName) {
      state.currentStepName = stepName
    }
  }
}

/**
 * @ignore
 */
const updateCurrentStepIsNewStep = (state: State, action: PayloadAction<boolean>) => {
  const { payload } = action
  const currentStep = EntityUploadPortalService.findCurrentStep(state.entitySteps, state.currentStepName)
  if (currentStep) {
    currentStep.isNewStep = payload
  }
  state.currentStep = currentStep
}

/**
 * @ignore update company and individual's `add step tabs`
 */
const updateAddStepTabs = (
  state: State,
  action: PayloadAction<{
    isCompany: boolean
    result: IMemberTab[]
  }>
) => {
  const { payload } = action
  if (payload.isCompany) {
    state.companyAddStepTabsRaw = payload.result
  } else {
    state.individualAddStepTabsRaw = payload.result
  }
  refreshAddStepTabs(state, payload.isCompany)
}

/**
 * @ignore update company and individual's `add step tabs`
 */
const updateAddStepLinkProperties = (
  state: State,
  action: PayloadAction<{
    isCompany: boolean
    groupId: number
    result: IMemberProperty[]
  }>
) => {
  const {
    payload: { isCompany, result, groupId }
  } = action
  const properties: IMemberProperty[] = []
  result.forEach((propertyItem) => {
    const property = { ...propertyItem }
    if (property.dropDownOptions && property.dropDownOptions.length > 0) {
      storage.setDropDownOptions({
        ...storage.getDropDownOptions(),
        [EntityUploadPortalService.getDropDownOptionKey(property.fieldName, property.id || property.linkPropertyId)]: (
          property.dropDownOptions as DropDownOption[]
        ).map((item) => {
          return {
            option: item.option,
            text: item.text,
            originalData: item.originalData
          } as DropDownOption
        })
      })
      property.dropDownOptions = []
    }
    properties.push(property)
  })
  if (isCompany) {
    if (!state.companyAddStepLinkProperties) {
      state.companyAddStepLinkProperties = {}
    }
    state.companyAddStepLinkProperties[groupId] = properties
  } else {
    if (!state.individualAddStepLinkProperties) {
      state.individualAddStepLinkProperties = {}
    }
    state.individualAddStepLinkProperties[groupId] = properties
  }
}

/**
 * @ignore update company and individual's `all journeys`
 */
const updateAllJourneys = (
  state: State,
  action: PayloadAction<{
    isCompany: boolean
    result: IJourney[]
  }>
) => {
  const {
    payload: { isCompany, result }
  } = action
  if (isCompany) {
    let defaultJourney = result?.[0]
    state.companyJourneys = result?.map((journey) => {
      if (journey.name.toLowerCase().indexOf('sub ') > -1) {
        defaultJourney = journey
      }
      return {
        ...journey,
        option: journey.id,
        text: journey.name
      }
    })
    state.defaultJourneyId.company = defaultJourney?.id
  } else {
    state.individualJourneys = result?.map((journey) => ({
      ...journey,
      option: journey.id,
      text: journey.name
    }))
    const defaultJourney = state.individualJourneys.find(
      ({ text = '', name = '' }) =>
        (text || name).toLowerCase().indexOf('sub') > -1 ||
        (text || name).toLowerCase().indexOf('individuals - shareholder') > -1
    )
    state.defaultJourneyId.individual = state.individualJourneys?.[0].id
    state.defaultJourneyId.individualShareholder = defaultJourney?.id || state.individualJourneys?.[0].id
  }
  refreshAddStepTabs(state, isCompany)
}

const refreshAddStepTabs = (state: State, isCompany: boolean, journeyId?: number, isShareholderStep?: boolean) => {
  if (journeyId) {
    state.defaultJourneyId[isCompany ? 'company' : isShareholderStep ? 'individualShareholder' : 'individual'] =
      journeyId
  }
  const defaultJourney =
    state.defaultJourneyId[isCompany ? 'company' : isShareholderStep ? 'individualShareholder' : 'individual']
  if (
    defaultJourney &&
    ((isCompany && state.companyAddStepTabsRaw) || (!isCompany && state.individualAddStepTabsRaw))
  ) {
    let result: IMemberTab[] | undefined = [
      ...(isCompany ? state.companyAddStepTabsRaw || [] : state.individualAddStepTabsRaw || [])
    ]
    result = compact(
      result?.map((tab) => {
        if (tab.journeyId !== defaultJourney) {
          return undefined
        }
        const item = { ...tab }
        item.properties = [...item.properties] as IMemberProperty[]
        const properties: IMemberProperty[] = []
        item.properties.forEach((propertyItem) => {
          const property = { ...propertyItem }
          if (property.dropDownOptions && property.dropDownOptions.length > 0) {
            const dropdownOptionKey = EntityUploadPortalService.getDropDownOptionKey(property.fieldName, property.id)
            storage.setDropDownOptions({
              ...storage.getDropDownOptions(),
              [dropdownOptionKey]: (property.dropDownOptions as DropDownOption[]).map((item) => {
                return {
                  option: item.option,
                  text: item.text,
                  originalData: item.originalData
                } as DropDownOption
              })
            })
            property.dropDownOptions = []
          }
          if (
            property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction ||
            property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Country ||
            property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Nationality
          ) {
            const options = (
              property.dropDownOptions?.length
                ? property.dropDownOptions
                : CreateEntityCaseService.getJurisdictionsList() || []
            ) as ICountry[]
            property.fieldValue = options[0]?.id || '0'
            property.fieldLabel = options[0]?.text || ''
          }
          properties.push(property)
          if (isCompany && item.name === STEP_NAME.identity) {
            if (property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction) {
              properties.push(EntityUploadPortalService.getEmptyDataSourceProperty(property))
            }
          }
        })

        item.properties = properties
        return item
      })
    )
    if (isCompany) {
      if (!isEqual(state.companyAddStepTabs, result)) {
        state.companyAddStepTabs = result
      }
    } else {
      if (!isEqual(state.individualAddStepTabs, result)) {
        state.individualAddStepTabs = result
      }
    }
  }
}

/**
 * @ignore add or update new step: new member template
 */
const addOrUpdateNewStep = (
  state: State,
  action: PayloadAction<{
    stepName: string
    memberType: string
    isShareholderMember?: boolean
    rawTabs: IMemberTab[]
    linkProperties: IMemberProperty[]
    journeyId?: number
  }>
) => {
  const {
    payload: { stepName, memberType, isShareholderMember, rawTabs: paraRawTabs, linkProperties, journeyId }
  } = action
  const entitySteps = cloneDeep(state.entitySteps)
  const parentStepName = stepName.replace('.new', '')
  const parentStep = EntityUploadPortalService.findCurrentStep(entitySteps, parentStepName)
  const currentStep = parentStep?.subSteps?.find((step) => step.fullName === stepName)
  const currentStepIndex = parentStep?.subSteps?.findIndex((step) => step.fullName === stepName) ?? -1
  const currentRootStep = state.currentRootStep
  const isShareholderStep = currentRootStep?.groupType === 'Shareholders'
  const isCompany = memberType === EMemberType.entity
  const isIndividual = memberType === EMemberType.individual
  const isShareholderGroup = memberType === EMemberType.jointShareholder
  let rawTabs = paraRawTabs
  const defaultJourney =
    journeyId ||
    state.defaultJourneyId[isCompany ? 'company' : isShareholderStep ? 'individualShareholder' : 'individual']
  if (defaultJourney) {
    refreshAddStepTabs(state, isCompany, defaultJourney, isShareholderStep)
    rawTabs =
      cloneDeep(
        isCompany
          ? state.companyAddStepTabs
          : isIndividual
          ? state.individualAddStepTabs
          : state.shareholderGroupProperties
      ) || []
    state.defaultJourneyId[isCompany ? 'company' : isShareholderStep ? 'individualShareholder' : 'individual'] =
      defaultJourney
  }
  if (currentRootStep && parentStep) {
    const newStep: TEntityStep = {
      ...currentStep,
      name: 'new',
      caseStepGroupId: parentStep.caseStepId,
      directiveName:
        memberType === EMemberType.entity
          ? ENTITY_DIRECTIVE_NAME.memberCompany
          : memberType === EMemberType.individual
          ? ENTITY_DIRECTIVE_NAME.memberIndividual
          : undefined,
      fullName: stepName,
      stepProgressVisible: true,
      groupId: currentRootStep.groupId,
      stepType: EStepType.New,
      caseStepId: 0,
      type: isCompany ? EMEMBER_TYPE.COMPANY : isIndividual ? EMEMBER_TYPE.INDIVIDUAL : EMEMBER_TYPE.JOINTSHAREHOLDER,
      isCompany: memberType === EMemberType.entity,
      isNewStep: true,
      isIndividual,
      isShareholderGroup,
      isIdentity: false,
      isDocument: false,
      memberType: memberType as EMemberType,
      isShareholderMember,
      cards: []
    }

    const cards: TCard[] = []
    const tabs: TCard[] = []
    cloneDeep(rawTabs)?.forEach((tab: IMemberTab) => {
      if (tab.name === IdentityConst) {
        tab.properties.push(...(linkProperties || []))

        EntityUploadPortalService.appendExtraProperties({
          tab,
          groupId: currentRootStep.groupId,
          isShareholderMember: isShareholderMember,
          isShareholderGroupItem: isShareholderGroup,
          isNew: true,
          memberType,
          isCompany,
          isIndividual,
          defaultJourney
        })
      }
      const tabCards = EntityUploadPortalService.generateStepCards({
        directiveName: isCompany
          ? ENTITY_DIRECTIVE_NAME.memberCompany
          : isIndividual
          ? ENTITY_DIRECTIVE_NAME.memberIndividual
          : ENTITY_DIRECTIVE_NAME.shareholderGroup,
        tab,
        isNewStep: true,
        memberType
      })

      // Merge tabs into one card
      tabCards.forEach((card) => {
        tabs.push(...(card.tabs || []))
      })
    })
    cards.push({
      title: 'details',
      tabs
    })
    if (isShareholderGroup) {
      cards.push({
        buttons: EntityUploadPortalService.getCurrentStepButtons([], newStep)
      })
    } else {
      cards.push({
        buttons: [buttonGroup.saveAndAddAnother, buttonGroup.saveAndReturn, buttonGroup.cancel]
      })
    }

    newStep.cards = cards
    if (!isEqual(currentStep, newStep)) {
      if (parentStep.subSteps) {
        if (currentStepIndex !== -1) {
          parentStep.subSteps.splice(currentStepIndex, 1, newStep)
        } else {
          parentStep.subSteps.push(newStep)
        }
      } else {
        parentStep.subSteps = [newStep]
      }

      if (!isEqual(state.entitySteps, entitySteps)) {
        state.entitySteps = [...entitySteps]
        state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
      }
    }
  }
}

/**
 * @ignore update shareholder group step
 */
const updateShareholderGroup = (
  state: State,
  action: PayloadAction<{
    stepId: number
    groupId: number
    result: IGroupResult
  }>
) => {
  const {
    payload: { stepId, groupId, result }
  } = action
  const fullNamePrefix = findMemberStepFullNamePrefix(groupId, stepId, state.entitySteps)
  let extraPermissions = {} as TExtraPermissions
  if (fullNamePrefix) {
    const currentMembers = EntityUploadPortalService.findCurrentStep(state.entitySteps, fullNamePrefix)
    currentMembers?.cards?.forEach((card) => {
      card.members?.forEach((member) => {
        if (member.id === stepId) {
          member.name = result.name ?? ''
          member.type = result.type as EMemberType | EMEMBER_TYPE | undefined
          member.shareCount = result.shares
          if (member.extraPermissions) {
            extraPermissions = member.extraPermissions
          }
        }
      })
    })
  }

  const currentStep = findShareholderStepById(state, stepId, groupId)
  const cards: TCard[] = []
  const tabs: TCard[] = []
  const tabCards = EntityUploadPortalService.generateStepCards({
    directiveName: ENTITY_DIRECTIVE_NAME.shareholderGroup,
    tab: EntityUploadPortalService.assignShareholderGroupValues(result, cloneDeep(state.shareholderGroupProperties[0]))
  })
  // Merge tabs into one card
  tabCards.forEach((card) => {
    tabs.push(...(card.tabs || []))
  })
  cards.push({
    title: 'details',
    tabs
  })

  const detailMembers = state.refreshMembers[EStepType.Detail].slice()
  const documentIds = state.refreshMembers[EStepType.JointShareholderDocument].slice()

  const { groupLinks } = result
  cards.push({
    total: groupLinks.length,
    pageSize: 10,
    allowAdd: true,
    hasErrorMessage: result.hasErrorMessage,
    members: groupLinks.map((link) => {
      const type = link.isCompany ? EMemberType.entity : EMemberType.individual
      if (!detailMembers.includes(link.caseStepId)) {
        detailMembers.push(link.caseStepId)
      }
      if (!documentIds.includes(link.caseStepId)) {
        documentIds.push(link.caseStepId)
      }
      return {
        id: link.caseStepId,
        name: link.fullName,
        secondary: 'Joint Shareholder Member',
        jointShareholderId: result.caseStepId,
        hasErrorMessage: !!link.errorMessages?.length,
        errorMessages: link.errorMessages,
        hasDocuments: true,
        type,
        isDeletable: link.caseStepId < 0,
        isEditable: extraPermissions[link.caseStepId]?.canEdit ?? true,
        stepPrefix: STEP_PREFIX[type]
      }
    })
  })

  cards.push({
    buttons: [buttonGroup.saveJointShareholderDetail, buttonGroup.addShareholderMember, buttonGroup.back]
  })

  if (currentStep) {
    currentStep.cards = cards
    currentStep.caseStepIds = groupLinks.map((link) => link.caseStepId)
    currentStep.caseStepGroupId = result.caseStepGroupId
    currentStep.shareCount = result.shares
    currentStep.isShareholderGroup = true
    currentStep.hasErrorMessage = result.hasErrorMessage
    currentStep.subSteps = groupLinks.map((link) => {
      const directiveName = link.isCompany
        ? ENTITY_DIRECTIVE_NAME.memberCompany
        : ENTITY_DIRECTIVE_NAME.memberIndividual
      return {
        name: `${STEP_PREFIX[directiveName]}${link.caseStepId}`,
        directiveName,
        fullName: `${currentStep.fullName}.${STEP_PREFIX[directiveName]}${link.caseStepId}`,
        allEdit: true,
        isEditable: extraPermissions[link.caseStepId]?.canEdit ?? true,
        stepProgressVisible: true,
        groupId: currentStep.groupId,
        isDeactivated: currentStep.isDeactivated,
        caseStepId: link.caseStepId,
        caseCommonId: link.caseCommonId,
        isCompany: link.isCompany,
        type: currentStep.type,
        isIndividual: !link.isCompany,
        isIdentity: false,
        isDocument: false,
        hasDocuments: true,
        cards: [],
        stepType: EStepType.Detail,
        jointShareholderId: result.caseStepId,
        hasErrorMessage: !!link.errorMessages?.length,
        errorMessages: link.errorMessages || []
      }
    })

    state.hashEntityStepsFullNames = EntityUploadPortalService.hashEntityStepsFullNames(state.entitySteps)
  }
  if (!isEqual(detailMembers, state.refreshMembers[EStepType.Detail])) {
    state.refreshMembers[EStepType.Detail] = detailMembers
  }
  if (!isEqual(documentIds, state.refreshMembers[EStepType.JointShareholderDocument])) {
    state.refreshMembers[EStepType.JointShareholderDocument] = documentIds
  }
}

/**
 * @ignore Update lookupCasesOptions
 */
const updateLookupCases = (state: State, action: PayloadAction<ILookupPage>) => {
  const { payload } = action
  const { list, itemCount, pageIndex } = payload
  const options =
    list?.map((lookupCase) => ({
      id: lookupCase.caseCommonId,
      option: lookupCase.caseCommonId.toString(),
      text: EntityUploadPortalService.getLookupCaseText(lookupCase),
      originalData: lookupCase
    })) || []
  if (pageIndex === 1) {
    state.lookupCasesOptions = options
  } else {
    state.lookupCasesOptions.push(...options)
  }
  state.lookupCasesTotalCount = itemCount
}

/**
 * @ignore Update lookupCasesOptions
 */
const updateSelectedExistsCase = (state: State, action: PayloadAction<IMemberResult | undefined>) => {
  const { payload } = action
  state.selectedExistsCase = payload
}

/**
 * @ignore Update Officers status
 */
const updateOfficersStatus = (state: State, action: PayloadAction<Record<string, boolean>>) => {
  state.identityStatus = {
    ...state.identityStatus,
    ...action.payload
  }
}

/**
 * @ignore Update Officers status
 */
const updateNationalitiesWithJurisdictions = (state: State, action: PayloadAction<INationalitiesWithJurisdictions>) => {
  const { payload } = action
  const jurisdictionsList = formatCountryNationality(
    payload.doesJurisdictionListDifferFromCountries ? payload.jurisdictionList : payload.countryList
  )

  const sourcesMap: TDropDownOption = {}
  jurisdictionsList.forEach((jurisdiction) => {
    if (jurisdiction.sources && jurisdiction.sources.length > 0) {
      sourcesMap[
        EntityUploadPortalService.getDropDownOptionKey(ENTITY_UPLOAD_FIELD_NAMES.DataSource, jurisdiction.id)
      ] = jurisdiction.sources.map(
        (source, idx) =>
          ({
            id: idx,
            option: source.name,
            text: source.description
          } as DropDownOption)
      )
    }
  })

  storage.setDropDownOptions({
    ...storage.getDropDownOptions(),
    ...sourcesMap,
    jurisdictionsList: jurisdictionsList as unknown as DropDownOption[],
    nationalitiesList: formatCountryNationality(payload.nationalityList) as unknown as DropDownOption[]
  })
}

/**
 * @ignore update identity document changed
 */
const updateIdentityDocumentChanged = (state: State) => {
  state.identityDocumentChanged = Date.now()
}

/**
 * @ignore update refresh members
 * track the updates of the members, so that when user go back to "member list" or "joint shareholder", app will
 *   refresh the corresponding page
 */
const updateRefreshMembers = (
  state: State,
  action: PayloadAction<{ groupId: number; jointShareholderId?: number }>
) => {
  const {
    payload: { groupId, jointShareholderId }
  } = action
  if (jointShareholderId) {
    // Refresh Joint Shareholder
    const shareholderGroupIds = state.refreshMembers[EStepType.ShareholderGroup]
    if (!shareholderGroupIds.includes(jointShareholderId)) {
      state.refreshMembers[EStepType.ShareholderGroup] = [
        ...state.refreshMembers[EStepType.ShareholderGroup],
        jointShareholderId
      ]
    }
  }
  // Refresh member list
  const groupIds = state.refreshMembers[EStepType.MemberList]
  if (!groupIds.includes(groupId)) {
    state.refreshMembers[EStepType.MemberList] = [...state.refreshMembers[EStepType.MemberList], groupId]
  }
}

/**
 * @ignore clear refresh members
 */
const clearRefreshMembers = (state: State, action: PayloadAction<{ type: string; id: number }>) => {
  const {
    payload: { type, id }
  } = action
  if (type) {
    const numbers = state.refreshMembers[type].slice()
    if (numbers.includes(id)) {
      state.refreshMembers[type] = numbers.filter((n) => n !== id)
    }
  }
}

/**
 * @ignore update pageIndex
 */
const updatePageIndex = (state: State, action: PayloadAction<number | undefined>) => {
  state.pageIndex = action.payload ?? 1
}

/**
 * @ignore update isFetchingList
 */
const updateIsFetchingList = (state: State, action: PayloadAction<boolean>) => {
  state.isFetchingList = action.payload
}

const caseReducers = {
  updateEntitySteps,
  removeCurrentRootStep,
  updateEntityStepsStatus,
  updateCurrentStep,
  updateSubSteps,
  updateSubStepsPermissions,
  updateIdentitySteps,
  updateMemberCards,
  updateDocumentCards,
  updateAddStepTabs,
  updateAddStepLinkProperties,
  updateAllJourneys,
  updateCurrentStepIsNewStep,
  addOrUpdateNewStep,
  updateShareholderGroup,
  updateLookupCases,
  updateSelectedExistsCase,
  updateOfficersStatus,
  updateNationalitiesWithJurisdictions,
  updateIdentityDocumentChanged,
  updateRefreshMembers,
  clearRefreshMembers,
  updatePageIndex,
  updateIsFetchingList
}

export default caseReducers
