import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { actions as userSliceActions } from 'src/state/user/slice'

export class AuthError extends Error {
  code: string

  constructor(message: string) {
    super(message)

    Error.captureStackTrace(this, AuthError)
    this.name = 'AuthError'
    this.code = '401'
  }
}

export class NetworkError extends Error {
  code: string

  constructor(message: string) {
    super(message)

    Error.captureStackTrace(this, NetworkError)
    this.name = 'NetworkError'
    this.code = '503'
  }
}

const errorHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.error && action.error.name === 'AuthError') {
      dispatch(userSliceActions.logout())
      return
    }

    return next(action)
  }

export default errorHandler

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // eslint-disable-next-line no-console
    console.error('rtk query error', api)
  }

  return next(action)
}
