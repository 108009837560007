import { PayloadAction } from '@reduxjs/toolkit'
import storage from 'src/utils/storage'
import { ENTITY_PATHNAME } from 'src/constants/app'
import { State } from './slice'

/**
 * @ignore update global loading request count
 */
const updateGlobalLoadingRequestCount = (state: State, action: PayloadAction<{ requestId: string; count: number }>) => {
  const {
    payload: { requestId, count }
  } = action
  const currentCount = (state.globalLoading[requestId] || 0) + count
  if (currentCount <= 0) {
    delete state.globalLoading[requestId]
  } else {
    state.globalLoading[requestId] = currentCount
  }
}

/**
 * @ignore reset global loading async request count, use when the request timeout
 */
const resetGlobalLoadingRequestCount = (state: State, action: PayloadAction<string>) => {
  delete state.globalLoading[action.payload]
}

/**
 * @ignore update document request count
 */
const updateDocumentRequestCount = (state: State, action: PayloadAction<{ requestId: string; count: number }>) => {
  const {
    payload: { requestId, count }
  } = action
  const currentCount = (state.documentRequestCount[requestId] || 0) + count
  if (currentCount <= 0) {
    delete state.documentRequestCount[requestId]
  } else {
    state.documentRequestCount[requestId] = currentCount
  }
}

/**
 * @ignore reset global loading async request count, use when the request timeout
 */
const resetDocumentRequestCount = (state: State, action: PayloadAction<string>) => {
  delete state.documentRequestCount[action.payload]
  clearTimeout(state.documentRequestCountTimeout)
  state.documentRequestCountTimeout = undefined
}

/**
 * @ignore update global loading async request count
 */
const setDocumentRequestTimeout = (state: State, action: PayloadAction<NodeJS.Timeout>) => {
  if (state.documentRequestCountTimeout) {
    clearTimeout(state.documentRequestCountTimeout)
  }
  state.documentRequestCountTimeout = action.payload
}

/**
 * @ignore update entityPath
 */
const updateEntityPath = (state: State, action: PayloadAction<string>) => {
  const { payload } = action
  if (payload) {
    storage.setEntityPath(payload)
    state.entityPath = payload
    state.isDocumentValidation = payload === ENTITY_PATHNAME.entityValidation
  } else {
    storage.removeEntityPath()
    state.entityPath = ''
    state.isDocumentValidation = false
  }
}

/**
 * @ignore update global Loading message
 */
const updateGlobalLoadingMessage = (state: State, action: PayloadAction<string>) => {
  const { payload } = action
  state.globalLoadingMessage = payload
}

const caseReducers = {
  updateGlobalLoadingRequestCount,
  resetGlobalLoadingRequestCount,
  updateDocumentRequestCount,
  resetDocumentRequestCount,
  setDocumentRequestTimeout,
  updateEntityPath,
  updateGlobalLoadingMessage
}

export default caseReducers
