import React, { FC, ReactNode, useMemo } from 'react'
import { KycProvider } from '@kyc-ui/react'
import { useTranslation } from 'react-i18next'
import { deDE, enUS, esES, frFR, jaJP, zhHK, Localization } from '@mui/material/locale'
import { theme } from 'src/theming/theme'
import useQuery from 'src/hooks/common/useQuery'

interface Props {
  children?: ReactNode
}

const ThemeWrapper: FC<Props> = ({ children }) => {
  const { brand } = useQuery()
  const { i18n } = useTranslation()
  const language: Localization = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case 'fr':
        return frFR
      case 'de':
        return deDE
      case 'es':
        return esES
      case 'zh':
        return zhHK
      case 'jp':
        return jaJP
      default:
        return enUS
    }
  }, [i18n.resolvedLanguage])
  return <KycProvider theme={theme(brand, language)}>{children}</KycProvider>
}

export default ThemeWrapper
