import { createAsyncThunk } from '@reduxjs/toolkit'
import caseAPI from 'src/api/case'
import {
  StatusReturn,
  TBlobStorage,
  TDeleteFileRequest,
  TDocumentStep,
  TIndividualSummaryRequest,
  TPinLocation,
  TSubmitCaseRequest,
  TUploadFileRequest,
  TValidateFieldRequest
} from 'src/typings/types'

export const getStepList = createAsyncThunk('case/getStepList', async () => {
  return await caseAPI.getStepList()
})

export const submitCase = createAsyncThunk('case/submitCase', async (payload: TSubmitCaseRequest) => {
  await caseAPI.submitCase(payload)
})

export const updateIndividual = createAsyncThunk(
  'case/updateIndividual',
  async (individual: TIndividualSummaryRequest, { rejectWithValue }) => {
    try {
      await caseAPI.updateIndividual(individual)
      return Promise.resolve(individual)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const pinLocation = createAsyncThunk(
  'case/pinLocation',
  async (payload: TPinLocation): Promise<TPinLocation> => {
    await caseAPI.pinLocation(payload)
    return Promise.resolve(payload)
  }
)

export const uploadFile = createAsyncThunk(
  'case/uploadFile',
  async (payload: TUploadFileRequest): Promise<StatusReturn> => {
    return await caseAPI.uploadFile(payload)
  }
)

export const deleteFile = createAsyncThunk('case/deleteFile', async (payload: TDeleteFileRequest): Promise<void> => {
  await caseAPI.deleteFile(payload)
})

export const getDocumentInfoByStep = createAsyncThunk(
  'case/getDocumentInfoByStep',
  async (stepId: number): Promise<TDocumentStep> => {
    return await caseAPI.getDocumentInfoByStep(stepId)
  }
)

export const getIndividualDocumentPreview = createAsyncThunk(
  'case/getIndividualDocumentPreview',
  async (docId: number) => {
    return await caseAPI.getIndividualDocumentPreview(docId)
  }
)

export const createBlobInContainer = createAsyncThunk('case/createBlobInContainer', async (blob: TBlobStorage) => {
  return await caseAPI.createBlobInContainer(blob)
})

export const checkFieldValidation = createAsyncThunk(
  'case/checkFieldValidation',
  async (payload: TValidateFieldRequest) => {
    return await caseAPI.checkFieldValidation(payload)
  }
)

export const autoCheckFieldValidation = createAsyncThunk(
  'case/autoCheckFieldValidation',
  async (payload: TValidateFieldRequest) => {
    return await caseAPI.checkFieldValidation(payload)
  }
)
