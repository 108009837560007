import { createTheme } from '@kyc-ui/react'
import 'src/theming/dms/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import { ReactComponent as Logo } from 'src/theming/dms/images/logo.svg'
import { ReactComponent as LogoIcon } from 'src/theming/dms/images/logo-icon.svg'
import { ReactComponent as Location } from 'src/theming/dms/images/location.svg'
import { ReactComponent as Agreement } from 'src/theming/dms/images/agreement.svg'
import Background from 'src/theming/dms/images/background.svg'
import Login from 'src/theming/dms/images/login.svg'
import uploadAnimation from 'src/theming/dms/lotties/upload.json'
import checkAnimation from 'src/theming/dms/lotties/check.json'
import pinAnimation from 'src/theming/dms/lotties/pin.json'
import Loading from 'src/theming/dms/images/loading.gif'
import emptyMemberList from 'src/theming/dms/lotties/empty-member-list.json'
import { ReactComponent as EntityBeforeWeBegin } from 'src/theming/dms/images/entity-before-we-begin.svg'
import { ReactComponent as EntityControllingBodies } from 'src/theming/dms/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/theming/dms/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/theming/dms/images/entity-psc.svg'
import AcceptedDocumentsImg from 'src/theming/dms/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/dms/lotties/selfie.json'
import POIImg from 'src/theming/dms/lotties/poi-accepted-doc.json'

const fonts = {
  system: SYSTEM_FONTS,
  body: `'Nunito Sans', ${SYSTEM_FONTS}`
}

const palette = {
  primary: {
    main: '#426282',
    light: '#708FB2',
    dark: '#123855',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#ED0875',
    light: '#FF5BA3',
    dark: '#B4004A',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#58CADC',
    dark: '#0599AA',
    light: '#8FFDFF',
    contrastText: '#252A36'
  },
  warning: {
    main: '#F36D27',
    dark: '#EF5A1D',
    light: '#FBB87C',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#6DDF70',
    dark: '#34AC42',
    light: '#A2FFA0',
    contrastText: '#252A36'
  },
  error: {
    main: '#E90022',
    dark: '#A70037',
    light: '#F86963',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#252A36',
    secondary: '#252A36B2',
    disabled: '#252A3661'
  },
  background: {
    paper: '#FFFFFF',
    default: '#F5F6FA'
  },
  action: {
    active: '#252A3699',
    hover: '#252A360A',
    selected: '#252A3614',
    disabled: '#252A3642',
    disabledBackground: '#252A361F',
    focus: '#252A361F'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '105px',
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '66px',
      lineHeight: '120%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '52px',
      lineHeight: '116.7%'
    },
    h4: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '37px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px'
    },
    h5: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '26px',
      lineHeight: '133.4%'
    },
    h6: {
      fontWeight: FONT_WEIGHT.semiBold,
      fontSize: '22px',
      lineHeight: '160%',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '17px',
      lineHeight: '175%',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: FONT_WEIGHT.semiBold,
      fontSize: '15px',
      lineHeight: '157%',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '15px',
      lineHeight: '150%',
      letterSpacing: '0.5px'
    },
    body2: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '13px',
      lineHeight: '143%',
      letterSpacing: '0.25px'
    },
    button: {
      fontWeight: FONT_WEIGHT.semiBold,
      fontSize: '15px',
      lineHeight: '26px',
      letterSpacing: '1.25px',
      '& .MuiButton-sizeSmall': {
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '17px',
        lineHeight: '26px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '13px',
      lineHeight: '166%',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '11px',
      lineHeight: '266%',
      letterSpacing: '1.5px'
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#252A361F'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#252A363B'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: '#252A363B'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#252A366B'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#252A3680'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          color: palette.action.active
        }
      }
    }
  },
  palette,
  images: {
    upload: JSON.stringify(uploadAnimation),
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    loading: Loading,
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: LogoIcon,
    location: Location,
    login: Login,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    individualBeforeWeBegin: EntityBeforeWeBegin,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc,
    acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
    selfieImg: JSON.stringify(SelfieImg),
    poiImg: JSON.stringify(POIImg)
  },
  colorName: {
    button: 'primary',
    linearProgress: 'info',
    checkbox: 'primary',
    stepColor: 'text.primary',
    entityButton: 'primary'
  },
  others: {
    loginBackgroundSize: 'cover',
    customize: {
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
}

export default theme
