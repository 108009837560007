import storage from 'store2'
import Cookies from 'js-cookie'
import {
  API_SERVER_URL,
  BRAND,
  BRANDING_AGREEMENT_LINKS,
  GEO_LOCATION_MANDATORY,
  EDITABLE,
  MAXIMUM_FILE_SIZE,
  MINIMUM_FILE_SIZE,
  SIGNALR_SERVER_URL,
  TOKEN,
  ACCESS_TOKEN,
  USER,
  LOGIN_DATA,
  SIGNAL_TOKEN,
  DROPDOWN_OPTIONS,
  ENTITY_FORM_VALUES,
  ENTITY_PATH,
  REDIRECT_URL,
  DO_NOT_SHOW_ME_NAVIGATE_AGAIN,
  LAST_STEP
} from 'src/constants/storage'
import { DEFAULT_TOKEN_EXPIRES } from 'src/constants/network'
import { BrandingAgreementLinks, TLoginData } from 'src/typings/user'
import { DropDownOption, TDropDownOption } from 'src/typings/types'

function getAccessToken(): string | undefined {
  return storage.session.get(ACCESS_TOKEN)
}

function setAccessToken(accessToken: string) {
  storage.session.set(ACCESS_TOKEN, accessToken)
}

function removeAccessToken() {
  storage.session.remove(ACCESS_TOKEN)
}

function getToken(): string | undefined {
  return Cookies.get(TOKEN)
}

function setToken(token: string) {
  Cookies.set(TOKEN, token, { expires: DEFAULT_TOKEN_EXPIRES })
}

function removeToken() {
  Cookies.remove(TOKEN)
}

function getSignalToken(): string | undefined {
  return storage.session.get(SIGNAL_TOKEN)
}

function setSignalToken(signalToken: string) {
  storage.session.set(SIGNAL_TOKEN, signalToken)
}

function removeSignalToken() {
  storage.session.remove(SIGNAL_TOKEN)
}

function getLoginData(): TLoginData | undefined {
  return storage.session.get(LOGIN_DATA)
}

function setLoginData(loginData: TLoginData) {
  storage.session.set(LOGIN_DATA, loginData)
}

function removeLoginData() {
  storage.session.remove(LOGIN_DATA)
}

function removeUser() {
  storage.session.remove(USER)
}

function getEditable(): string | undefined {
  return storage.session.get(EDITABLE)
}

function setEditable(individualEditable: boolean) {
  storage.session.set(EDITABLE, individualEditable)
}

function removeEditable() {
  storage.session.remove(EDITABLE)
}

function setGeoLocationMandatory(locationMandatory: boolean) {
  storage.session.set(GEO_LOCATION_MANDATORY, locationMandatory)
}

function getGeoLocationMandatory(): boolean {
  return storage.session.get(GEO_LOCATION_MANDATORY)
}

function removeGeoLocationMandatory() {
  storage.session.remove(GEO_LOCATION_MANDATORY)
}

function setMaximumFileSize(fileSize: number) {
  storage.session.set(MAXIMUM_FILE_SIZE, fileSize)
}

function getMaximumFileSize(): number {
  return storage.session.get(MAXIMUM_FILE_SIZE) ?? 52428800
}

function removeMaximumFileSize() {
  storage.session.remove(MAXIMUM_FILE_SIZE)
}

function setEntityFormValues(formValue: Record<string, string>) {
  storage.set(ENTITY_FORM_VALUES, formValue)
}

function getEntityFormValues(): Record<string, string> {
  return storage.get(ENTITY_FORM_VALUES)
}

function removeEntityFormValues() {
  storage.remove(ENTITY_FORM_VALUES)
}

function setRedirectUrl(redirectUrl: string) {
  storage.set(REDIRECT_URL, redirectUrl)
}

function getRedirectUrl(): string {
  return storage.get(REDIRECT_URL) || ''
}

function removeRedirectUrl() {
  storage.remove(REDIRECT_URL)
}

function setMinimumFileSize(fileSize: number) {
  storage.session.set(MINIMUM_FILE_SIZE, fileSize)
}

function getMinimumFileSize(): number {
  return storage.session.get(MINIMUM_FILE_SIZE)
}

function removeMinimumFileSize() {
  storage.session.remove(MINIMUM_FILE_SIZE)
}

function getAPIServerUrl(): string | undefined {
  return storage.session.get(API_SERVER_URL)
}

function setAPIServerUrl(apiServerUrl?: string) {
  storage.session.set(API_SERVER_URL, apiServerUrl)
}

function getSignalrServerUrl(): string | undefined {
  return storage.session.get(SIGNALR_SERVER_URL)
}

function setSignalrServerUrl(apiServerUrl?: string) {
  storage.session.set(SIGNALR_SERVER_URL, apiServerUrl)
}

function getBrand(): string | undefined {
  return storage.get(BRAND)
}

function setBrand(brand: string) {
  storage.set(BRAND, brand)
}

function getBrandingAgreementLinks(): BrandingAgreementLinks {
  return (
    storage.get(BRANDING_AGREEMENT_LINKS) || {
      termsAndConditionsLink: [
        {
          language: 'default',
          url: 'https://knowyourcustomer.com/terms-conditions/'
        }
      ],
      privacyStatementLink: [
        {
          language: 'default',
          url: 'https://knowyourcustomer.com/privacy-policy/'
        }
      ]
    }
  )
}

function setBrandingAgreementLinks(brandingAgreementLinks: BrandingAgreementLinks) {
  storage.set(BRANDING_AGREEMENT_LINKS, brandingAgreementLinks)
}

function getEntityPath() {
  return storage.get(ENTITY_PATH) || '/entity'
}

function setEntityPath(value: string) {
  return storage.set(ENTITY_PATH, value)
}

function removeEntityPath() {
  return storage.remove(ENTITY_PATH)
}

function getDoNotShowNavigateAgain() {
  return storage.get(DO_NOT_SHOW_ME_NAVIGATE_AGAIN)
}

function setDoNotShowNavigateAgain() {
  return storage.set(DO_NOT_SHOW_ME_NAVIGATE_AGAIN, true)
}

function removeDoNotShowNavigateAgain() {
  return storage.remove(DO_NOT_SHOW_ME_NAVIGATE_AGAIN)
}

function getLocalStorage(key: string) {
  return storage.get(key)
}

function setLocalStorage(key: string, value: unknown) {
  return storage.set(key, value)
}

function getDropDownOptions(): TDropDownOption {
  return storage.get(DROPDOWN_OPTIONS) || {}
}

function getDropDownOptionsByKey(key: string): DropDownOption[] {
  const dropdownOptions = storage.get(DROPDOWN_OPTIONS) || {}
  if (key) {
    return dropdownOptions[key] || []
  }

  return []
}

function setDropDownOptions(value: TDropDownOption) {
  return storage.set(DROPDOWN_OPTIONS, value)
}

function removeDropDownOptions() {
  return storage.remove(DROPDOWN_OPTIONS)
}

function getLastStep(accessToken?: string | null) {
  const res = storage.get(LAST_STEP) ?? ''
  const [at, lastStep] = res.split('____')
  if (at === accessToken) {
    return lastStep
  } else {
    removeLastStep()
    return undefined
  }
}

function setLastStep(value: string, accessToken?: string | null) {
  if (!accessToken) {
    return undefined
  }
  return storage.set(LAST_STEP, `${accessToken}____${value}`)
}

function removeLastStep() {
  return storage.remove(LAST_STEP)
}

const storageHelper = {
  getToken,
  setToken,
  removeToken,
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getSignalToken,
  setSignalToken,
  removeSignalToken,
  getLoginData,
  setLoginData,
  removeLoginData,
  removeUser,
  getEditable,
  setEditable,
  removeEditable,
  setGeoLocationMandatory,
  getGeoLocationMandatory,
  removeGeoLocationMandatory,
  setMaximumFileSize,
  getMaximumFileSize,
  removeMaximumFileSize,
  setMinimumFileSize,
  getMinimumFileSize,
  removeMinimumFileSize,
  getAPIServerUrl,
  setAPIServerUrl,
  getSignalrServerUrl,
  setSignalrServerUrl,
  getBrand,
  setBrand,
  getBrandingAgreementLinks,
  setBrandingAgreementLinks,
  getDropDownOptions,
  setDropDownOptions,
  getDropDownOptionsByKey,
  setEntityFormValues,
  getEntityFormValues,
  removeEntityFormValues,
  setRedirectUrl,
  getRedirectUrl,
  removeRedirectUrl,
  getEntityPath,
  setEntityPath,
  removeEntityPath,
  getLocalStorage,
  setLocalStorage,
  removeDropDownOptions,
  getDoNotShowNavigateAgain,
  setDoNotShowNavigateAgain,
  removeDoNotShowNavigateAgain,
  getLastStep,
  setLastStep,
  removeLastStep
}

export default storageHelper
