import { createAsyncThunk } from '@reduxjs/toolkit'
import UserAPI from 'src/api/user'
import { TUploadFile } from 'src/typings/types'
import { TLoginData } from 'src/typings/user'
import storage from 'src/utils/storage'

export const login = createAsyncThunk('user/login', async (token: string, thunkAPI): Promise<TLoginData> => {
  const res: TLoginData = await UserAPI.login(token)

  if (res.token !== '') {
    storage.setAccessToken(token)
    storage.setToken(res.token)
    const detail = res.companyDetail || res.individualDetail
    storage.setEditable(!!detail?.allowEdit)
    storage.setGeoLocationMandatory(!!detail?.geoLocationFlow?.mandatory)
    storage.setMaximumFileSize(detail?.maximumFileSize || 52428800)
    storage.setMinimumFileSize(detail?.minimumFileSize || 0)
    await thunkAPI.dispatch(loginSignal())
  }
  return res
})

export const loginSignal = createAsyncThunk('user/loginSignal', async () => {
  return await UserAPI.loginSignal()
})

export const getUploadFileUrl = createAsyncThunk(
  'user/getUploadFileUrl',
  async ({ signal, extension }: { signal?: AbortSignal; extension: string }): Promise<TUploadFile> => {
    return await UserAPI.getUploadFileUrl(signal, extension)
  }
)

export const hasAgreedWithTerms = createAsyncThunk('user/hasAgreedWithTerms', async () => {
  return await UserAPI.hasAgreedWithTerms()
})

export const agreeWithTerms = createAsyncThunk('user/agreeWithTerms', async () => {
  return await UserAPI.agreeWithTerms()
})
