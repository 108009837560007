import { useCallback, useMemo } from 'react'
import useDispatch from 'src/hooks/common/useDispatch'
import * as userActions from 'src/state/user/actions'
import { actions as userSliceActions } from 'src/state/user/slice'
import singPassActions from 'src/state/singpass/slice'
import { StatusReturn, TUploadFile } from 'src/typings/types'
import useSelector from 'src/hooks/common/useSelector'
import { TLoginData } from 'src/typings/user'

export default function useUser() {
  const dispatch = useDispatch()
  const { signalToken, agreed, loginData } = useSelector((state) => state.user)
  const login = useCallback(
    async (accessToken: string) => {
      const { payload } = await dispatch(userActions.login(accessToken))
      return payload as TLoginData
    },
    [dispatch]
  )
  const logout = useCallback(() => {
    dispatch(userSliceActions.logout())
    dispatch(singPassActions.reset())
  }, [dispatch])
  const getUploadFileUrl = useCallback(
    async (signal?: AbortSignal, extension = 'jpg'): Promise<TUploadFile> => {
      const { payload } = await dispatch(userActions.getUploadFileUrl({ signal, extension }))
      return payload as TUploadFile
    },
    [dispatch]
  )
  const hasAgreedWithTerms = useCallback(async (): Promise<boolean> => {
    const { payload } = await dispatch(userActions.hasAgreedWithTerms())
    return payload as boolean
  }, [dispatch])
  const agreeWithTerms = useCallback(async (): Promise<StatusReturn> => {
    const { payload } = await dispatch(userActions.agreeWithTerms())
    return payload as StatusReturn
  }, [dispatch])

  const isGeoLocationEnabled = useMemo(() => {
    return !!loginData?.individualDetail?.geoLocationFlow?.enabled
  }, [loginData])

  return {
    agreed,
    signalToken,
    isAuth: !!loginData,
    login,
    logout,
    getUploadFileUrl,
    hasAgreedWithTerms,
    agreeWithTerms,
    isGeoLocationEnabled
  }
}
