import { cloneDeep, compact, kebabCase, last } from 'lodash'
import dayjs from 'dayjs'
import {
  EButtonType,
  ECardType,
  EEntityGroupType,
  EMemberType,
  HideButtonsInEntityValidation,
  IDocument,
  IGroupResult,
  ILookupCase,
  IMemberProperty,
  IMemberResult,
  IMemberTab,
  IValidationResponse,
  TButton,
  TCard,
  TEntityDocument,
  TEntityStep,
  THashEntityStepsFullNames,
  TMemberType,
  TScaffold
} from 'src/typings/entity'
import {
  buttonGroup,
  companyIdentity,
  identityWelcome,
  officersWelcome,
  personWithSignificantControlWelcome,
  shareholdersWelcome,
  termsSteps,
  uploadingSteps,
  welcomeSteps
} from 'src/constants/entitySteps/common'
import { DropDownOption, EFieldType, TabProperty } from 'src/typings/types'
import {
  EMEMBER_TYPE,
  ENTITY_DIRECTIVE_NAME,
  ENTITY_UPLOAD_FIELD_NAMES,
  EStepName,
  EStepType,
  GROUP_NUMBER_MAPPING,
  IdentityConst,
  MEMBER_TYPE,
  ROLES
} from 'src/constants/entity'
import { IContinent, ICountry } from 'src/typings/country'
import storage from 'src/utils/storage'
import { TEntityCaseStatus, TStepStatus } from 'src/services/CreateEntityCaseService'
import { convertToMDY } from 'src/utils/stringHelper'
import { CHECKBOX_FALSE, CHECKBOX_TRUE } from 'src/constants/stepNames'
import { getIsMyInfoEntity } from 'src/state/rtkQueries/uploadPortalApiSlice'
import { BRANDS } from 'src/constants/app'

// KYC.WebApp/Templates/CaseDetail/Address.html
const addressNames = [
  ENTITY_UPLOAD_FIELD_NAMES.AddressLine1,
  ENTITY_UPLOAD_FIELD_NAMES.AddressLine2,
  ENTITY_UPLOAD_FIELD_NAMES.Country,
  ENTITY_UPLOAD_FIELD_NAMES.City,
  ENTITY_UPLOAD_FIELD_NAMES.StateProvince,
  ENTITY_UPLOAD_FIELD_NAMES.PostCode
]
const maxWidthNames = [ENTITY_UPLOAD_FIELD_NAMES.RegistrationNumber, ENTITY_UPLOAD_FIELD_NAMES.PostCode]
const myInfoAvailableFields = [
  ENTITY_UPLOAD_FIELD_NAMES.Email,
  ENTITY_UPLOAD_FIELD_NAMES.Phone,
  ENTITY_UPLOAD_FIELD_NAMES.Phone_Number,
  ENTITY_UPLOAD_FIELD_NAMES.Uinfin,
  ENTITY_UPLOAD_FIELD_NAMES.NRIC_FIN,
  ENTITY_UPLOAD_FIELD_NAMES.Alias,
  ENTITY_UPLOAD_FIELD_NAMES.Residential_Status
]
// KYC.WebApp/Templates/CaseDetail/IndividualProperties.html
const availableIndividualIdentityFields = [
  ENTITY_UPLOAD_FIELD_NAMES.Role,
  ENTITY_UPLOAD_FIELD_NAMES.OtherRole,
  ENTITY_UPLOAD_FIELD_NAMES.SearchExistingProfiles,
  ENTITY_UPLOAD_FIELD_NAMES.NotifiedOn,
  ENTITY_UPLOAD_FIELD_NAMES.NatureOfControl,
  ENTITY_UPLOAD_FIELD_NAMES.ShareCount,
  ENTITY_UPLOAD_FIELD_NAMES.GroupName,
  ENTITY_UPLOAD_FIELD_NAMES.FirstName,
  ENTITY_UPLOAD_FIELD_NAMES.MiddleName,
  ENTITY_UPLOAD_FIELD_NAMES.Middle_Name,
  ENTITY_UPLOAD_FIELD_NAMES.LastName,
  ENTITY_UPLOAD_FIELD_NAMES.Nationality,
  ENTITY_UPLOAD_FIELD_NAMES.BirthDate,
  ENTITY_UPLOAD_FIELD_NAMES.Birth_Date,
  ENTITY_UPLOAD_FIELD_NAMES.PassportExpirationDate,
  ENTITY_UPLOAD_FIELD_NAMES.Journey
]
// KYC.WebApp/Templates/CaseDetail/CompanyProperties.html
const availableCompanyIdentityFields = [
  ENTITY_UPLOAD_FIELD_NAMES.Role,
  ENTITY_UPLOAD_FIELD_NAMES.OtherRole,
  ENTITY_UPLOAD_FIELD_NAMES.SearchExistingProfiles,
  ENTITY_UPLOAD_FIELD_NAMES.NotifiedOn,
  ENTITY_UPLOAD_FIELD_NAMES.NatureOfControl,
  ENTITY_UPLOAD_FIELD_NAMES.ShareCount,
  ENTITY_UPLOAD_FIELD_NAMES.GroupName,
  ENTITY_UPLOAD_FIELD_NAMES.UnregisteredEntity,
  ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction,
  ENTITY_UPLOAD_FIELD_NAMES.CompanyName,
  ENTITY_UPLOAD_FIELD_NAMES.Journey,
  ENTITY_UPLOAD_FIELD_NAMES.DataSource,
  ENTITY_UPLOAD_FIELD_NAMES.CompanyType,
  ENTITY_UPLOAD_FIELD_NAMES.CompanyStatus,
  ENTITY_UPLOAD_FIELD_NAMES.RegistrationNumber
]

const getAvailableIdentityFields = (availableFields: string[]) => {
  const myAvailableFields = getIsMyInfoEntity() ? [...myInfoAvailableFields] : []
  return [...availableFields, ...myAvailableFields]
}
const allAddGroups = [
  EEntityGroupType.Officers,
  EEntityGroupType.Shareholders,
  EEntityGroupType.PersonWithSignificantControl
]

export function getDropDownOptionKey(fieldName?: string, id?: number | string): string {
  if (fieldName && typeof id !== 'undefined') {
    return `${fieldName?.replace(/ /g, '_')}_${id}`
  } else {
    return fieldName?.replace(/ /g, '_') || ''
  }
}

export function getGroupWelcome(
  groupType: EEntityGroupType,
  removeBackButton = false,
  isMyInfoEntity = false
): TEntityStep | undefined {
  let welcomeStep: TEntityStep | undefined = undefined
  switch (groupType) {
    case EEntityGroupType.Identity:
      welcomeStep = isMyInfoEntity ? companyIdentity : identityWelcome
      break
    case EEntityGroupType.Officers:
      welcomeStep = officersWelcome
      break
    case EEntityGroupType.Shareholders:
      welcomeStep = shareholdersWelcome
      break
    case EEntityGroupType.PersonWithSignificantControl:
      welcomeStep = personWithSignificantControlWelcome
      break
  }
  if (welcomeStep && removeBackButton) {
    welcomeStep.cards?.every((card) => {
      if (card.buttons && card.buttons[0]?.buttonType === EButtonType.previous) {
        card.buttons.splice(0, 1)
      }
      return true
    })
  }
  return welcomeStep
}

export function generateRootSteps(scaffolds: TScaffold[], isDocumentValidation = false): TEntityStep[] {
  const rootSteps: TEntityStep[] = []

  const totalSteps = scaffolds.length
  rootSteps.push(
    ...scaffolds.reduce((acc, scaffold, idx) => {
      const welcomeStep = getGroupWelcome(scaffold.sectionType, !idx && isDocumentValidation)
      acc.push({
        name: `g-${scaffold.caseGroup}`,
        allowAdd: allAddGroups.includes(scaffold.sectionType),
        cards: [],
        groupId: scaffold.caseGroup,
        groupType: scaffold.sectionType,
        hasDocumentRequested: scaffold.hasDocumentRequested,
        stepType: EStepType.Root,
        stepProgress: {
          step: idx + 1,
          total: totalSteps,
          isFinished: false,
          subtitle: EStepName[scaffold.sectionType as keyof typeof EStepName]
        },
        subSteps: welcomeStep ? [welcomeStep] : []
      } as TEntityStep)
      return acc
    }, [] as TEntityStep[])
  )

  if (!isDocumentValidation && rootSteps[0]?.groupType !== EEntityGroupType.Identity) {
    rootSteps.unshift(welcomeSteps)
  }

  if (storage.getBrand() !== BRANDS.bplkyc) {
    rootSteps.push(termsSteps)
  } else {
    // KWA-21998: BPL KYC does not need terms steps
    const lastStep = rootSteps.slice(-1)?.[0]
    if (lastStep) {
      lastStep.isLastStep = true
    }
  }
  return rootSteps
}
export function generateMyInfoRootSteps(scaffolds: TScaffold[], isDocumentValidation = false): TEntityStep[] {
  const rootSteps: TEntityStep[] = []

  const totalSteps = scaffolds.length + 1
  rootSteps.push(
    ...scaffolds.reduce((acc, scaffold, idx) => {
      const welcomeStep = getGroupWelcome(scaffold.sectionType, !idx && isDocumentValidation, true)
      acc.push({
        name: `g-${scaffold.caseGroup}`,
        allowAdd: allAddGroups.includes(scaffold.sectionType),
        cards: [],
        groupId: scaffold.caseGroup,
        groupType: scaffold.sectionType,
        hasDocumentRequested: scaffold.hasDocumentRequested,
        stepType: EStepType.Root,
        stepProgress: {
          step: idx + 2,
          total: totalSteps,
          isFinished: false,
          subtitle: EStepName[scaffold.sectionType as keyof typeof EStepName]
        },
        subSteps: welcomeStep ? [welcomeStep] : []
      } as TEntityStep)
      return acc
    }, [] as TEntityStep[])
  )

  rootSteps.push(uploadingSteps)
  return rootSteps
}

export function calculateFullNameOfEntitySteps(entitySteps: TEntityStep[], parent = ''): TEntityStep[] {
  return entitySteps
    .map((step) => {
      const fullName = parent ? `${parent}.${step.name}` : step.name
      if (step.subSteps) {
        step.subSteps = calculateFullNameOfEntitySteps(step.subSteps, fullName)
      }
      step.fullName = fullName
      return step
    })
    .filter((step) => step.fullName)
}

export function getEmptyDataSourceProperty(property: IMemberProperty) {
  return {
    ...property,
    id: 0,
    fieldName: ENTITY_UPLOAD_FIELD_NAMES.DataSource,
    fieldType: EFieldType.List,
    isMandatory: true,
    isEditable: true,
    fieldValue: '',
    _fieldValue: '',
    dropDownOptions: [] as DropDownOption[],
    label: 'Choose data source'
  } as unknown as IMemberProperty
}

export function getFieldProperties(property: IMemberProperty) {
  const properties: TabProperty = {
    id: (property.id || property.linkPropertyId) ?? 0,
    fieldName: property.fieldName,
    fieldType: property.fieldType,
    isMandatory: property.isMandatory,
    isEditable: property.isEditable,
    isIdentity: property.isIdentity ?? undefined,
    fieldValue: property.fieldValue,
    _fieldValue: property.fieldValue,
    fieldValidator: property.fieldValidator,
    originalFieldValue: property.originalFieldValue,
    dropDownOptions: [],
    label: property.label,
    fieldLabel: property.fieldLabel ?? undefined,
    icon: property.icon,
    groupId: property.groupId,
    tag: property.tag,
    isLinkProperty: !!property.linkPropertyId
  }

  if (property.dropDownOptions && property.dropDownOptions.length > 0) {
    storage.setDropDownOptions({
      ...storage.getDropDownOptions(),
      [getDropDownOptionKey(property.fieldName, property.id || property.linkPropertyId)]: (
        property.dropDownOptions as DropDownOption[]
      ).map((item) => {
        return {
          option: item.option,
          text: item.text,
          originalData: item.originalData
        } as DropDownOption
      })
    })
  }

  if (maxWidthNames.includes(property.fieldName) || property.fieldType === EFieldType.DateTime) {
    properties.maxWidth = '240px'
    properties.xsMaxWidth = '100%'
  }
  return properties
}

export function getAddresses(tab?: IMemberTab) {
  const addresses: TabProperty[] = []
  addressNames.forEach((name) => {
    const property = tab?.properties.find((property: IMemberProperty) => property.fieldName === name)
    if (property) {
      addresses.push(getFieldProperties(property))
    }
  })

  return addresses
}

export function getBasicInfo(tab?: IMemberTab, directiveName?: string) {
  const companyInfo: TabProperty[] = []
  const isCompany = directiveName === ENTITY_DIRECTIVE_NAME.memberCompany
  const isIndividual = directiveName === ENTITY_DIRECTIVE_NAME.memberIndividual
  tab?.properties.forEach((property: IMemberProperty) => {
    if (!addressNames.includes(property.fieldName)) {
      if (isCompany && tab?.isIdentity) {
        if (
          getAvailableIdentityFields(availableCompanyIdentityFields).includes(property.fieldName) ||
          property.linkPropertyId ||
          !property.isIdentity
        ) {
          companyInfo.push(getFieldProperties(property))
        }
      } else if (isIndividual && tab?.isIdentity) {
        if (
          getAvailableIdentityFields(availableIndividualIdentityFields).includes(property.fieldName) ||
          property.linkPropertyId ||
          !property.isIdentity
        ) {
          companyInfo.push(getFieldProperties(property))
        }
      } else {
        companyInfo.push(getFieldProperties(property))
      }
    }
  })
  return companyInfo
}

export function getMemberInfo({
  tab,
  infoTitle = 'details',
  addressTitle = 'address',
  isNewStep = false,
  memberType,
  directiveName,
  isApplicantProfile = false
}: {
  tab?: IMemberTab
  infoTitle?: string
  addressTitle?: string
  isNewStep?: boolean
  memberType?: string
  directiveName?: string
  isApplicantProfile?: boolean
}): TCard[] {
  if (kebabCase(tab?.name) === 'identity') {
    const addressProperties = getAddresses(tab)
    const tabs: TCard[] = [
      {
        title: infoTitle,
        memberTypeVisible: isNewStep,
        memberType,
        properties: getBasicInfo(tab, directiveName)
      }
    ]
    if (addressProperties?.length) {
      tabs.push({
        title: addressTitle ?? '',
        properties: addressProperties
      } as unknown as TCard)
    }
    if (isApplicantProfile) {
      return [
        {
          title: kebabCase(tab?.name),
          cardType: ECardType.identity,
          tabs
        }
      ]
    }
    return [
      {
        title: kebabCase(tab?.name),
        cardType: ECardType.identity,
        tabs
      },
      {
        buttonDirection: 'row',
        cardType: ECardType.button,
        buttons: [buttonGroup.previous, buttonGroup.saveFormAndNext]
      }
    ]
  } else {
    if (isApplicantProfile) {
      return [
        {
          title: kebabCase(tab?.name),
          cardType: ECardType.other,
          tabs: [
            {
              title: tab?.name,
              memberTypeVisible: isNewStep && tab?.name === 'details',
              memberType,
              properties: getBasicInfo(tab, directiveName)
            }
          ]
        }
      ]
    }
    return [
      {
        title: kebabCase(tab?.name),
        cardType: ECardType.other,
        tabs: [
          {
            title: tab?.name,
            memberTypeVisible: isNewStep && tab?.name === 'details',
            memberType,
            properties: getBasicInfo(tab, directiveName)
          }
        ]
      },
      {
        buttonDirection: 'row',
        cardType: ECardType.button,
        buttons: [buttonGroup.previous, buttonGroup.saveFormAndNext]
      }
    ]
  }
}

const getDocuments = (documents?: IDocument[], caseStepId?: number, documentStepId?: number) => {
  return compact(
    documents?.map((document: IDocument) => {
      // KYC.WebApp/Templates/CaseRequest/DocumentsContent.html
      if (!document.isCaseRequest) {
        return undefined
      }
      return {
        caseStepId: caseStepId,
        caseDocumentId: document.caseDocumentId,
        documentStepId: documentStepId || caseStepId,
        id: document.caseDocumentId,
        documentType: document.documentType || document.fileCat,
        fileCat: document.fileCat,
        description: document.description,
        name: document.fileName,
        uploadValidationStatus: document.uploadValidationStatus,
        helperText: 'documentRequired',
        isMandatory: document.isDocumentWasRequested,
        isDocumentWasRequested: document.isDocumentWasRequested,
        isLoading: false,
        tooltip: 'documentTooltip',
        url: document.url
      }
    })
  ) as TEntityDocument[]
}

export function generateStepCards({
  directiveName,
  tab,
  documents,
  isNewStep = false,
  memberType,
  caseStepId,
  documentStepId,
  isApplicantProfile = false
}: {
  directiveName?: string
  tab?: IMemberTab
  documents?: IDocument[]
  isNewStep?: boolean
  memberType?: string
  caseStepId?: number
  documentStepId?: number
  isApplicantProfile?: boolean
}): TCard[] {
  switch (directiveName) {
    case ENTITY_DIRECTIVE_NAME.applicantProfile:
      return getMemberInfo({
        tab,
        infoTitle: 'applicantProfile',
        addressTitle: 'address',
        isNewStep,
        directiveName,
        isApplicantProfile
      })
    case ENTITY_DIRECTIVE_NAME.identityCompany:
      return getMemberInfo({
        tab,
        infoTitle: 'companyInformation',
        addressTitle: 'companyAddress',
        isNewStep,
        directiveName
      })
    case ENTITY_DIRECTIVE_NAME.memberCompany:
    case ENTITY_DIRECTIVE_NAME.memberIndividual:
    case ENTITY_DIRECTIVE_NAME.shareholderGroup:
      if (tab) {
        return getMemberInfo({ tab, isNewStep, memberType, directiveName, isApplicantProfile })
      } else {
        return [
          {
            title: 'kycDocuments',
            cardType: ECardType.document,
            documents: getDocuments(documents, caseStepId, documentStepId)
          },
          {
            buttonDirection: 'row',
            cardType: ECardType.button,
            buttons: [buttonGroup.previous, buttonGroup.next]
          }
        ]
      }
    case ENTITY_DIRECTIVE_NAME.kycDocuments:
      return [
        {
          title: 'companyDocuments',
          cardType: ECardType.document,
          documents: getDocuments(documents, caseStepId, documentStepId)
        },
        {
          buttonDirection: 'row',
          cardType: ECardType.button,
          buttons: [buttonGroup.previous, buttonGroup.next]
        }
      ]
  }

  return []
}

/**
 * Generates a hash of fullNames of entity steps
 * @param {entitySteps} entitySteps
 * @example:
 * [
 *   {
 *     name: 'identity',
 *     fullName: 'identity',
 *     ...rest,
 *     subSteps: [
 *       {
 *         name: 'welcome',
 *         fullName: 'identity.welcome',
 *         ...rest
 *       },
 *       {
 *         name: 'documents',
 *         fullName: 'identity.documents',
 *         ...rest
 *       }
 *     ]
 *   },
 *   {
 *     name: 'control',
 *     fullName: 'control',
 *     ...rest,
 *     subSteps: [
 *       {
 *         name: 'bodies',
 *         fullName: 'control.bodies',
 *         ...rest
 *       },
 *       {
 *         name: 'members-${pageIndex}',
 *         fullName: 'control.members',
 *         subSteps: [
 *           {
 *             name: 'details',
 *             fullName: 'control.members.details',
 *             ...rest
 *           }
 *         ]
 *       }
 *     ]
 *   },
 *   {
 *     name: 'ownership',
 *     fullName: 'ownership',
 *     ...rest,
 *     subSteps: [
 *       {
 *         name: 'welcome',
 *         fullName: 'ownership.welcome',
 *         ...rest
 *       }
 *     ]
 *   }
 * ]
 *
 * to
 *
 * {
 *    'identity.welcome': {
 *      previous: undefined,
 *      child: undefined,
 *      next: 'identity.details',
 *    },
 *    'identity.details': {
 *      previous: 'identity.welcome',
 *      child: undefined,
 *      next: 'identity.documents',
 *    },
 *    'identity.documents': {
 *      previous: 'identity.details',
 *      child: undefined,
 *      next: 'control.bodies',
 *    },
 *    'control.bodies': {
 *      previous: 'identity.documents',
 *      child: undefined,
 *      next: 'control.members',
 *    },
 *    'control.members': {
 *      previous: 'control.bodies',
 *      child: 'control.members.details',
 *      next: 'ownership.welcome',
 *    },
 *    'control.members.details': {
 *      previous: 'control.members',
 *      child: undefined,
 *      next: undefined,
 *    },
 *    'ownership.welcome': {
 *      previous: 'control.members',
 *      child: undefined,
 *      next: undefined,
 *    }
 * }
 */
export function hashEntityStepsFullNames(entitySteps: TEntityStep[]): THashEntityStepsFullNames {
  const getFullNames = (entitySteps: TEntityStep[]) => {
    return entitySteps.reduce((acc, step) => {
      step.fullName && acc.push(step.fullName)
      if (step.subSteps) {
        acc.push(...getFullNames(step.subSteps))
      }
      return acc
    }, [] as string[])
  }
  const fullNames = getFullNames(entitySteps).filter((name) => name.indexOf('.') > -1)

  const getPrevious = (index: number, len: number): string | undefined => {
    const idx = index - 1
    if (idx <= 0) {
      return undefined
    }
    const previous = fullNames[idx]
    if (previous?.split('.').length > len) {
      return getPrevious(idx, len)
    }
    return previous
  }

  return fullNames.reduce((acc, fullName, index) => {
    const fullNameLen = fullName?.split('.').length
    const previous = getPrevious(index, fullName?.split('.').length) || fullNames[index - 1]
    let child = undefined
    let next: string | undefined = fullNames[index + 1]
    let nextLen = next?.split('.').length
    let idx = index + 1
    while (next && next.indexOf(fullName) === 0) {
      if (!child) {
        child = next
      }
      idx++
      next = fullNames[idx]
      nextLen = next?.split('.').length
    }
    if (fullNameLen > nextLen && fullName.indexOf(next) !== 0) {
      next = undefined
    }
    acc[fullName] = {
      previous,
      child,
      next
    }
    return acc
  }, {} as THashEntityStepsFullNames)
}

export function findPreviousStepName(
  currentStepName: string,
  hashEntityStepsFullNames?: THashEntityStepsFullNames,
  isVeryPrevious?: boolean
): string | undefined {
  let prev = hashEntityStepsFullNames?.[currentStepName]?.previous
  if (prev && prev.indexOf('identity') > -1) {
    return prev
  }
  if (isVeryPrevious) {
    // g-2.members-3 -> g-2.shareholders
    // skip the g-2.members-2, and g-2.members-1
    if (currentStepName && currentStepName.indexOf('members') > -1) {
      while (prev && prev.indexOf('members') > -1) {
        prev = hashEntityStepsFullNames?.[prev]?.previous
      }
      return prev
    } else {
      // From g-11.personWithSignificantControl -> g-2.members-1
      // skip the g-2.members-2 (if any)
      prev = prev ? hashEntityStepsFullNames?.[prev]?.previous : prev
      while (prev && prev.indexOf('members') > -1) {
        prev = hashEntityStepsFullNames?.[prev]?.previous
      }
      return prev ? hashEntityStepsFullNames?.[prev]?.next : prev
    }
  }
  return prev
}

export function findNextStepName(
  currentStepName: string,
  hashEntityStepsFullNames?: THashEntityStepsFullNames,
  isVeryNext?: boolean
): string | undefined {
  let next = hashEntityStepsFullNames?.[currentStepName]?.next
  if (isVeryNext && currentStepName && currentStepName.indexOf('members') > -1) {
    while (next && next.indexOf('members') > -1) {
      next = hashEntityStepsFullNames?.[next]?.next
    }
    return next
  }
  return next
}

export function findChildStepName(
  currentStepName: string,
  hashEntityStepsFullNames?: THashEntityStepsFullNames
): string | undefined {
  return hashEntityStepsFullNames?.[currentStepName]?.child
}

export function getMemberTypes(step?: TEntityStep): { key: TMemberType; label: string }[] {
  const memberValues = Object.values(MEMBER_TYPE) || []
  if (step?.groupId !== 1 || step?.isShareholderMember) {
    return memberValues.filter((member) => member.key !== EMemberType.jointShareholder)
  }
  return memberValues
}

export function checkIsDocumentCardVisible(step?: TEntityStep): boolean {
  return step?.memberType !== EMemberType.jointShareholder
}

export function getCurrentStepButtons(
  buttons: TButton[],
  step?: TEntityStep,
  isDocumentValidation = false,
  isMyInfoEntity = false,
  nextStepName = ''
): TButton[] {
  if (isMyInfoEntity && nextStepName === 'uploading.upload') {
    return [buttons[0], buttonGroup.completeSubmission]
  }
  if (isMyInfoEntity) {
    return buttons
      .filter((button) => button.text !== EButtonType.saveAndAddAnother)
      .map((button) => {
        if (button.text === EButtonType.saveAndReturn) {
          return {
            ...button,
            variant: 'contained'
          }
        }
        return button
      })
  }
  if (step?.memberType === EMemberType.jointShareholder) {
    if (step?.isNewStep) {
      buttons = [buttonGroup.saveAndAddMember, buttonGroup.cancel]
    } else {
      buttons = [buttonGroup.saveJointShareholderDetail, buttonGroup.addShareholderMember, buttonGroup.back]
    }
  }

  if (!isDocumentValidation) {
    const lastButton = buttons[buttons.length - 1]
    const restButtons = buttons.slice(0, -1)
    if (step?.isLastStep && lastButton.text === EButtonType.next) {
      return [...restButtons, buttonGroup.agreeAndUpload]
    }
    return buttons
  } else {
    const newButtons: TButton[] = buttons
      .filter((button) => {
        return !(button.buttonType && HideButtonsInEntityValidation.includes(button.buttonType))
      })
      .map((button) => {
        if (button.buttonType && [EButtonType.cancel, EButtonType.previous].includes(button.buttonType)) {
          return button
        }
        return { ...button, variant: 'contained' }
      })

    const lastButton = newButtons[newButtons.length - 1]
    const restButtons = newButtons.slice(0, -1)
    if (step?.isLastStep && lastButton.text === EButtonType.next) {
      return [...restButtons, buttonGroup.agreeAndUpload] as TButton[]
    }

    return newButtons
  }
}

export function checkIsJointShareholderMember(stepName?: string): boolean {
  return stepName?.toUpperCase() === 'JOINT SHAREHOLDER MEMBER'
}

export function checkIsShareholderGroup(groupName?: string): boolean {
  return groupName?.toUpperCase() === 'SHAREHOLDERS'
}

export function checkIsPersonWithSignificantControlGroup(groupName?: string): boolean {
  return groupName?.toUpperCase() === 'PERSONWITHSIGNIFICANTCONTROL'
}

export function assignShareholderGroupValues(result: IGroupResult, tab: IMemberTab): IMemberTab {
  tab.properties.forEach((property) => {
    switch (property.fieldName) {
      case ENTITY_UPLOAD_FIELD_NAMES.ShareCount:
        property.fieldValue = !result.shares ? '-' : result.shares?.toString()
        property._fieldValue = !result.shares ? '-' : result.shares?.toString()
        break
      case ENTITY_UPLOAD_FIELD_NAMES.GroupName:
        property.fieldValue = result.name || ''
        property._fieldValue = result.name || ''
        break
    }
  })

  return tab
}

export function appendExtraProperties({
  tab,
  groupId,
  stepName,
  groupName,
  isShareholderMember,
  isShareholderGroupItem,
  memberType,
  isNew,
  isCompany,
  isIndividual,
  defaultJourney,
  isIdentity = false
}: {
  tab: IMemberTab
  groupId?: number
  stepName?: string
  groupName?: string
  isShareholderMember?: boolean
  isShareholderGroupItem?: boolean
  memberType?: string
  isNew?: boolean
  isCompany?: boolean
  isIndividual?: boolean
  defaultJourney?: number | string
  isIdentity?: boolean
}) {
  const roles = groupId ? ROLES[groupId] : []
  isShareholderMember = isShareholderMember || checkIsJointShareholderMember(stepName)
  groupName = groupName || (groupId ? GROUP_NUMBER_MAPPING[groupId] : '')
  const isShareholderGroup = checkIsShareholderGroup(groupName)
  const isPersonWithSignificantControlGroup = checkIsPersonWithSignificantControlGroup(groupName)

  const defaultProperties = {
    isCompanyCases: isCompany,
    isIndividualCases: isIndividual
  }

  if (isNew) {
    // Append "Search existing profiles" to the individual and entity section
    tab.properties.splice(0, 0, {
      id: 0,
      fieldName: ENTITY_UPLOAD_FIELD_NAMES.SearchExistingProfiles,
      fieldType: EFieldType.SearchAutoComplete,
      isMandatory: false,
      isEditable: true,
      fieldValue: '',
      _fieldValue: '',
      label: 'Search existing profiles',
      icon: 'Search',
      ...defaultProperties
    } as IMemberProperty)
  }
  if (!isShareholderMember) {
    if (isPersonWithSignificantControlGroup) {
      // Append "Nature of Control" and "Notified on" to the PSC section
      tab.properties.splice(0, 0, {
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.NotifiedOn,
        fieldType: EFieldType.DateTime,
        isMandatory: false,
        isEditable: true,
        fieldValue: '',
        _fieldValue: '',
        label: 'Notified on',
        ...defaultProperties
      } as IMemberProperty)
      tab.properties.splice(0, 0, {
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.NatureOfControl,
        fieldType: EFieldType.Text,
        isMandatory: true,
        isEditable: true,
        fieldValue: '',
        _fieldValue: '',
        label: 'Nature of Control',
        ...defaultProperties
      } as IMemberProperty)
    }
    if (!isShareholderGroupItem && isShareholderGroup) {
      // Append share count to the Shareholders section
      tab.properties.splice(0, 0, {
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.ShareCount,
        fieldType: EFieldType.ShareCount,
        isMandatory: true,
        isEditable: true,
        fieldValue: '',
        _fieldValue: '',
        label: 'Share count',
        ...defaultProperties
      } as IMemberProperty)
    }
    if (!isShareholderGroupItem && roles?.length > 0) {
      storage.setDropDownOptions({
        ...storage.getDropDownOptions(),
        [getDropDownOptionKey(ENTITY_UPLOAD_FIELD_NAMES.Role, groupId)]: roles.map((role, idx) => ({
          id: idx - 100000,
          option: role,
          text: role
        })) as DropDownOption[]
      })
      // Append other role to the Officers and Shareholders section
      tab.properties.splice(0, 0, {
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.OtherRole,
        fieldType: EFieldType.Text,
        isMandatory: true,
        isEditable: true,
        fieldValue: '',
        _fieldValue: '',
        label: 'OtherRole',
        groupId,
        ...defaultProperties
      } as unknown as IMemberProperty)
      // Append role to the Officers and Shareholders section
      tab.properties.splice(0, 0, {
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.Role,
        fieldType: EFieldType.Role,
        isMandatory: true,
        isEditable: true,
        fieldValue: '',
        _fieldValue: '',
        dropDownOptions: [],
        label: 'Role',
        groupId,
        ...defaultProperties
      } as unknown as IMemberProperty)
    }
  }

  if (!isIdentity && memberType === EMemberType.entity) {
    // Append "Nature of Control" and "Notified on" to the PSC section
    tab.properties.splice(0, 0, {
      id: 0,
      fieldName: ENTITY_UPLOAD_FIELD_NAMES.UnregisteredEntity,
      fieldType: EFieldType.Switch,
      isMandatory: false,
      isEditable: true,
      fieldValue: '',
      _fieldValue: '',
      label: 'Unregistered entity',
      ...defaultProperties
    } as IMemberProperty)
  }
  if (isCompany || isIndividual) {
    const findJourney = tab.properties.find((property) => property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Journey)
    if (!findJourney) {
      // Append journey
      tab.properties.push({
        id: 0,
        fieldName: ENTITY_UPLOAD_FIELD_NAMES.Journey,
        fieldType: EFieldType.Country,
        isMandatory: false,
        isEditable: true,
        fieldValue: defaultJourney ?? '',
        _fieldValue: defaultJourney ?? '',
        label: 'Journey',
        ...defaultProperties
      } as IMemberProperty)
    } else if (typeof defaultJourney !== 'undefined') {
      findJourney.fieldValue = (defaultJourney ?? '') as string
      findJourney._fieldValue = (defaultJourney ?? '') as string
    }
  }
}

export function assignIdentityPropertyValues(
  result: IMemberResult,
  groupId: number,
  isIdentity = false
): IMemberResult {
  const newResult = cloneDeep(result)
  newResult.propertiesTabs.forEach((tab) => {
    if (tab.name === IdentityConst) {
      const isCompany = result.type === EMEMBER_TYPE.COMPANY
      appendExtraProperties({
        tab,
        groupId,
        groupName: result.groupName,
        stepName: result.stepName,
        isCompany: isCompany,
        isIndividual: result.type === EMEMBER_TYPE.INDIVIDUAL,
        memberType: isCompany ? EMemberType.entity : undefined,
        isIdentity
      })

      if (result.type === EMEMBER_TYPE.COMPANY) {
        const jurisdictionIndex = tab.properties.findIndex(
          (property) => property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction
        )
        if (jurisdictionIndex > -1) {
          tab.properties.splice(
            jurisdictionIndex,
            0,
            getEmptyDataSourceProperty({} as IMemberProperty) as IMemberProperty
          )
        }
      }

      tab.properties.forEach((property) => {
        const value = getValueByFieldName(result, property.fieldName)
        if (typeof value !== 'undefined') {
          if (
            property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.OtherRole ||
            property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Role
          ) {
            if (property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.Role) {
              if (!checkIsRegularRole(property.fieldName, groupId, value)) {
                property.originalFieldValue = value
                property.fieldValue = 'Other'
                property._fieldValue = 'Other'
              } else {
                property.fieldValue = value
                property._fieldValue = value
              }
            }
            if (property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.OtherRole) {
              if (!checkIsRegularRole(ENTITY_UPLOAD_FIELD_NAMES.Role, groupId, value)) {
                property.fieldValue = value
                property._fieldValue = value
              } else {
                property.fieldValue = ''
                property._fieldValue = ''
              }
            }
          } else {
            property.fieldValue = value
            property._fieldValue = value
          }
        }
      })
    }
  })

  return newResult
}

export const checkIsRegularRole = (fieldName: string, groupId: number, value?: string): boolean => {
  const roleDropdowns = storage.getDropDownOptionsByKey(getDropDownOptionKey(fieldName, groupId))
  return !!roleDropdowns.find((role) => role.text === value)
}

export const formatCountryNationality = (data: IContinent[]): ICountry[] => {
  const formattedData: ICountry[] = []
  data?.forEach((group) => {
    group.children.forEach((country) => {
      country.text &&
        formattedData.push({
          ...country,
          groupId: group.id,
          groupName: group.text
        })
    })
  })

  return formattedData
}

export const getLookupCaseText = (lookupCase: ILookupCase) => {
  return `${lookupCase.caseName} / ${lookupCase.caseFullId} / ${lookupCase.countryName}`
}

export const getValueByFieldName = (
  result: IMemberResult,
  fieldName: string,
  convertDateTime = false
): string | undefined => {
  if (!result) {
    return undefined
  }
  let value: string | undefined
  switch (fieldName) {
    case ENTITY_UPLOAD_FIELD_NAMES.UnregisteredEntity:
      return result.isUnregisteredEntity ? CHECKBOX_TRUE : CHECKBOX_FALSE
    case ENTITY_UPLOAD_FIELD_NAMES.Role:
    case ENTITY_UPLOAD_FIELD_NAMES.OtherRole:
      return result.role
    case ENTITY_UPLOAD_FIELD_NAMES.ShareCount:
      if (!result.shareCount) {
        return '-'
      }
      return result.shareCount?.toString()
    case ENTITY_UPLOAD_FIELD_NAMES.NotifiedOn:
      if (result.pscMember?.notifiedOn) {
        return dayjs(result.pscMember?.notifiedOn).format('DD/MM/YYYY')
      }
      break
    case ENTITY_UPLOAD_FIELD_NAMES.NatureOfControl:
      return result.pscMember?.natureOfControl
    // Company/Entity
    case ENTITY_UPLOAD_FIELD_NAMES.CompanyName:
      return result.companyName || result.name
    case ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction:
      return result.countryId.toString()
    case ENTITY_UPLOAD_FIELD_NAMES.DataSource:
      return result['jurisdictionSource']
    case ENTITY_UPLOAD_FIELD_NAMES.Uinfin:
    case ENTITY_UPLOAD_FIELD_NAMES.NRIC_FIN:
      return result.properties['Uinfin']
    case ENTITY_UPLOAD_FIELD_NAMES.Residential_Status:
      return result.properties['Residential Status']
    case ENTITY_UPLOAD_FIELD_NAMES.Alias:
      return result.properties['Alias']
    case ENTITY_UPLOAD_FIELD_NAMES.CompanyType:
      return result.properties['Company Type']
    case ENTITY_UPLOAD_FIELD_NAMES.CompanyStatus:
      return result.properties['Company Status']
    case ENTITY_UPLOAD_FIELD_NAMES.RegistrationNumber:
      return result.properties['Registration Number']
    case ENTITY_UPLOAD_FIELD_NAMES.Journey:
      return result.journeyId?.toString()
    case ENTITY_UPLOAD_FIELD_NAMES.AddressLine1:
      return (result.fullAddress || result.caseAddress)?.addressLine1 || ''
    case ENTITY_UPLOAD_FIELD_NAMES.AddressLine2:
      return (result.fullAddress || result.caseAddress)?.addressLine2 || ''
    case ENTITY_UPLOAD_FIELD_NAMES.City:
      return (result.fullAddress || result.caseAddress)?.city || ''
    case ENTITY_UPLOAD_FIELD_NAMES.StateProvince:
      return (result.fullAddress || result.caseAddress)?.stateProvince || ''
    case ENTITY_UPLOAD_FIELD_NAMES.PostCode:
      return (result.fullAddress || result.caseAddress)?.postcode || ''
    case ENTITY_UPLOAD_FIELD_NAMES.Country:
      return (result.fullAddress || result.caseAddress)?.countryId?.toString()
    // Individual
    case ENTITY_UPLOAD_FIELD_NAMES.FirstName:
      return result.firstName
    case ENTITY_UPLOAD_FIELD_NAMES.LastName:
      return result.lastName
    case ENTITY_UPLOAD_FIELD_NAMES.BirthDate:
      if (convertDateTime && result.birthDate) {
        return convertToMDY(result.birthDate)
      }
      return result.birthDate
    case ENTITY_UPLOAD_FIELD_NAMES.Nationality:
      return result.nationalityCountryId?.toString()
    default:
      value = result.propertiesTabs?.reduce((acc, currTab) => {
        currTab.properties?.every((prop) => {
          acc[prop.fieldName] = prop.fieldValue || acc[prop.fieldName]
          return true
        })
        return acc
      }, {} as Record<string, string | undefined>)[fieldName]
      if (convertDateTime && value) {
        return convertToMDY(value)
      } else {
        return value
      }
  }
}

export function findCurrentStep(entitySteps: TEntityStep[], currentStepName = ''): TEntityStep | undefined {
  const stepNames = currentStepName.split('.')
  let currentStep: TEntityStep | undefined
  let isLastStep = false
  stepNames?.forEach((stepName) => {
    if (currentStep) {
      const lastStep = last(currentStep.subSteps)
      currentStep = currentStep.subSteps?.find((subStep) => {
        return subStep.name === stepName
      })
      if (currentStep && currentStep.fullName === lastStep?.fullName && !currentStep.isWelcomeStep) {
        currentStep.isLastStep = isLastStep
      }
    } else {
      currentStep = entitySteps.find((step) => {
        return step.name === stepName
      })
      if (currentStep?.isLastStep) {
        isLastStep = true
      }
    }
  })

  return currentStep
}

export const checkIsLastStepOfGroup = (currentStepName: string, nextStepName: string, isNextLikeStep = false) => {
  return isNextLikeStep && currentStepName.split('.')[0] !== nextStepName.split('.')[0]
}

export const checkIsDisabled = (
  property: TabProperty,
  entityStatus: TEntityCaseStatus,
  currentStepStatus: TStepStatus,
  isIdentity = false,
  isMyInfoEntity = false
): boolean => {
  if (isMyInfoEntity) {
    if (
      ![
        ENTITY_UPLOAD_FIELD_NAMES.Email,
        ENTITY_UPLOAD_FIELD_NAMES.Phone,
        ENTITY_UPLOAD_FIELD_NAMES.Phone_Number
      ].includes(property.fieldName)
    ) {
      return true
    }
  }
  if (property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.UnregisteredEntity) {
    return !entityStatus.isSwitchEnable
  }
  // KYC.WebApp/Templates/CaseDetail/Address.html L9
  if (
    [
      ENTITY_UPLOAD_FIELD_NAMES.AddressLine1,
      ENTITY_UPLOAD_FIELD_NAMES.AddressLine2,
      ENTITY_UPLOAD_FIELD_NAMES.PostCode,
      ENTITY_UPLOAD_FIELD_NAMES.City,
      ENTITY_UPLOAD_FIELD_NAMES.StateProvince,
      ENTITY_UPLOAD_FIELD_NAMES.Country
    ].includes(property.fieldName)
  ) {
    return Boolean(!property.isEditable || currentStepStatus.isReadOnlyMode)
  }
  // KYC.WebApp/Templates/CaseDetail/IndividualProperties.html L24
  if (
    [
      ENTITY_UPLOAD_FIELD_NAMES.FirstName,
      ENTITY_UPLOAD_FIELD_NAMES.LastName,
      ENTITY_UPLOAD_FIELD_NAMES.Nationality,
      ENTITY_UPLOAD_FIELD_NAMES.BirthDate,
      ENTITY_UPLOAD_FIELD_NAMES.PassportExpirationDate
    ].includes(property.fieldName)
  ) {
    return Boolean(!property.isEditable || currentStepStatus.isReadOnlyMode || currentStepStatus.isLinkedCaseClosed)
  }
  // KYC.WebApp/Templates/CaseDetail/CompanyProperties.html L34
  if (property.fieldName === ENTITY_UPLOAD_FIELD_NAMES.DataSource) {
    return Boolean(
      (isIdentity &&
        !currentStepStatus.isNotAvailable &&
        currentStepStatus.hasRegisterNumber &&
        entityStatus.canScrap &&
        !currentStepStatus.is4UnregisteredEntity &&
        !currentStepStatus.isManualIntervention) ||
        currentStepStatus.isReadOnlyMode ||
        currentStepStatus.isLinkedCaseClosed
    )
  }
  if (
    [
      ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction,
      ENTITY_UPLOAD_FIELD_NAMES.CompanyName,
      ENTITY_UPLOAD_FIELD_NAMES.RegistrationNumber
    ].includes(property.fieldName)
  ) {
    return Boolean(
      (isIdentity &&
        !currentStepStatus.isNotAvailable &&
        currentStepStatus.hasRegisterNumber &&
        entityStatus.canScrap &&
        !currentStepStatus.is4UnregisteredEntity &&
        !currentStepStatus.isManualIntervention) ||
        currentStepStatus.isReadOnlyMode ||
        currentStepStatus.isLinkedCaseClosed ||
        !property.isEditable
    )
  }
  if (
    [
      ENTITY_UPLOAD_FIELD_NAMES.Journey,
      ENTITY_UPLOAD_FIELD_NAMES.CompanyStatus,
      ENTITY_UPLOAD_FIELD_NAMES.CompanyType
    ].includes(property.fieldName)
  ) {
    return Boolean(!property.isEditable || currentStepStatus.isReadOnlyMode || currentStepStatus.isLinkedCaseClosed)
  }
  return false
}

export const getValidationErrors = (results: IValidationResponse[]) => {
  const formattedResult: Record<string, boolean> = {}
  results?.every((result) => {
    formattedResult[result.type] = true
    return true
  })

  return Object.keys(formattedResult)
}

export const checkCurrentStepHealth = (currentStep?: TEntityStep): boolean => {
  if (!currentStep) {
    return false
  }

  let propertyLength = 0
  currentStep.cards?.every((card) => {
    return card.tabs?.every((tab) => {
      propertyLength += tab.properties?.length ?? 0
      return true
    })
  })
  switch (currentStep?.stepType) {
    case EStepType.Root:
    case EStepType.SectionMessage:
    case EStepType.Document:
    case EStepType.MemberList:
      return true
    case EStepType.Detail:
    case EStepType.ShareholderGroup:
    case EStepType.New:
      return propertyLength > 0
  }

  return true
}
