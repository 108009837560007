import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TabProperty, TIndividualStepInfo } from 'src/typings/types'
import { SINGPASS_ADDITIONAL_PROPS } from 'src/constants/myInfoProps'
import { PROFILE_DIRECTIVE_NAME } from 'src/constants/directiveNames'
import { ESingPassFlowInputType, TPersonalData } from 'src/typings/singpass'
import { getStepList } from 'src/state/case/actions'
import { myInfoToSingpassTab } from 'src/pages/Individual/SingpassMyInfo/helper'

interface ISingPassState {
  inputType: null | ESingPassFlowInputType
  myInfoToken: string
  tabs: TabProperty[]
  cachedMyInfoData: TPersonalData | undefined
}
const initialState = (): ISingPassState => ({
  inputType: null,
  myInfoToken: '',
  tabs: SINGPASS_ADDITIONAL_PROPS,
  cachedMyInfoData: undefined
})

export const singPassSlice = createSlice({
  name: 'singpass',
  initialState,
  reducers: {
    reset: initialState,
    setManualInput(state) {
      state.inputType = ESingPassFlowInputType.Manual
    },
    setMyInfoInput(state) {
      state.inputType = ESingPassFlowInputType.MyInfo
    },
    setMyInfoToken(state, action: PayloadAction<string>) {
      state.myInfoToken = action.payload
    },
    setTabs(state, action: PayloadAction<TPersonalData>) {
      state.tabs = myInfoToSingpassTab(state.tabs || [], action.payload)
    },
    setNricfinUneditable(state) {
      const nricfin = state.tabs.find((prop) => prop.fieldName === 'NRIC/FIN')
      if (nricfin) nricfin.isEditable = false
    },
    setCachedMyInfoData(state, action: PayloadAction<TPersonalData>) {
      state.cachedMyInfoData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStepList.fulfilled, (state, action) => {
      const { payload } = action
      const summaryStep = payload.steps.find(
        (step) => step.directiveName === PROFILE_DIRECTIVE_NAME
      ) as TIndividualStepInfo
      const otherTab = summaryStep.tabs.find((tab) => tab.name === 'Other')
      if (otherTab) {
        for (const key of ['Phone', 'Email', 'NRIC/FIN']) {
          const prop = otherTab.properties.find((prop) => prop.fieldName === key)
          if (prop) {
            const index = state.tabs.findIndex((prop) => prop.fieldName === key)
            state.tabs[index] = prop
          }
        }
      }
    })
  }
})

const singPassActions = singPassSlice.actions

export default singPassActions
