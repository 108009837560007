import { actions as entitySliceActions } from 'src/state/entity/slice'
import { EStepType, IdentityNumberConst } from 'src/constants/entity'
import entityRequest from 'src/state/rtkQueries/entityRequest'
import { uploadPortalApiSlice } from './uploadPortalApiSlice'

const documentRequest = uploadPortalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/documents' : `CaseRequest/${params.id}/documents`,
        method: 'GET',
        params
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'EntityDocument' as const,
          id: arg.id
        }
      ],
      async onQueryStarted({ id, groupId }, { dispatch, queryFulfilled }) {
        try {
          const { data: { result } = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateIdentitySteps({
                stepId: id,
                groupId,
                result,
                isDocument: true
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        } finally {
          dispatch(entitySliceActions.updateOfficersStatus({ document: true }))
        }
      }
    }),
    getMemberDocuments: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/documents' : `CaseRequest/${params.id}/documents`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => {
        return arg.isJointShareholderMember
          ? [
              {
                type: 'EntityJointShareholderDocument' as const,
                id: arg.id
              }
            ]
          : [
              {
                type: 'EntityDocument' as const,
                id: arg.id
              }
            ]
      },
      async onQueryStarted({ id, groupId, stepPrefix, isJointShareholderMember }, { dispatch, queryFulfilled }) {
        try {
          const { data: { result } = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateDocumentCards({
                stepId: id,
                groupId,
                result,
                stepPrefix,
                isJointShareholderMember
              })
            )
            dispatch(
              entitySliceActions.clearRefreshMembers({
                type: isJointShareholderMember ? EStepType.JointShareholderDocument : EStepType.Document,
                id
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    deleteDocument: builder.mutation({
      query: (body) => ({
        url: 'CaseRequest/DeleteFile',
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : arg.jointShareholderId
          ? [
              {
                type: 'EntityJointShareholderDocument',
                id: arg.memberCaseStepId
              }
            ]
          : [
              {
                type: 'EntityDocument',
                id: arg.memberCaseStepId
              }
            ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            entitySliceActions.updateRefreshMembers({
              groupId: arg.groupId,
              jointShareholderId: arg.jointShareholderId
            })
          )
          if (arg.groupId === IdentityNumberConst) {
            dispatch(
              entityRequest.util.invalidateTags([
                {
                  type: 'EntityStepList' as const,
                  id: arg.groupId
                }
              ])
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    uploadDocument: builder.mutation({
      query: (body) => ({
        url: 'CaseRequest/UploadFiles',
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            entitySliceActions.updateRefreshMembers({
              groupId: arg.groupId,
              jointShareholderId: arg.jointShareholderId
            })
          )
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    })
  }),
  overrideExisting: false
})

export default documentRequest
export const { useGetMemberDocumentsQuery, useDeleteDocumentMutation, useUploadDocumentMutation } = documentRequest
