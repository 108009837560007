import { useEffect } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import { TErrorCode, TSignalRNotification } from 'src/typings/types'
import { useAlertErrors } from 'src/hooks/common/useErrorCodes'
import useApp from 'src/hooks/app/useApp'

export default function useBroadcast() {
  const showErrorAlerts = useAlertErrors()
  const { updateDocumentRequestCount, globalLoading } = useApp()
  useEffect(() => {
    const signalRNotifyErrorChannel = new BroadcastChannel('signalRNotifyError')
    signalRNotifyErrorChannel.onmessage = (notificationInfo: TSignalRNotification) => {
      updateDocumentRequestCount('async', -1)
      if (notificationInfo.validationResult.Errors) {
        return showErrorAlerts([notificationInfo.validationResult.Errors as unknown as TErrorCode], 'warning', '\r\n')
      }
    }

    const signalRNotifySuccessChannel = new BroadcastChannel('signalRNotifySuccess')
    signalRNotifySuccessChannel.onmessage = () => {
      updateDocumentRequestCount('async', -1)
    }

    return function Clean() {
      ;(async () => {
        await signalRNotifyErrorChannel.close()
        await signalRNotifySuccessChannel.close()
      })()
    }
  }, [globalLoading, showErrorAlerts, updateDocumentRequestCount])
}
