export const TOKEN = 'KYC_TOKEN'
export const ACCESS_TOKEN = 'KYC_ACCESS_TOKEN'
export const REFRESH_TOKEN = 'KYC_REFRESH_TOKEN'
export const USER = 'KYC_USER'
export const LOGIN_DATA = 'KYC_LOGIN_DATA'
export const SIGNAL_TOKEN = 'KYC_SIGNAL_TOKEN'
export const LAST_REFRESH_TOKEN_TIMESTAMP = 'KYC_LAST_REFRESH_TOKEN_TIMESTAMP'
export const IS_AUTH = 'KYC_IS_AUTH'
export const API_SERVER_URL = 'KYC_API_SERVER_URL'
export const SIGNALR_SERVER_URL = 'KYC_SIGNALR_SERVER_URL'
export const EDITABLE = 'KYC_EDITABLE'
export const BRAND = 'KYC_BRAND'
export const BRANDING_AGREEMENT_LINKS = 'KYC_BRANDING_AGREEMENT_LINKS'
export const GEO_LOCATION_MANDATORY = 'GEO_LOCATION_MANDATORY'
export const MAXIMUM_FILE_SIZE = 'MAXIMUM_FILE_SIZE'
export const MINIMUM_FILE_SIZE = 'MINIMUM_FILE_SIZE'
export const LANGUAGE_CODE = 'KYC_LANGUAGE_CODE'
export const DROPDOWN_OPTIONS = 'KYC_DROPDOWN_OPTIONS'
export const ENTITY_FORM_VALUES = 'KYC_ENTITY_FORM_VALUES'
export const REDIRECT_URL = 'KYC_REDIRECT_URL'
export const ENTITY_PATH = 'KYC_ENTITY_PATH'
export const DO_NOT_SHOW_ME_NAVIGATE_AGAIN = 'KYC_DO_NOT_SHOW_ME_NAVIGATE_AGAIN'
export const LAST_STEP = 'KYC_LAST_STEP'
