import { EMemberType, TMemberType } from 'src/typings/entity'

export const IdentityDocumentStepName = 'doc'
export const IdentityConst = 'Identity'
export const IdentityNumberConst = 4
export const JOINT_SHAREHOLDER_MEMBER_ROLE = 'Joint Shareholder Member'

export enum EVALIDATION_ERROR {
  DocumentMissed = 'DocumentMissed',
  MandatoryFieldMissed = 'MandatoryFieldMissed',
  GeolocationMissed = 'GeolocationMissed',
  PreValidationFailed = 'PreValidationFailed'
}

export const DONOT_CONVERT_ROLES = ['CEO', 'CTO', 'CFO', 'COO']

export enum EStepName {
  ApplicantProfile = 'applicant',
  Company = 'company',
  Identity = 'identity',
  Officers = 'control',
  Shareholders = 'ownership',
  PersonWithSignificantControl = 'PSC'
}

export enum EStepType {
  Root = 'Root',
  SectionMessage = 'SectionMessage',
  Detail = 'Detail',
  Document = 'Document',
  MemberList = 'MemberList',
  New = 'New',
  ShareholderGroup = 'ShareholderGroup',
  JointShareholderDocument = 'JointShareholderDocument'
}

export enum EFileType {
  photoid = 'proofOfIdentity',
  selfie = 'selfie',
  poa = 'proofOfAddress'
}

export enum EMEMBER_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
  JOINTSHAREHOLDER = 'JOINTSHAREHOLDER'
}

export enum STEP_NAME {
  identity = 'Identity',
  additionalInfo = 'Additional Info'
}

export const MEMBER_TYPE: Record<TMemberType, { key: TMemberType; label: string }> = {
  individual: {
    key: 'individual',
    label: 'individual'
  },
  entity: {
    key: 'entity',
    label: 'entity'
  },
  jointShareholder: {
    key: 'jointShareholder',
    label: 'jointShareholder'
  }
}

export const CASE_STATUS_COLOR_MAPPING: Record<string, string> = {
  Active: 'success.main',
  Dissolved: 'error.main'
}

export const ENTITY_DIRECTIVE_NAME = {
  applicantProfile: 'kyc-applicant-profile-case-step-content',
  identityCompany: 'kyc-identity-company-case-step-content',
  memberIndividual: 'kyc-member-individual-case-step-content',
  memberCompany: 'kyc-member-company-case-step-content',
  shareholderGroup: 'shareholder-group',
  memberJointShareholder: 'kyc-member-joint-shareholder-case-step-content',
  kycDocuments: 'kyc-documents-case-step-content'
}

export const GROUP_NUMBER_MAPPING: Record<number, string> = {
  // PrivateCompanyLimitedBySharesShareholders: Shareholders and Beneficial Owners
  1: 'Shareholders',
  // PrivateCompanyLimitedBySharesOfficers: Controlling Entities and Individuals
  2: 'Officers',
  3: 'Other',
  // PrivateCompanyLimitedBySharesIdentity: Identity
  4: 'Identity',
  0: 'Unknown',
  5: 'Financial',
  6: 'AdditionalInformation',
  7: 'OtherIndividuals',
  8: 'UltimateBeneficialOwners',
  9: 'CaseComments',
  10: 'UltimateBeneficialOwnersScaffolds',
  // PersonWithSignificantControl: Person with Significant Control
  11: 'PersonWithSignificantControl',
  12: 'Risks',
  13: 'JointShareholder'
}

export enum COUNTRY_CASE_TYPE {
  NotSet = 0,
  SearchOnly = 1,
  ScrapAutomated = 2,
  RegistryConnected = SearchOnly | ScrapAutomated,
  HasSpecification = 4,
  OpenCorporate = 8
}

export const ROLES: Record<number, string[]> = {
  2: [
    'Administrator',
    'CEO',
    'Director',
    'Distributor',
    'General Partner',
    'Investment Manager',
    'Investment Advisor',
    'Manager',
    'Protector',
    'Secretary',
    'Trustee',
    'Officer',
    'Legal Representative',
    'Other'
  ],
  1: ['Beneficiary', 'Guarantor', 'Limited Partner', 'Settlor', 'Shareholder', 'Unit Holder', 'Investor', 'Other']
}

export const ENTITY_UPLOAD_PROPERTIES_NAMES = {
  CompanyStatus: 'Company Status',
  CompanyType: 'Company Type',
  CompanyTypeLocalized: 'Company Type Localized',
  CreationDate: 'Creation Date',
  RegistrationNumber: 'Registration Number'
}

export const ENTITY_UPLOAD_FIELD_NAMES = {
  UnregisteredEntity: 'UnregisteredEntity',
  Role: 'Role',
  OtherRole: 'OtherRole',
  SearchExistingProfiles: 'SearchExistingProfiles',
  CompanyType: 'CompanyType',
  CompanyStatus: 'CompanyStatus',
  Journey: 'Journey',
  Jurisdiction: 'Jurisdiction',
  DataSource: 'DataSource',
  Country: 'Country',
  Nationality: 'Nationality',
  RegistrationNumber: 'RegistrationNumber',
  ShareCount: 'ShareCount',
  GroupName: 'GroupName',
  NotifiedOn: 'NotifiedOn',
  NatureOfControl: 'NatureOfControl',
  CompanyName: 'CompanyName',
  AddressLine1: 'AddressLine1',
  AddressLine2: 'AddressLine2',
  City: 'City',
  StateProvince: 'StateProvince',
  PostCode: 'PostCode',
  FirstName: 'FirstName',
  MiddleName: 'MiddleName',
  Middle_Name: 'Middle Name',
  LastName: 'LastName',
  BirthDate: 'BirthDate',
  Birth_Date: 'Birth Date',
  PassportExpirationDate: 'PassportExpirationDate',
  Email: 'Email',
  Phone: 'Phone',
  Phone_Number: 'Phone Number',
  NRIC_FIN: 'NRIC/FIN',
  Uinfin: 'Uinfin',
  Alias: 'Alias',
  Residential_Status: 'Residential Status'
}

export const STEP_PREFIX = {
  [EMemberType.identity]: 'ic-',
  [ENTITY_DIRECTIVE_NAME.identityCompany]: 'ic-',
  [ENTITY_DIRECTIVE_NAME.memberIndividual]: 'mi-',
  [EMemberType.individual]: 'mi-',
  [ENTITY_DIRECTIVE_NAME.memberCompany]: 'mc-',
  [EMemberType.entity]: 'mc-',
  [ENTITY_DIRECTIVE_NAME.memberJointShareholder]: 'mj-',
  [EMemberType.jointShareholder]: 'mj-',
  [ENTITY_DIRECTIVE_NAME.shareholderGroup]: 'sg-',
  [ENTITY_DIRECTIVE_NAME.kycDocuments]: 'doc-'
}
