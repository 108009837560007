import storage from 'src/utils/storage'
import { EXTERNAL_AUTHORIZE_PATHNAME } from 'src/constants/app'
import { State } from './slice'

/**
 * @ignore
 */
const logout = (state: State) => {
  state.signalToken = null
  storage.removeSignalToken()
  storage.removeLoginData()
  storage.removeToken()
  storage.removeEditable()
  storage.removeGeoLocationMandatory()
  storage.removeMinimumFileSize()
  storage.removeMaximumFileSize()
  storage.removeDropDownOptions()
  const accessToken = storage.getAccessToken()
  if (window.location.pathname !== '/') {
    if (accessToken) {
      const hasCompanyDetail = !!state.loginData?.companyDetail
      const hasIndividualDetail = !!state.loginData?.individualDetail
      const isExternalAuthorizePath = window.location.pathname === EXTERNAL_AUTHORIZE_PATHNAME

      if (hasCompanyDetail || isExternalAuthorizePath || hasIndividualDetail) {
        window.location.href = `/?accessToken=${accessToken}`
      } else {
        window.location.href = '/'
      }
    } else {
      window.location.href = '/'
    }
  }
}

const userReducers = {
  logout
}

export default userReducers
