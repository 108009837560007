import { createTheme } from '@kyc-ui/react'
import 'src/theming/geraldeve/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import { ReactComponent as Logo } from 'src/theming/geraldeve/images/logo.svg'
import { ReactComponent as LogoIcon } from 'src/theming/geraldeve/images/logo-icon.svg'
import { ReactComponent as Location } from 'src/theming/geraldeve/images/location.svg'
import { ReactComponent as Agreement } from 'src/theming/geraldeve/images/agreement.svg'
import Background from 'src/theming/geraldeve/images/background.svg'
import Login from 'src/theming/geraldeve/images/login.svg'
import { ReactComponent as Completed } from 'src/theming/geraldeve/images/completed.svg'
import uploadAnimation from 'src/theming/geraldeve/lotties/upload.json'
import checkAnimation from 'src/theming/geraldeve/lotties/check.json'
import pinAnimation from 'src/theming/geraldeve/lotties/pin.json'
import completedAnimation from 'src/theming/geraldeve/lotties/entityCompleted.json'
import emptyMemberList from 'src/theming/geraldeve/lotties/empty-member-list.json'
import { ReactComponent as EntityBeforeWeBegin } from 'src/theming/geraldeve/images/entity-before-we-begin.svg'
import { ReactComponent as EntityControllingBodies } from 'src/theming/geraldeve/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/theming/geraldeve/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/theming/geraldeve/images/entity-psc.svg'
import { ReactComponent as PageNotFound } from 'src/theming/geraldeve/images/page-not-found.svg'
import { ReactComponent as Uploaded } from 'src/theming/geraldeve/images/entity-uploaded.svg'
import Loading from 'src/theming/geraldeve/images/loading.gif'
import AcceptedDocumentsImg from 'src/theming/geraldeve/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/geraldeve/lotties/selfie.json'
import POIImg from 'src/theming/geraldeve/lotties/poi-accepted-doc.json'

const fonts = {
  system: SYSTEM_FONTS,
  body: `'Hind', ${SYSTEM_FONTS}`
}

const palette = {
  primary: {
    main: '#799C4B',
    light: '#A5BD86',
    dark: '#566F35',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#21372C',
    light: '#BAC1BE',
    dark: '#192921',
    contrastText: '#ffffff'
  },
  info: {
    main: '#5F6EB3',
    dark: '#434E7F',
    light: '#949ECC',
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#D65F00',
    dark: '#984300',
    light: '#E49454',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#66BBB0',
    dark: '#48857D',
    light: '#98D1CA',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#BD3C4B',
    dark: '#862B35',
    light: '#D37C86',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#212121',
    secondary: '#5C5C5C',
    disabled: '#9A9A9A'
  },
  background: {
    paper: '#FFFFFF',
    default: '#FEFEFE'
  },
  action: {
    active: '#6F6F6F',
    hover: '#DBDBDB',
    selected: '#D4D4D4',
    disabled: '#B1B1B1',
    disabledBackground: '#CBCBCB',
    focus: '#CBCBCB'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '101px',
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: FONT_WEIGHT.light,
      fontSize: '63px',
      lineHeight: '120%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '50px',
      lineHeight: '116.7%'
    },
    h4: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '36px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px'
    },
    h5: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '25px',
      lineHeight: '133.4%'
    },
    h6: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '21px',
      lineHeight: '160%',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '17px',
      lineHeight: '175%',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '15px',
      lineHeight: '157%',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '17px',
      lineHeight: '150%',
      letterSpacing: '0.5px'
    },
    body2: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '15px',
      lineHeight: '143%',
      letterSpacing: '0.25px'
    },
    button: {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: '15px',
      lineHeight: '25px',
      letterSpacing: '1.25px',
      '& .MuiButton-sizeSmall': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '17px',
        lineHeight: '27px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '13px',
      lineHeight: '156%',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: FONT_WEIGHT.normal,
      fontSize: '10px',
      lineHeight: '266%',
      letterSpacing: '1.5px'
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DBDBDB'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#DBDBDB'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#EBEBEB'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#8B8B8B . 50%'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            backgroundColor: '#f1f5f9',
            color: '#64748B'
          },
          '& .deleteIcon': {
            color: palette.action.active
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.paper,
          boxShadow: 'none',
          color: palette.primary.main,
          '& .logoContainer svg': {
            height: '36px'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.agreementTypography a': {
            color: palette.secondary.dark
          },
          '&.agreementTitle': {
            color: palette.text.primary
          },
          '&.MuiTypography-overline.progressText': {
            color: palette.secondary.dark
          },
          '&.serviceRequiresText': {
            color: palette.text.primary
          },
          '&.MuiListItemText-secondary': {
            color: palette.text.primary
          },
          '&.locationText': {
            color: palette.text.primary
          },
          '&.docText': {
            color: palette.text.primary
          },
          '&.agreementDevicePrompt': {
            display: 'none'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#C9D7B7',
          '& .MuiLinearProgress-bar': {
            backgroundColor: palette.primary.main
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.topNavBtn': {
            color: palette.secondary.main
          },
          '&.topNavBtn: hover': {
            background: '#E5E6E6'
          },
          '&.infoIcon, .deleteIcon, .cancelIcon': {
            color: palette.action.active
          },
          '&.uploadFileCustom': {
            color: palette.action.active
          }
        }
      }
    }
  },

  palette,
  images: {
    entityCompleted: JSON.stringify(completedAnimation),
    upload: JSON.stringify(uploadAnimation),
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: LogoIcon,
    location: Location,
    login: Login,
    completed: Completed,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    individualBeforeWeBegin: EntityBeforeWeBegin,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc,
    notFound: PageNotFound,
    uploadedIcon: Uploaded,
    loading: Loading,
    acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
    selfieImg: JSON.stringify(SelfieImg),
    poiImg: JSON.stringify(POIImg)
  },
  colorName: {
    button: 'primary',
    linearProgress: 'primary',
    checkbox: 'secondary',
    stepColor: 'primary.dark',
    cardBorderColor: '#cccccc'
  },
  colorString: {
    checkbox: palette.secondary.main
  },
  others: {
    loginBackgroundSize: 'contain',
    customize: {
      background: {
        background: '#ffffff'
      },
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
}

export default theme
