import React, { FC, ReactNode } from 'react'
import { Box } from '@kyc-ui/react'
import { styled } from '@mui/system'
import { HomeNavbar } from './HomeNavbar'

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%'
})

interface Props {
  children?: ReactNode
}

export const LayoutRoot: FC<Props> = ({ children }) => {
  return (
    <Root>
      <HomeNavbar />
      {children}
    </Root>
  )
}

LayoutRoot.displayName = 'LayoutRoot'
