import React from 'react'
import { Box, Card, CardContent, CardActions, Button } from '@kyc-ui/react'
import type { TErrorProps } from 'src/typings/types'
import useUser from 'src/hooks/user/useUser'
import useTranslation from 'src/hooks/common/useTranslation'
import useTheme from 'src/hooks/common/useTheme'

function ErrorPage({ message, refreshData }: TErrorProps) {
  const { logout } = useUser()
  const tc = useTranslation('common')
  const theme = useTheme()

  const goBack = () => {
    logout()
    refreshData()
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
      <Card
        sx={{
          marginBottom: theme.spacing(4)
        }}
      >
        <CardContent>
          <div>Fatal error: {message}</div>
          <CardActions>
            <Button color={theme.colorName.button} fullWidth onClick={goBack} variant="contained">
              {tc('logout') ?? 'Logout'}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ErrorPage
