import Background from 'src/assets/images/main-bg.svg'

const theme = {
  others: {
    customize: {
      background: {
        background: '#F0F1FE'
      },
      loginBackground: {
        background: `url(${Background}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
}
export default theme
