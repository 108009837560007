import { createSlice } from '@reduxjs/toolkit'
import { agreeWithTerms, hasAgreedWithTerms, loginSignal, login } from 'src/state/user/actions'
import storage from 'src/utils/storage'
import reducers from 'src/state/user/reducers'
import { TLoginData } from 'src/typings/user'
import { getStepList } from 'src/state/case/actions'

export interface State {
  loginData?: TLoginData | null
  signalToken?: string | null
  agreed?: boolean
  allowEdit?: boolean
}

const initialState = {
  loginData: storage.getLoginData(),
  signalToken: storage.getSignalToken(),
  agreed: undefined,
  allowEdit: storage.getEditable()
} as State

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers,
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { payload } = action
        state.loginData = payload
        const detail = payload.companyDetail || payload.individualDetail
        state.allowEdit = detail?.allowEdit
        storage.setLoginData(payload)
      })
      .addCase(loginSignal.fulfilled, (state, action) => {
        const { payload } = action
        state.signalToken = payload
        storage.setSignalToken(payload)
      })
      .addCase(hasAgreedWithTerms.fulfilled, (state, action) => {
        const { payload } = action
        state.agreed = payload
      })
      .addCase(agreeWithTerms.fulfilled, (state) => {
        state.agreed = true
      })
      .addCase(getStepList.rejected, (state) => {
        storage.removeUser()
        state.signalToken = null
      })
  }
})

export default userSlice
export const actions = userSlice.actions
