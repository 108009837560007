import { alpha, createTheme, theme as kycTheme, lighten } from '@kyc-ui/react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded'
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded'
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded'
import 'src/theming/banquepatronus/fonts/style.scss'
import { SYSTEM_FONTS, FONT_WEIGHT } from 'src/constants/app'
import Logo from 'src/theming/banquepatronus/images/logo.png'
import EntityUpload from 'src/theming/banquepatronus/images/entity-upload.gif'
import EntityUploaded from 'src/theming/banquepatronus/images/entity-uploaded.png'
import { ReactComponent as Location } from 'src/theming/banquepatronus/images/location.svg'
import { ReactComponent as Individual } from 'src/theming/banquepatronus/icons/Individual.svg'
import { ReactComponent as Entity } from 'src/theming/banquepatronus/icons/Entity.svg'
import { ReactComponent as ShareholderGroup } from 'src/theming/banquepatronus/icons/ShareholderGroup.svg'
import Login from 'src/theming/banquepatronus/images/login.png'
import EntityBeforeWeBegin from 'src/theming/banquepatronus/images/entity-before-we-begin.png'
import Agreement from 'src/theming/banquepatronus/images/agreement.png'
import EntityControllingBodies from 'src/theming/banquepatronus/images/entity-controlling-bodies.png'
import EntityOwnership from 'src/theming/banquepatronus/images/entity-ownership.png'
import EntityPsc from 'src/theming/banquepatronus/images/entity-psc.png'
import PageNotFound from 'src/theming/banquepatronus/images/page-not-found.png'
import checkAnimation from 'src/theming/banquepatronus/lotties/check.json'
import pinAnimation from 'src/theming/banquepatronus/lotties/pin.json'
import emptyMemberList from 'src/theming/banquepatronus/lotties/empty-member-list.json'
import { ReactComponent as Uploaded } from 'src/theming/banquepatronus/images/entity-uploaded.svg'
import Loading from 'src/theming/banquepatronus/images/loading.gif'
import AcceptedDocumentsImg from 'src/theming/banquepatronus/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/banquepatronus/lotties/selfie.json'
import POIImg from 'src/theming/banquepatronus/lotties/poi-accepted-doc.json'
import { ReactComponent as LanguageRounded } from 'src/theming/banquepatronus/icons/LanguageRounded.svg'
import { ReactComponent as Logout } from 'src/theming/banquepatronus/icons/Logout.svg'

const fonts = {
  system: SYSTEM_FONTS,
  body: `CrimsonPro, ${SYSTEM_FONTS}`,
  title: `Nexa, ${SYSTEM_FONTS}`
}

const extraColors = {
  Timberwolf: '#D9D4D0',
  DarkBlue: '#1C355E',
  DarkBlue50: '#8895A9',
  GoldFusion: '#8C734B',
  LightGrey: '#F8F8F8',
  Background: '#EAECEE',
  InputLabel: '#73829A'
}

const palette = {
  ...kycTheme.palette,
  primary: {
    ...kycTheme.palette.primary,
    main: extraColors.DarkBlue
  },
  text: {
    ...kycTheme.palette.text,
    primary: extraColors.DarkBlue,
    secondary: '#737491'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h1: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '95px',
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '59px',
      lineHeight: '120%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '48px',
      lineHeight: '116.7%'
    },
    h4: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '34px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px'
    },
    h5: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '24px',
      lineHeight: '133.4%'
    },
    h6: {
      fontFamily: fonts.title,
      fontWeight: `${FONT_WEIGHT.black}!important`,
      fontSize: '19px',
      lineHeight: '160%',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontFamily: fonts.body,
      fontWeight: `${FONT_WEIGHT.medium}!important`,
      fontSize: '18px',
      lineHeight: '175%',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: `${FONT_WEIGHT.normal}!important`,
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: `${FONT_WEIGHT.normal}!important`,
      fontSize: '18px',
      lineHeight: '150%',
      letterSpacing: '0.5px'
    },
    body2: {
      fontWeight: `${FONT_WEIGHT.normal}!important`,
      fontSize: '15px',
      lineHeight: '150%',
      letterSpacing: '0.25px'
    },
    button: {
      fontFamily: fonts.body,
      fontSize: '18px!important',
      fontWeight: '700!important',
      lineHeight: '27px',
      letterSpacing: '1.25px',
      '& .MuiButton-sizeSmall': {
        fontSize: '14px!important',
        lineHeight: '24px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '16px!important',
        lineHeight: '26px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontWeight: `${FONT_WEIGHT.light}!important`,
      fontSize: '13px',
      lineHeight: '156%',
      letterSpacing: '0.4px'
    },
    overline: {
      // fontWeight: `100!important`,
      fontSize: '12px!important',
      lineHeight: '18px',
      height: '18px',
      letterSpacing: '1.5px'
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          fontWeight: '400'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '15px!important',
          fontWeight: '400!important',
          color: extraColors.InputLabel,
          '.MuiFormLabel-asterisk': {
            color: palette.warning.main
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DBDBDB'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#DBDBDB'
        },
        root: {
          fontSize: '18px',
          fontWeight: '500!important'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: '#EBEBEB'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#8B8B8B . 50%'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            backgroundColor: '#f1f5f9',
            color: '#64748B'
          },
          '& .deleteIcon': {
            color: palette.action.active
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#FFB400'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: 'none',
          // color: palette.secondary.contrastText,
          '& .logoContainer svg': {
            height: '36px'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000',
          '&.agreementTypography a': {
            color: extraColors.GoldFusion
          },
          '&.agreementTitle': {
            color: palette.text.primary
          },
          '&.MuiTypography-overline.progressText': {
            color: palette.secondary.dark
          },
          '&.serviceRequiresText': {
            color: palette.text.primary
          },
          '&.MuiListItemText-secondary': {
            color: alpha(palette.text.secondary, 0.87),
            fontSize: '15px',
            fontWeight: '400!important',
            lineHeight: '150%'
          },
          '&.locationText': {
            color: palette.text.primary
          },
          '&.docText': {
            color: palette.text.primary
          },
          '&.agreementDevicePrompt': {
            display: 'none'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: lighten(extraColors.GoldFusion, 0.6),
          '& .MuiLinearProgress-bar': {
            backgroundColor: extraColors.GoldFusion
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.topNavBtn': {
            color: palette.primary.main
          },
          '&.topNavBtn: hover': {
            background: '#E5E6E6'
          },
          '&.infoIcon, .deleteIcon, .cancelIcon': {
            color: palette.action.active
          },
          '&.uploadFileCustom': {
            color: palette.action.active
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '18px',
            lineHeight: '27px',
            letterSpacing: '1.25px'
          }
        }
      }
    }
  },

  palette,
  images: {
    entityCompleted: EntityUploaded,
    upload: EntityUpload,
    check: JSON.stringify(checkAnimation),
    pin: JSON.stringify(pinAnimation),
    emptyMemberList: JSON.stringify(emptyMemberList),
    logo: Logo,
    logoIcon: Logo,
    location: Location,
    completed: EntityUploaded,
    agreement: Agreement,
    entityBeforeWeBegin: EntityBeforeWeBegin,
    individualBeforeWeBegin: EntityBeforeWeBegin,
    entityControllingBodies: EntityControllingBodies,
    entityOwnership: EntityOwnership,
    entityPsc: EntityPsc,
    notFound: PageNotFound,
    uploadedIcon: Uploaded,
    loading: Loading,
    login: Login,
    acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
    selfieImg: JSON.stringify(SelfieImg),
    poiImg: JSON.stringify(POIImg)
  },
  icons: {
    LanguageRounded: LanguageRounded,
    Logout: Logout,
    CheckRoundedIcon,
    AttachmentRoundedIcon,
    Individual,
    Entity,
    ShareholderGroup,
    AssistantRoundedIcon,
    LibraryAddRoundedIcon
  },
  colorName: {
    button: 'primary',
    linearProgress: 'primary',
    checkbox: 'primary',
    stepColor: 'primary.dark',
    cardBorderColor: 'none',
    addButton: 'primary',
    switch: 'primary',
    pagination: 'primary',
    progressButton: 'secondary'
  },
  colorString: {
    checkbox: palette.primary.main,
    individualIconColor: palette.action.active
  },
  sizes: {
    button: 'large',
    beforeWeBeginMaxWidth: '500px'
  },
  sx: {
    stepProgressOverline: {
      fontFamily: fonts.title
    },
    stepProgressTitle: {
      fontSize: '17px',
      fontWeight: '600!important',
      lineHeight: '25px',
      marginBottom: '12px!important'
    },
    fileUploader: {
      'label.MuiButtonBase-root': {
        minHeight: '64px'
      },
      '.MuiListItemAvatar-root .MuiAvatar-root': {
        backgroundColor: extraColors.LightGrey
      },
      '&:not(:last-child)': {
        marginBottom: '16px'
      },
      '.MuiListItemButton-root': {
        border: '1px solid rgba(74, 75, 101, 0.12)!important'
      }
    },
    uploadingCard: {
      backgroundColor: 'transparent',
      height: 'auto',
      img: {
        height: '400px'
      }
    },
    uploadedCard: {
      backgroundColor: 'transparent',
      height: 'auto',
      '>div': {
        display: 'flex',
        borderRadius: '16px',
        backgroundColor: 'white'
      },
      img: {
        height: '400px'
      }
    },
    uploading: {
      fontFamily: fonts.body
    },
    notFoundText: {
      fontFamily: fonts.body
    },
    notFound: {
      pt: 0,
      pb: 10,
      svg: {
        height: '400px'
      }
    },
    uploadedMessage: {
      fontFamily: fonts.body
    },
    loadingBackdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      backdropFilter: 'blur(0px)'
    },
    loadingMessage: {
      backgroundColor: 'transparent!important'
    },
    agreementDialogCheckbox: {
      alignItems: 'flex-start'
    },
    didYouKnow: {
      fontFamily: fonts.body,
      fontSize: '24px',
      fontWeight: '700!important',
      lineHeight: '36px'
    },
    completedCard: {
      width: 'auto',
      mx: 'auto',
      mb: '24px!important',
      '*': {
        padding: '0!important',
        mb: '0!important',
        display: 'flex'
      },
      img: {
        height: '358px'
      },
      '.completedImage': {
        padding: '24px!important'
      }
    },
    completedCard2: {
      '*': {
        padding: '0!important',
        mb: '0!important'
      },
      p: {
        padding: '16px!important'
      }
    }
  },
  others: {
    loginBackgroundSize: 'contain',
    customize: {
      background: {
        background: palette.background.default
      }
    }
  },
  consts: {
    isBpl: true
  }
}

export default theme
