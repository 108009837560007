export type TPersonalAddressField = {
  type: string
  block: string
  building: string
  floor: string
  unit: string
  street: string
  postal: string
  country: string
  countryCode: string
  line1?: string
  line2?: string
}
export type TPersonalData = {
  principalName: string
  dob: string
  email: string
  mobileno: string
  name: string
  nationality: number
  race: string
  regAdd: TPersonalAddressField
  uinfin: string
  aliasName: string
}

export type TSingPassMyInfo = {
  principalName: string
  firstName: string
  lastName: string
  dateOfBirth: string
  nationality: number
  address1: string
  address2: string
  city: string
  state: string
  country: string
  countryId: string | number | undefined
  postcode: string
  properties: Record<string, string>
}

export interface IIdentifaceResult {
  passed: boolean
  reason: string
  score: number
  type: 'success'
  isSuccess: boolean
}

export enum ESingPassFlowInputType {
  MyInfo = 'myinfo',
  Manual = 'manual'
}
