import { styled } from '@mui/system'
import { Box } from '@kyc-ui/react'

export const Background = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: -1
}))
