export const emailProp = {
  id: 0,
  fieldName: 'Email',
  fieldType: 1,
  isMandatory: false,
  isEditable: true,
  order: 0,
  tabId: 0,
  fieldValue: '',
  isIndividualCases: false,
  isCompanyCases: false,
  tabName: 'Other',
  propertyEnvironments: 14,
  label: 'Email',
  isDefaultEmail: false,
  isDefaultSms: false,
  isDefaultField: false,
  sections: 30,
  fieldTypeSub: 0,
  isVisibleInIdentityStep: false
}
export const phoneProp = {
  id: 0,
  fieldName: 'Phone',
  fieldType: 1,
  isMandatory: false,
  isEditable: true,
  order: 0,
  tabId: 0,
  fieldValue: '',
  isIndividualCases: false,
  isCompanyCases: false,
  tabName: 'Other',
  propertyEnvironments: 14,
  label: 'Phone',
  isDefaultEmail: false,
  isDefaultSms: false,
  isDefaultField: false,
  sections: 30,
  fieldTypeSub: 0,
  isVisibleInIdentityStep: false
}
export const nricfinProp = {
  id: 0,
  fieldName: 'NRIC/FIN',
  fieldType: 1,
  isMandatory: true,
  isEditable: true,
  order: 0,
  tabId: 0,
  fieldValue: '',
  isIndividualCases: false,
  isCompanyCases: false,
  tabName: 'Other',
  propertyEnvironments: 14,
  label: 'NRIC/FIN',
  isDefaultEmail: false,
  isDefaultSms: false,
  isDefaultField: false,
  sections: 30,
  fieldTypeSub: 0,
  isVisibleInIdentityStep: false
}
export const genderProp = {
  id: 0,
  fieldName: 'Gender',
  fieldType: 1,
  isMandatory: false,
  isEditable: true,
  order: 0,
  tabId: 0,
  fieldValue: '',
  isIndividualCases: false,
  isCompanyCases: false,
  tabName: 'Other',
  propertyEnvironments: 14,
  label: 'Gender',
  isDefaultEmail: false,
  isDefaultSms: false,
  isDefaultField: false,
  sections: 30,
  fieldTypeSub: 0,
  isVisibleInIdentityStep: false
}

// Remove phoneProp, emailProp per KWA-17834
export const SINGPASS_ADDITIONAL_PROPS = [nricfinProp, genderProp]
