import { ReactNode } from 'react'
import { DOCUMENT_DIRECTIVE_NAME, LOCATION_DIRECTIVE_NAME, PROFILE_DIRECTIVE_NAME } from '../constants/directiveNames'

export type TErrorProps = {
  message: string
  refreshData: () => void
}

export type TRoute = {
  id: number
  name: string
  icon: ReactNode
  path: string
  title: string
  text: string
  valid: boolean | undefined
}

export type TErrorCode = {
  Code: string
  Description: string
}

export type TValidationResult = {
  Success: boolean
  WarningKeys: Array<TErrorCode>
  Errors: Array<TErrorCode>
}

export type TSignalRNotification = {
  fileCat: string
  validationResult: TValidationResult
}

export type TRouteProps = {
  route: TRoute[]
  isLoading: boolean
}

type TDocumentName = 'Photo ID' | 'Selfie' | 'Proof of Address' | string

export type TDocumentType = 'photoid' | 'selfie' | 'poa' | string

export type TDocument = {
  id: number
  description: TDocumentName
  documentType: TDocumentType
  url: string
  updateDate: string
  fileName: string
  uploadValidationStatus: string
  originalUrl?: string
  previewUrl?: string
}

export type TBlobStorage = {
  url: string
  file: File
  signal?: AbortSignal
}

type TDocumentInfo = {
  name: TDocumentName
  fileName: string
  fileCat: TDocumentType
  tempFilePath: string
  caseDocumentId: number
  messages: never[]
}

export type TUploadFileRequest = {
  caseStepId: number
  groupId?: number
  childCaseCommonId: number | null
  memberCaseStepId: number | null
  documentInfos: TDocumentInfo[]
  signal?: AbortSignal
}

export type TSubmitCaseRequest = {
  isMyInfoFlow: boolean
}

export type TDeleteFileRequest = Pick<TDocumentInfo, 'name' | 'fileCat' | 'caseDocumentId'> &
  Omit<TUploadFileRequest, 'documentInfos'>

export type TStepInfoBase = {
  directiveName: keyof TStepObj
  description: string | null
  stepId: number
}

export enum RequestedInfos {
  MyInfo = 35,
  Identiface = 36
}

export type TDocumentStepInfo = TStepInfoBase & {
  directiveName: typeof DOCUMENT_DIRECTIVE_NAME
  documentInfos: TDocument[]
  requestedInfos: RequestedInfos[]
  checkLiveness: boolean
}

export type TStepObj = {
  [DOCUMENT_DIRECTIVE_NAME]: TDocumentStepInfo
  [LOCATION_DIRECTIVE_NAME]: TLocationStepInfo
  [PROFILE_DIRECTIVE_NAME]: TIndividualStepInfo
}
export type TLocationStepInfo = TStepInfoBase &
  TPinLocation & {
    directiveName: typeof LOCATION_DIRECTIVE_NAME
  }

export type TIndividualStepInfo = TStepInfoBase &
  TIndividualSummary & {
    directiveName: typeof PROFILE_DIRECTIVE_NAME
  }

export type TStepList = {
  steps: Array<TLocationStepInfo | TDocumentStepInfo | TIndividualStepInfo>
}

export type TDocumentStep = {
  documentInfos: TDocument[]
  individualFullName: string
  caseCommonId: number
  customerId: number
}

export interface PostAdditionalRequest {
  bankName: string
  address1: string
  city: string
  state: string
  country: string
}

export type TPinLocation = {
  lng: number | null
  lat: number | null
}

export type TUploadFile = {
  url: string
  filePath: string
}

export type StatusReturn = {
  status: number
}

export type TIndividualSummary = TIndividualSummaryDefault

export type TIndividualSummaryDefault = {
  caseCommonId: number
  customerId: number
  firstName: string
  middleName?: string
  lastName: string
  nationalityName: string
  nationalityCountryId: number | null | string
  birthDate: string
  address: {
    addressLine1: string | null
    addressLine2: string | null
    city: string | null
    stateProvince: string | null
    postcode: string | null
    countryId: number | null | string
    country: string | null
  }
  mandatoryIdentityProperties?: string[]
  tabs: PropertiesTab[]
  properties: Record<string, string>
}

export type TEntityForm = {
  properties: Record<string, string>
  type?: string
}

export type TIndividualSummaryRequest = Omit<
  TIndividualSummary,
  'address' | 'caseCommonId' | 'customerId' | 'nationalityName' | 'tabs' | 'mandatoryIdentityProperties'
> & {
  middleName: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  postcode: string | null
  stateProvince: string | null
  countryId: number | null | string
  properties: Record<string, unknown>
  linkProperties: Record<string, unknown>
}

export type THashProperties = {
  [key: string]: TabProperty
}

export type PropertiesTab = {
  id: number
  name: string
  order: number
  journeyId: number | null
  properties: TabProperty[]
  isIdentity: boolean | null
  description: string
  title?: string
}

export type TabProperty = {
  id: number
  fieldName: string
  fieldType: EFieldType
  fieldValidator?: string | null
  isMandatory: boolean
  isEditable: boolean
  caseType?: number
  order?: number
  tabId?: number
  groupId?: number
  fieldValue: string
  _fieldValue?: string
  originalFieldValue?: string
  fieldLabel?: string
  isIndividualCases?: boolean
  isCompanyCases?: boolean
  tabName?: string
  fieldTypeName?: string
  propertyEnvironments?: number
  label: string
  dropDownOptions?: DropDownOption[]
  dropDownId?: number
  isDefaultEmail?: boolean
  isDefaultSms?: boolean
  dropDownOptionId?: number
  tag?: string | null
  isDefaultField?: boolean
  fieldActionType?: number
  fieldActionTypes?: string
  sections?: number
  isIdentity?: boolean
  isLinkProperty?: boolean
  labelShort?: string
  labelSub?: string
  fieldTypeSub?: number
  tabTitleSub?: string
  errorMessageMandatory?: string
  isMandatoryForClosing?: boolean
  isVisibleInIdentityStep?: boolean
  maxWidth?: string
  xsMaxWidth?: string
  _tabIndex?: number
  icon?: string
  isOriginalValue?: boolean
}

export type TValidateFieldRequest = {
  label: string
  value: string
  validator: string
}

export enum EFieldType {
  Text = 1,
  Number = 2,
  DateTime = 3,
  CheckBox = 4,
  List = 5,
  Monetary = 6,
  IBAN = 7,
  BIC = 8,
  Multiple = 9,
  ListFreeText = 10,
  Country = 11,
  Nationality = 12,
  AssigneeList = 13,
  MultipleNoFreeText = 14,
  MultiLineText = 15,
  // Frontend customer field type
  Switch = 100,
  SearchAutoComplete = 101,
  Role = 102,
  ShareCount = 103
}

export type DropDownOption = {
  id?: number
  option: string
  dropDownId?: number
  customerId?: number
  localizationKey?: string
  text?: string
  isOtherFreeText?: boolean
  originalData?: any
}

export type TDropDownOption = Record<number | string, DropDownOption[]>

export type InitialValueType = Omit<
  TIndividualSummary,
  'caseCommonId' | 'customerId' | 'mandatoryIdentityProperties'
> & {
  middleName: string | null
  properties: Record<string, string>
}

export type IndexType = keyof TIndividualSummaryRequest

export type AddionalInfoRequestType = Record<string, unknown>

export interface IValidationError {
  errors: Record<string, string[]>
  type: string
  title: string
  status: number
  traceId: string
}
